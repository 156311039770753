export const EllenPassData = {
        premium: {
            name: 'Premium',
            videoUrl: 'https://ellenikautility.com/assets/video/ellen-pass-premium.mp4',
            benefitsList: [
                'Membership Ellenika Utility',
                'Accesso alla sezione Partner',
                '% di sconto base',
                'Promozioni lifetime garantite'
            ],
            price: 99
        },
        gold: {
            name: 'Gold',
            videoUrl: 'https://ellenikautility.com/assets/video/ellen-pass-gold.mp4',
            benefitsList: [
                'Membership Ellenika Utility',
                'Accesso alla sezione Partner',
                '% di sconto intermedia',
                'Promozioni lifetime garantite',
                'Experience dedicate'
            ],
            price: 159
        },
        platinum: {
            name: 'Platinum / Business',
            videoUrl: 'https://ellenikautility.com/assets/video/ellen-pass-platinum.mp4',
            benefitsList: [
                'Membership Ellenika Utility',
                'Accesso alla sezione Partner',
                "Accesso esclusivo all'area Business (per Imprenditori/Aziende)",
                '% di sconto Massima',
                'Promozioni lifetime garantite',
                'Experience dedicate'
            ],
            price: 199
        }
    }
