import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { UiState } from './slice-ui.d';


// initial slice state
const initialState: UiState = {

    isAppMobile: false,
    isLoading: false,
    isSidebarMenuOpen: true
};

const uiSlice = createSlice(
    {
        name: 'ui',
        initialState,

        reducers: {

            setIsAppMobile: (state, action: PayloadAction<boolean>) => {
                state.isAppMobile = action.payload;
            },
            setIsLoading: (state, action: PayloadAction<boolean>) => {
                state.isLoading = action.payload;
            },
            setIsSidebarMenuOpen: (state, action: PayloadAction<boolean>) => {
                state.isSidebarMenuOpen = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    setIsAppMobile,
    setIsLoading,
    setIsSidebarMenuOpen
} = uiSlice.actions;

// reducer for store
export default uiSlice.reducer;
