import { useState } from 'react';

// routing
import { useNavigate } from 'react-router';

// assets
import EllenikaLogo from 'assets/logo-ellenika.png'

// style
import { 
    Page,
    Content,
    LogoBox,
    TextBox,
    Title,
    Description,
    ResendButton,
    Signature
} from './style/VerifyEmail.style';
import BaseCard from 'components/BaseCard/BaseCard.component';


const VerifyEmail = () => {

    const [isMouseOverButton, setIsMouseOverButton] = useState(undefined);
    const navigate = useNavigate();

    const onMouseOverHandler = () => setIsMouseOverButton(true);
    const onMouseLeaaveHandler = () => setIsMouseOverButton(false);

    const resendClickHandler = async () => {
        
        navigate('/authentication/login');
    };

    return (
        <Page>
            <Content>
                <BaseCard
                    maxWidth='1200px'
                    >
                    <LogoBox>
                        <img alt='logo' src={EllenikaLogo} />
                    </LogoBox>

                    <TextBox>
                        <Title>
                            Verifica e-mail
                        </Title>

                        <hr style={{ 'height': '2px', 'width': '100%', 'color': '#1E2251FC' }}/>

                        <Description>
                            Ti abbiamo inviato una mail di verifica, controlla nella sezione posta in 
                            arrivo. Qualora non dovesse esserci attendi 5 minuti o controlla 
                            nella cartella spam. 
                        </Description>

                        <ResendButton
                            onMouseOver={onMouseOverHandler}
                            onMouseLeave={onMouseLeaaveHandler}
                            isMouseOver={isMouseOverButton}
                            onClick={resendClickHandler}
                        >
                            Torna al login
                        </ResendButton>

                        <hr style={{ 'height': '2px', 'width': '100%', 'color': '#1E2251FC' }}/>

                        <Signature>
                            Il Team Ellenika Utility
                        </Signature>
                    </TextBox>
                </BaseCard>
            </Content>
        </Page>
    )
};

export default VerifyEmail;
