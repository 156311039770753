import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const FooterRow = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    gap: 20px;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    padding: 20px 30px;
    
    background: white;
    
    @media ${devices.tablet} {
        
        align-items: center;

        gap: unset;
        flex-direction: row;
        height: 80px;
        padding: 0 30px;
    }
`;

export const InfoBox = styled.div``;

export const Info = styled.p`

    margin: 0;
    padding: 0;

    a{

        text-decoration: none;
        color: black;
        cursor: pointer;
    }

    span {
        cursor: pointer;
    }
`;

export const SocialBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    img {
        max-width: 50px;
    }
`
