import { sendEmailVerification } from "firebase/auth";
import { child, get, getDatabase, ref, set } from "firebase/database";
import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import "firebase/compat/firestore";


const FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// const FIREBASE_CONFIG_TEST = {
//     apiKey: process.env.REACT_APP_APIKEY_TEST,
//     authDomain: process.env.REACT_APP_AUTHDOMAIN_TEST,
//     databaseURL: process.env.REACT_APP_DATABASEURL_TEST,
//     projectId: process.env.REACT_APP_PROJECTID_TEST,
//     storageBucket: process.env.REACT_APP_STORAGEBUCKET_TEST,
//     messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID_TEST,
//     appId: process.env.REACT_APP_APPID_TEST,
//     measurementId: process.env.REACT_APP_MEASUREMENTID_TEST,
// };


/**
 * DB base functions
 */

const firebaseInit = async () => {

    try {

        const initResponse = firebase.initializeApp(FIREBASE_CONFIG);
        
        firebase.auth().onAuthStateChanged(
            (user: any) => {
            if (user) return localStorage.setItem('authUser', JSON.stringify(user));
            localStorage.removeItem('authUser');
            }
        );

        return initResponse;
    } catch (error: any) {
        throw error; // to do change error handler
    }
};

export const initFirebaseBackend = async () => {

    const authUser = localStorage.getItem('authUser');
    if (authUser === null) return firebaseInit();
};

export const loginUser = async (email: string, password: string) => {

    try {

        await firebaseInit();
        const response = await firebase.auth().signInWithEmailAndPassword(email, password);

        return response;
    } catch (error: any) {
    throw error; // to do change error handler
    }
};

export const getFirebaseDB = () => {

    firebaseInit();
    const app = firebase.initializeApp(FIREBASE_CONFIG);      
    return getDatabase(app);
};

export const getDBData = async (path: string) => {

    const database = ref(getFirebaseDB());

    try {
        
        const snapshot = await get(child(database, path));

        if (snapshot.exists()) return snapshot.val();

        return []
    } catch (error: any) {
        throw error;
    }
};

export const userDBData = async () => {
  
    const userID = JSON.parse(localStorage.getItem('authUser')!).uid; // to do manage null and trycatch
    const userDataPath = `userData/${userID}`;
  
    return await getDBData(userDataPath);
};

export const updateDB = async (dbPath: string, payload: any) => {

    const database = await getFirebaseDB(); // to do manage trycatch
    const dbData = await getDBData(`${dbPath}`);

    if (dbData.length === 0) return set(ref(database, `${dbPath}`), {...payload});
    return set(ref(database, `${dbPath}`), {...dbData, ...payload});
};

export const removeDataDB = async (dbPath: string, payload: any) => {

    const database = await getFirebaseDB(); // to do manage trycatch
    set(ref(database, `${dbPath}`), {...payload});
};
  

/**
 * User's DB functions
 */

export const registerUser = async (email: string, password: string) => {

    try {
        
        await firebaseInit();
        await firebase.auth().createUserWithEmailAndPassword(email, password);

        return firebase.auth().currentUser;
    } catch (error: any) {
        throw error;
    }
};

export const recoverPassword = async (email: string) => {

    const actionsCodeSetting = null; // it's possible to set actions for the user's redirect

    try {
        
        await firebaseInit();
        const response = await firebase.auth().sendPasswordResetEmail(email, actionsCodeSetting);

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const logoutUser = async () => {

    try {
        
        const response = await firebase.auth().signOut();

        return response;
    } catch (error: any) {
        throw error;
    }
};

export const sendVerificationMail = async (authData: any) => {

    const auth = authData ?? localStorage.getItem('authUser'); // or change user data funct
    
    try {
        const response = await sendEmailVerification(auth.currentUser);
        return response;
    } catch (error) {
        throw error;
    }
};
    