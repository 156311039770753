import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

// routing
import { useNavigate } from 'react-router';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// utils
import useAppDispatch from 'hooks/useAppDispatch';
import { loginUser } from 'services/firebase.services';

// components
import BaseInput from 'components/BaseInput/BaseInput.component';

// style
import {
    Content,
    LoginText,
    LoginButton,
    LinkBox,
    SwitchText
 } from './style/LoginForm.style';


const LoginForm: FC<{}> = () => {

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswodValid] = useState<boolean>(false);
    
    const mailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const emailValidityHandler = (isEmailValid: boolean) => setIsEmailValid(isEmailValid);
    const passwordValidityHandler = (isPasswordValid: boolean) => setIsPasswodValid(isPasswordValid);
    
    const signupHandler = () => navigate('/authentication/signup');
    const passwordRecoveryHandler = () => navigate('/authentication/password-recovery');

    const openLegalHandler = (navigateTo: 'terms' | 'cookie' | 'privacy') => {
        
        const baseUrl = ((window.location.origin.includes('localhost') ? 'http://localhost:3000' : (
            window.location.origin.includes('test')) ? 'https://testapp.ellenikautility.com' : 
            'https://app.ellenikautility.com'));
        window.open(
            `${baseUrl}/${navigateTo}`,
            '_blank',
            'noreferrer noopener'
        );
    }
 
    const onSubmitFormHandler = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {

        e.preventDefault();
        if (isSubmitButtonDisabled) return;

        try {
            
            await loginUser(mailRef.current?.value!, passwordRef.current?.value!);

            navigate('/reservedArea/');
        } catch (error: any) {
            
            const snackbarData: SnackbarOptions = {
                message: "Credenziali errate...",
                type: 'error'
            };

            dispatch( addSnackbar(snackbarData) );

            console.warn("ERROR:", error);
        }
    };

    useEffect(() => {

        if (isEmailValid && isPasswordValid) return setIsSubmitButtonDisabled(false);
        setIsSubmitButtonDisabled(true);
    }, [isEmailValid, isPasswordValid]);

    return (
        <Content
            onSubmit={onSubmitFormHandler}
        >
            <LoginText>
                Accedi ad Ellenika Utility
            </LoginText>

            <BaseInput 
                inputRef={mailRef}
                validityChecker={emailValidityHandler}
            />

            <BaseInput 
                inputRef={passwordRef}
                type='password'
                validityChecker={passwordValidityHandler}
            />

            <LinkBox>
                Password dimenticata? <span onClick={passwordRecoveryHandler}>Clicca qui</span>
            </LinkBox>

            <LoginButton
                $isDisabled={isSubmitButtonDisabled}
            >
                Accedi
            </LoginButton>

            <SwitchText>
                Se non hai ancora un account su Ellenika Utility clicca qui per la <span onClick={signupHandler}>registrazione</span>
            </SwitchText>

            <LinkBox>
                Clicca qui per visionare <span onClick={() => openLegalHandler('terms')}>T&C</span>, <span onClick={() => openLegalHandler('cookie')}>Cookie</span> e <span onClick={() => openLegalHandler('privacy')}>Privacy</span>.
            </LinkBox>
        </Content>
    );
};

export default LoginForm;
