import { BaseButton } from 'style/components/BaseButton.style';
import styled from 'styled-components';

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 20px;
    gap: 20px;
`;

export const Title = styled.h2``;

export const Description = styled.p``;

export const ButtonRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;
    gap: 20px;
`;

export const AcceptButton = styled(BaseButton)``;

export const DeniedButton = styled(BaseButton)``;
