import { useEffect } from 'react';

// routing
import { Outlet, useNavigate } from 'react-router-dom';

// components
import Sidebar from 'App/layout/Sidebar/Sidebar.component';
import Header from 'App/layout/Header/Header.component';
import Footer from 'App/layout/Footer/Footer.component';

// style
import { 
    Page,
    PageContent
} from './style/ReservedArea.style';


const ReservedArea = () => {

    const navigate = useNavigate();
    const authUser = localStorage.getItem('authUser');

    useEffect(() => {

        if (authUser === null) navigate('/');
    }, [authUser, navigate]);
    
    return (
        <Page>
            <Sidebar />
            {/* {isUserLoggedIn && <Outlet />} */}
            <PageContent>
                <Header />

                <Outlet />

                <Footer />
            </PageContent>
        </Page>
    )
};

export default ReservedArea;

