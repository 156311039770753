import { FC, useEffect } from "react";

// routing
import { useNavigate } from "react-router";

// redux
import { setIsLoading } from "redux/slices/slice-ui";

// hooks
import useAppDispatch from "hooks/useAppDispatch";

// style
import {
    Page,
    Title,
    Desctiption
 } from './style/PaymentConfirmed.style';


const PaymentConfirmed: FC<{}> = () => {
    
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    dispatch( setIsLoading(false) );

    useEffect(() => {
      const timeout = setTimeout(() => {
        navigate('/reservedarea');
      }, 5000);
    
      return () => {
        clearTimeout(timeout);
      }
    }, [])

    return (
        <Page>
            <Title>
                Pagamento Confermato
            </Title>

            <Desctiption>
                Il tuo pagamento è stato confermato, il prodotto da te acquistato sarà inserito tra i tuoi prodotti.
            </Desctiption>
        </Page>
    );
};

export default PaymentConfirmed;
