export const SidebarMenu = [
    {
        label: 'Home',
        navigateTo: '',
        category: 'Home',
        icon: 'home'
    },
    {
        label: 'My Address',
        navigateTo: 'wallet',
        category: 'Wallet',
        icon: 'myAddress'
    },
    {
        label: 'Partner',
        navigateTo: 'partner',
        category: 'NFT Shop',
        icon: 'partner'
    },
    {
        label: 'Ellen Pass Utility',
        navigateTo: 'ellenpass',
        category: 'NFT Shop',
        icon: 'ellenPass'
    },
    {
        label: 'Ellenika zone / State 1',
        navigateTo: 'state1',
        category: 'NFT Shop',
        icon: 'state1'
    },
    {
        label: 'Contacts',
        navigateTo: 'contacts',
        category: 'Contacts',
        icon: 'contact'
    },
    // {
    //     label: 'Personal Area',
    //     navigateTo: 'personalArea',
    //     category: 'Home',
    //     icon: ''
    // },
]