import { styled } from "styled-components";

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    padding: 100px 0 0;
`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 40px;
    font-weight: 700;
    color: green;

    @media screen and (max-width: 768px) {

        font-size: 50px;
    }
`;

export const Desctiption = styled.div`

    margin: 0;
    padding: 0;

    font-size: 20px;
`;
