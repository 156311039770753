import { FC, useState } from 'react';

// reduxz
import { setIsSidebarMenuOpen } from 'redux/slices/slice-ui';

// routing
import { Link } from 'react-router-dom';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// types
import { MenuItemProps } from './MenuItem.component.d';

// style
import {
    Item,
    // Category
} from './style/MenuItem.style';


const MenuItem: FC<MenuItemProps> = ({ 
    label,
    icon,
    navigateTo
 }) => {

    const [focus, setFocus] = useState<boolean>(false);
    const { isAppMobile } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();

    const onOverHandler = () => setFocus(true);
    const onMouseLeaveHandler = () => setFocus(false);

    const menuClickHandler = () => {
        if (isAppMobile) dispatch( setIsSidebarMenuOpen(false) );
    };

    return (
        <Item
            onMouseOver={onOverHandler}
            onMouseLeave={onMouseLeaveHandler}
            $isOnFocus={focus}
            onClick={menuClickHandler}
        >               
            <Link 
                to={navigateTo}
            >
                <img alt='menu icon' src={icon} />
                {label}
            </Link>
        </Item>
    );
};

export default MenuItem;
