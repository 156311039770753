import styled from 'styled-components';
import devices from 'style/breakpoints';
// import colors from 'style/palette/palette';


export const Container = styled.div`

    width: auto;
    padding: 0 10px;

    border: none;
    
    position: fixed;
    top: 10.2%;
    left: 50%;
    z-index: 1000;
    
    transform: translateX(-50%);
    
    display: none;
    
    @media ${devices.appMinWidth} {
        
        display: inline-block;
    }
    `;
    /* background: ${colors.transparent}; */
