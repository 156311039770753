import React from 'react';

// assets
import DiscordIcon from 'assets/icons/discord-white-icon.jpg';

// style
import { LinearDiscordButton } from './style/DiscordButton.style';


const DiscordButton = () => {

    const onClickHandler = () => window.location.href = 'https://discord.gg/Gn5qpDdqwF';

    return (
        <LinearDiscordButton onClick={onClickHandler}>
            join discord

            <img alt='discord' src={DiscordIcon} />
        </LinearDiscordButton>
    );
};

export default DiscordButton;
