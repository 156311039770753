import styled from 'styled-components';


export const Page = styled.div``;

export const Content = styled.div`

    padding: 50px;

    img:first-child {
        max-width: 300px;
    }
`;

export const Title = styled.h1``;

export const SubTitle = styled.h3``;