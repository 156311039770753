// utils
import { ethers } from 'ethers';
import { abi } from 'assets/ellenPassAbi';

const _smart_contract = process.env.REACT_APP_SMART_CONTRACT;
const _private_key = process.env.REACT_APP_PRIVATE_KEY;
const _address_smart_contract = process.env.REACT_APP_ADDRESS_SMART_CONTRACT;

export const assignEllenPass = async (userAddress: string, typeOfMinting: 'premium' | 'gold' | 'platinum') => {

    if ((_address_smart_contract === undefined) || 
        (_private_key === undefined) || 
        (_smart_contract === undefined)) throw new Error ("ERROR: not able to get init informations");

    const provider = new ethers.JsonRpcProvider(_smart_contract);
    const innerSigner = new ethers.Wallet(_private_key, provider);   
    const smartContractInit = new ethers.Contract(_address_smart_contract, abi , innerSigner);

    try {

        const cost = await smartContractInit.cost();
     
        const mintPayload = {
            value: cost,
            gasLimit: 10000000
        };
        
        let response;

        if (typeOfMinting === 'premium') response = await smartContractInit.mint_premium(1, userAddress, mintPayload);
        if (typeOfMinting === 'gold') response = await smartContractInit.mint_gold(1, userAddress, mintPayload);
        if (typeOfMinting === 'platinum') response = await smartContractInit.mint_platinum(1, userAddress, mintPayload);

        return response;
    } catch (error: any) {
        throw error;
    }    
};
    
export const checkEllenPass = async (userAddress: string, typeOfMinting: 'premium' | 'gold' | 'platinum') => {

    if ((_address_smart_contract === undefined) || 
        (_private_key === undefined) || 
        (_smart_contract === undefined)) throw new Error ("ERROR: not able to get init informations");

    const provider = new ethers.JsonRpcProvider(_smart_contract);
    const innerSigner = new ethers.Wallet(_private_key, provider);   
    const smartContractInit = new ethers.Contract(_address_smart_contract, abi , innerSigner);

    try {
        
        let response;

        if (typeOfMinting === 'premium') response = await smartContractInit.checkpremium(userAddress);
        if (typeOfMinting === 'gold') response = await smartContractInit.checkgold(userAddress);
        if (typeOfMinting === 'platinum') response = await smartContractInit.checkplatinum(userAddress);

        return response
    } catch (error: any) {
        throw error;
    }    
};
    