import styled from 'styled-components';

export const BaseButton = styled.button`

    margin: 0;
    padding: 10px 30px;

    border-radius: 5px;
    border: 2px solid black;

    font-size: 18px;
    font-weight: 500;

    text-transform: uppercase;

    color: white;
    background: #011f71;

    cursor: pointer;
`;