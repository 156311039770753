export const COOKIE_TYPES = [
    'Tecnici',
    'Funzionali',
    'Analitici',
    'Profilazione'
];

export const COOKIE_DESC = [
    'I cookie tecnici sono sempre attivi',
    'Vuoi che i cookie funzionali vengano registrati sul tuo browser?',
    'Vuoi che i cookie analitic vengano registrati sul tuo browser?',
    'Vuoi che i cookie di profilazione vengano registrati sul tuo browser?'
];

export const COOKIE_NAME = [
    'CookieConsent',
    'Cookie_Funzionali',
    'Cookie_Analitici',
    'Cookie_Profilazione',
]