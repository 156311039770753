import { FC } from 'react';

// asset
import LogoPNG from 'assets/logo-orizzontale.png';

// style
import {
    Page,
    Content,
    Title,
    SubTitle
} from './style/TermsAndConditions.style';


const TermsAndConditions: FC<{}> = () => {

    return (
        <Page>
            <Content>
                <img
                    alt='ellenika logo'
                    src={LogoPNG}
                />

                <Title>
                    CONDIZIONI GENERALI DI VENDITA
                </Title>

                <SubTitle>
                    DISPOSIZIONI PRELIMINARI
                </SubTitle>
                
                Le presenti condizioni generali di vendita (“CGV”) disciplinano l’utilizzo dei Prodotti offerti da Ellenika Utility S.r.l., società di diritto italiano, con sede legale in Bergamo (BG), via Daniele Piccinini n. 2, capitale sociale di € 10.000,00 interamente versato, iscritta al Registro Imprese di Bergamo, P.IVA e C.F. 04660680168, REA BG – 479563 (“Società”).<br />
                L’utilizzo dei Prodotti è consentito a persone giuridiche e a persone fisiche che abbiano raggiunto l’età stabilita dal proprio paese di residenza per avere la piena capacità di agire (“Utenti”).<br />
                I Prodotti vengono offerti dalla Società attraverso la piattaforma web disponibile sul proprio sito internet www.ellenikautility.com (“Sito”). <br />
                L’accesso al Sito, la fruizione dei Prodotti e/o l’utilizzo di tutte le informazioni e i contenuti messi a disposizione dalla Società per il tramite del Sito o in relazione alla stessa sono subordinati al rispetto e all’accettazione, da parte dell’Utente, delle presenti CGV, nonché dell’informativa in materia di trattamento dei dati personali (“Informativa Privacy”).<br />
                La Società si riserva la facoltà di modificare in ogni momento le CGV e queste saranno valide dal giorno della loro pubblicazione sul sito web www.ellenikautility.com. Gli Utenti sono pertanto invitati ad accedere regolarmente al Sito e consultare, prima di effettuare qualsiasi acquisto, la versione più aggiornata delle CGV. L’Utente potrà consultare e memorizzare le CGV accedendo al link presente nell’apposita sezione del Sito.<br />
                Per i Prodotti e servizi che rinviano a siti di società partner, l’Utente dovrà inoltre accettare le condizioni contrattuali e/o l’informativa in materia di trattamento dei dati personali da questi applicati.<br />
                Lo status di Utente è acquisito una volta completato il processo di registrazione al Sito, nel rispetto della procedura a tal fine prevista e dopo aver fornito tutte le informazioni appositamente richieste.<br />
                Resta inteso e di ciò l’Utente prende atto che la disciplina di cui al Codice del Consumo non troverà applicazione allorquando l’Utente stesso agisca e concluda il Contratto per scopi riconducibili all’attività imprenditoriale o professionale svolta.<br />
                L’Utente può prendere contatti con la Società utilizzando i canali di contatto indicati nel successivo articolo 9.<br />
                
                <SubTitle>
                    OGGETTO DEL CONTRATTO
                </SubTitle>
                
                Accedendo al Sito l’Utente potrà acquistare Asset digitali immobiliari e non fungibile token utility (“NFT Utility”), nonché usufruire dei servizi correlati (“Prodotti”). In particolare:<br />
                Nella sezione “Ellenika Zone” del Sito l’utente può acquistare uno o più Asset digitali immobiliari (“Asset”) dello spazio di 8.000.000 mq di proprietà della Società sul metaverso creato State1 Srl (“State1”) con sede legale in Genova (GE), Corso Torino 1, P.IVA IT02817950997 (https://www.state1.io/it).<br />
                NFT Utility (denominati “Ellen Pass”) sono rappresentazioni digitali crittografiche, registrate in una delle tecnologie blockchain, unici e non intercambiabili, che attribuiscono all’Utente il diritto di usufruire di benefici e promozioni presso una delle società partner (“Partner”).<br />
                Gli Asset acquistabili sono descritti in maniera dettagliata nelle relative pagine prodotto (qualità, caratteristiche, disponibilità, prezzo, etc.). Potrebbero evidenziarsi alcuni errori, imprecisioni o piccole differenze tra quanto pubblicato sul Sito e l’Asset acquistato; le immagini degli Asset sono solo rappresentative e non costituiscono elemento contrattuale.<br />
                Gli Ellen Pass acquistabili sono elencati e dettagliati nella relativa sezione del Sito che consente di visualizzare le società Partner che hanno aderito alla community, i benefici, le promozioni, i prezzi nonché le condizioni poste da ciascun Partner per l’utilizzo dell’NFT.<br />
                Per poter usufruire dei servizi, l’Utente deve dotarsi e avere la piena disponibilità di un portafoglio digitale (“Wallet”) per NFT sul quale verranno trasferiti gli Ellen Pass acquistati, un account con Wallet personale sul sito di State1 (https://www.state1.io/it) per il trasferimento degli Asset ed acquistare dal sito di quest’ultima gli accessori necessari ed espressamente indicati. <br />
                Gli Asset e gli Ellen Pass acquistabili dal Sito non costituiscono in alcun modo dei servizi finanziari.<br />
                La Società ha la facoltà di modificare, in qualsiasi momento, la quantità e la tipologia di Asset e di Ellen Pass acquistabili sul Sito.<br />
                L’acquisto dei Prodotti offerti dalla Società non dà all’Utente alcun diritto di utilizzo relativamente al nome, all’immagine, ai segni distintivi e agli elementi relativi alla Società.<br />
                L’acquisto degli Asset e degli Ellen Pass da parte di Utenti è irreversibile e non duplicabile, poiché avviene tramite tecnologia blockchain e si conclude con il trasferimento dell’Asset o dell’NFT sul Wallet personale dell’Utente. Si rinvia a quanto previsto all’articolo 7 in tema di esclusione del diritto di recesso.<br />
                
                <SubTitle>
                    AREA RISERVATA
                </SubTitle>
                
                Per poter acquistare i Prodotti, l’Utente dovrà creare un Account (“Account”) seguendo la procedura di registrazione indicata sul sito www.ellenikautility.com, fornendo tutte le informazioni richieste, dichiarare di avere letto e di accettare le CGV e di aver preso visione dell’informativa privacy.<br />
                L’Utente assume la piena responsabilità delle informazioni fornite in fase di registrazione all’area riservata e si impegna ad utilizzare gli Asset, gli Ellen Pass, il Sito e tutti i suoi contenuti, in conformità alla legge, al buon costume, all’ordine pubblico e a quanto stabilito dalle presenti CGV.<br />
                Per gli accessi successivi alla registrazione l’Utente potrà accedere alla propria area personale mediante (i) l’indirizzo email (“Username”) scelto in fase di registrazione nel Sito e (ii) una parola chiave (“Password”) scelta dall’Utente stesso (congiuntamente, le “Credenziali di Accesso”). Nella propria area personale l’Utente dovrà inserire i dati del proprio Wallet NFT, in caso intenda acquistare Ellen Pass, e/o del Wallet State1 nel caso intenda acquistare Asset. In ogni momento l’Utente potrà visualizzare i propri dati personali, le transazioni e gli acquisiti effettuati.<br />
                L’Utente è tenuto a conservare le Credenziali di Accesso utilizzando la massima riservatezza e diligenza, in modo tale da impedire l’accesso e/o l’indebito utilizzo da parte di terzi. L’Utente prende atto e riconosce che la Società non potrà essere ritenuta responsabile, salvo i casi di dolo e colpa grave della stessa, in ordine alla sicurezza del Sito e dei propri sistemi, nonché delle Credenziali di Accesso sopra riferite. L’Utente sarà il solo responsabile, pertanto, di qualsiasi danno arrecato a sé stesso e/o a terzi in caso di accesso alle Credenziali di Accesso e/o indebito utilizzo delle stesse da parte di terzi.<br />
                L’Utente è tenuto ad informare immediatamente la Società di qualsiasi utilizzo non autorizzato o improprio delle Credenziali e del proprio account, e a segnalare eventuali violazioni da parte di terzi. La Società si riserva di adottare ogni rimedio ivi inclusa la sospensione o la rimozione dell’account. I predetti rimedi saranno esperiti dalla Società previo idoneo preavviso all’Utente, salvo l’ipotesi in cui detto preavviso pregiudichi oppure impedisca il corretto esperimento delle attività. <br />
                È consentito ad ogni Utente l’apertura di un unico account. Qualora fosse rilevata, o fondatamente sospettata, l’esistenza contemporanea di più account riferibili al medesimo Utente, la Società si riserva di adottare ogni rimedio esperibile ivi inclusa la sospensione o la rimozione di uno o tutti gli account riferibili al medesimo Utente.<br />
                La Società, inoltre, si riserva la facoltà di sospendere l’accesso all’area personale dell’Utente e/o cancellare la stessa, a propria discrezione, senza obbligo di preavviso e con effetto immediato nei seguenti casi:<br />
                qualora l’Utente abbia fornito informazioni non veritiere durante la registrazione per la creazione della propria area personale;<br />
                qualora l’Utente si rifiuti di fornire informazioni relative alla propria identità o di fornire la documentazione indicata all’articolo 4.7 ovvero la documentazione richiesta da qualsiasi autorità che ne abbia titolo secondo la normativa vigente al momento della richiesta;<br />
                in caso di violazione da parte dell’Utente delle presenti CGV;<br />
                in caso di utilizzo fraudolento o illecito, anche solo sospetto, del Sito;<br />
                qualora venga così richiesto dall’autorità giudiziaria, dalle autorità di ordine pubblico e da qualsiasi altra autorità che ne abbia titolo secondo le leggi applicabili.<br />
                In caso di sospensione, la Società potrà decidere – a propria discrezione e insindacabile giudizio – se riattivare o cancellare permanentemente l’account dell’Utente. Per procedere alla riattivazione dell’account sospeso, l’Utente deve farne richiesta esplicita alla Società, inviando apposita comunicazione all’indirizzo mail indicato al successivo articolo 9.<br />
                L’Utente potrà in qualsiasi momento modificare i dati inseriti in sede di registrazione e cancellare il proprio account seguendo l’apposita procedura indicata nel Sito.<br />
                
                <SubTitle>
                    MODALITÀ DI ACQUISTO E CONCLUSIONE DEL CONTRATTO
                </SubTitle>
                
                Per procedere con l’acquisto l’Utente dovrà (i) accedere alla relativa sezione del sito; (ii) selezionare e inserire nel Carrello gli Asset o gli Ellen Pass desiderati; (iii) inserire i dati (nome, cognome, codice fiscale, indirizzo di fatturazione, indirizzo email) necessari anche per l’emissione della fattura; (iv) selezionare la modalità di pagamento, (v) inserire i dati di pagamento; (vi) accettare le CGV e dichiarare di avere preso visione dell’informativa privacy e (vii) procedere all’inoltro della proposta di acquisto (“Proposta di Acquisto”).<br />
                La Proposta di Acquisto è disciplinata dalle presenti CGV ed è vincolante per l’Utente. L’Utente è pertanto tenuto a leggere attentamente tutte le istruzioni fornite nel corso della procedura di acquisto, nonché le presenti CGV e l’Informativa Privacy, che dovranno essere accettare espressamente, ponendo particolare attenzione alle disposizioni relativa al corrispettivo, alle modalità di pagamento e all’esclusione del diritto di recesso.<br />
                Prima dell’inoltro della Proposta di Acquisto, l’Utente ha la possibilità di apportare variazioni ai dati forniti seguendo l’apposita procedura indicato nel Sito (a titolo esemplificativo e non esaustivo, l’Utente ha la facoltà di modificare i dati della fatturazione, di aggiungere e/o sostituire dal Carrello gli NFT che intende acquistare ecc.).<br />
                La Società può rifiutare una Proposta di Acquisto nei seguenti casi:<br />
                in caso di mancata disponibilità dei Prodotti selezionati;<br />
                nel caso in cui vi sia una segnalazione o il sospetto di attività fraudolente o illegali, ivi incluso il sospetto che gli acquisti vengano per finalità ricadenti nell’ambito del Decreto Legislativo n. 231/2007 in materia di antiriciclaggio;<br />
                in caso di malfunzionamento, assenza o incompatibilità del Wallet indicato dall’Utente per il trasferimento degli Asset o degli NFT.<br />
                Tutte le comunicazioni relative alla Proposta di Acquisto, ivi comprese l’accettazione o il rifiuto delle stesse, sarà trasmessa all’indirizzo email indicato dall’Utente in fase di registrazione del proprio account. La Conferma di Acquisto conterrà un riepilogo delle caratteristiche essenziali dei Prodotti acquistati, l’indicazione dettagliata del prezzo e delle modalità di pagamento utilizzate. Successivamente alla ricezione della Conferma di Acquisto, quest’ultimo non potrà più essere annullato né modificato.<br />
                Il contratto si perfeziona quando l’Utente riceve conferma da parte della Società dell’accettazione della Proposta di Acquisto (“Conferma di Acquisto”). Successivamente all’avvenuto pagamento del prezzo la Società effettuerà i trasferimenti degli Asset e degli Ellen Pass sui Wallet indicati dall’Utente.<br />
                La Proposta di Acquisto e i dati dell’Utente saranno conservati dalla Società quale titolare del trattamento dati personali ai sensi del Reg. UE 679/2016 (GDPR) per il periodo di tempo previsto dalla normativa vigente, così come descritto nell’Informativa sulla Privacy. <br />
                La Società si riserva il diritto di richiedere all’Utente, in ogni tempo, la documentazione necessaria per adempiere alle disposizioni normative in materia di antiriciclaggio, con particolare riferimento alle disposizioni di cui al Decreto Legislativo n. 231/2007, così come modificato dal Decreto Legislativo n. 90/2017 e dal Decreto Legislativo n. 125/2019.<br />
                
                <SubTitle>
                    CORRISPETTIVO E MODALITÀ DI PAGAMENTO
                </SubTitle>
                
                I prezzi degli Asset e degli Ellen Pass sono indicati in euro e si intendono comprensivi di IVA. La Società si riserva il diritto di modificare il prezzo in ogni momento.<br />
                All’Utente sarà addebitato il prezzo indicato nel riepilogo della Conferma di Acquisto. Il prezzo può essere corrisposto mediante carte di credito e/o altre formule di pagamento consentite ed espressamente indicate nel Sito.<br />
                Per ciascun acquisto, su richiesta dell’Utente, la Società emetterà apposita fattura con le informazioni fornite provvedendo alla trasmissione della stessa nelle modalità e nei termini previsti dalla legge, inviandone copia via email all’Utente.<br />
                
                <SubTitle>
                    GARANZIE E LIMITAZIONI DI RESPONSABILITÀ
                </SubTitle>
                
                Con l’accettazione delle presenti CGV, l’Utente riconosce che gli Asset e gli Ellen Pass sono prodotti sviluppati da soggetti terzi alla Società e pertanto la garanzia collegata alla conformità del prodotto è resa direttamente dalle società Partner in conformità alle condizioni generali di vendita da essi applicate e rese note sui propri siti web.<br />
                In particolare:<br />
                la Società non garantisce che il Sito, gli Asset, gli Ellen Pass e i servizi connessi soddisfino le esigenze e le aspettative dell’Utente, né che gli stessi siano immuni da errori, né che eventuali errori vengano corretti;<br />
                la Società non garantisce: (i) che i Prodotti e il Sito siano disponibili senza interruzioni, con puntualità, in modo sicuro o esente da errori, (ii) che l’aspettativa dell’Utente in relazione ai risultati ottenuti dall’utilizzo dei Prodotti sia soddisfatta (iii) che la qualità dei Prodotti, delle informazioni o altri materiali acquisiti o ottenuti per il tramite del Sito possano soddisfare le esigenze dell’Utente, né (iv) che eventuali errori nel Sito vengano corretti; <br />
                la Società non sarà responsabile per: (i) eventuali errori derivanti dal mancato funzionamento del Sito; (ii) errori, malfunzionamenti o inadempimenti imputabili ai Partner e/o a terzi in relazione agli Asset, agli Ellen Pass e ai servizi ad essi connessi; (iii) standard qualitativi delle prestazioni erogate dai Partner; (iv) eventuali inadempimenti contrattuali dei Partner non attinente ai Prodotti oggetto delle presenti CGV; (v) errori o malfunzionamenti connessi ai Wallet degli Utenti.<br />
                la Società non fornisce alcun servizio di supporto e assistenza tecnica o informatica successivamente al corretto trasferimento dei Prodotti acquistati. Ogni eventuale assistenza e supporto di carattere tecnico per la creazione e/o utilizzo del Wallet, finalizzato ad una migliore gestione del processo di acquisto degli Asset e degli Ellen Pass, potrà essere fornito discrezionalmente dalla Società sulla base delle sue disponibilità, senza che ciò implichi forme di assistenza tecnica obbligatoria.<br />
                Con l’accettazione delle presenti CGV, l’Utente prende atto ed accetta che le società Partner potranno in qualsiasi momento revocare la partnership con la Società e l’adesione ai servizi offerti con gli Ellen Pass acquistati. <br />
                Con la creazione del proprio account nell’Area Riservata del Sito, l’Utente accetta di manlevare e mantenere indenne la Società in relazione a ogni pregiudizio, perdita, danno, responsabilità, costo, onere, spese, incluse le spese di natura legale, e a qualsivoglia pretesa e/o domanda e/o azione (sia di carattere risarcitorio e/o a titolo di indennizzo) che sia stata e/o possa essere avanzata, nei confronti della Società, in qualunque sede, da parte di terzi, in relazione alla violazione da parte dell’Utente dei doveri di custodia delle Credenziali di Accesso, nonché in relazione alla violazione di qualsivoglia obbligazione imposta a suo carico dalle presenti CGV.<br />
                Nulla di quanto previsto nelle presenti CGV avrà l’effetto di limitare o escludere la responsabilità della Società in caso di danni derivanti da dolo o colpa grave ai sensi dell’art. 1229 del codice civile.<br />
                
                <SubTitle>
                    ESCLUSIONE DEL DIRITTO DI RECESSO
                </SubTitle>
                
                Con la creazione del proprio account nell’Area Riservata del Sito, l’Utente prende atto ed accetta espressamente che per gli acquisti di Asset e Ellen Pass è escluso l’esercizio del diritto di recesso.<br />
                Anche laddove l’Utente si qualifichi come “consumatore” ai sensi del Codice del Consumo di Decreto Legislativo 06/09/2005 n. 206, il diritto di recesso è escluso in conformità all’art. 59, comma 1, a) e o), del medesimo Codice, trattandosi di acquisto di servizi ad esecuzione immediata e/o  fornitura di contenuto digitale mediante un supporto non materiale che rispettano i requisiti ivi previsti: “Esecuzione iniziata con l’accordo espresso del consumatore e con l’accettazione della perdita del diritto di recesso a seguito della piena esecuzione del contratto da parte del professionista”.<br />
                
                <SubTitle>
                    PROPRIETÀ INTELLETTUALE
                </SubTitle>
                
                L’Utente riconosce che il Sito, gli Asset e gli Ellen Pass sono protetti dalle leggi in materia di proprietà intellettuale e/o industriale.<br />
                L’Utente prende atto che tutti i diritti relativi a marchi, figurativi e/o verbali, nonché tutte le illustrazioni, i design industriali registrati o non registrati, le immagini, le grafiche, i video e i loghi protetti da copyright, e, più in generale, tutti i diritti di proprietà intellettuale relativi agli Asset  e agli Ellen Pass sono e resteranno della Società o dei loro legittimi titolari, senza che, in alcun modo, l’acquisto possa rappresentare trasferimento o licenza di tali diritti d’autore o di proprietà intellettuale. L’acquisto degli Asset e degli Ellen Pass non implica, inoltre, alcun diritto di utilizzo per scopi commerciali da parte dell’Utente del nome, dell’immagine, del logo e degli elementi creativi relativi al marchio o ai contenuti digitali.<br />
                L’Utente prende atto che con l’utilizzo del Sito non acquista in alcun modo i diritti relativi a marchi, figurativi e/o verbali, nonché tutte le illustrazioni, i design industriali registrati o non registrati, le immagini, le grafiche, i video e i loghi protetti da copyright, e, più in generale, tutti i diritti di proprietà intellettuale relativi alle immagini, video, audio, che sono e resteranno di proprietà della Società o dei loro legittimi titolari. La Società, pertanto, non cede nessun diritto di sfruttamento economico in relazione a tali elementi.<br />
                La riproduzione totale o parziale, la rappresentazione, la modifica o l'utilizzo di qualsiasi elemento del Sito, Asset digitali immobiliari e agli Ellen Pass non autorizzata dalla Società è severamente vietata.<br />
                L'Utente è tenuto ad astenersi dal: (i) decifrare, decompilare, disassemblare, decodificare o tentare in altro modo di ricavare il codice sorgente o le idee o gli algoritmi sottostanti di qualsiasi parte del Sito, degli Asset e/o degli Ellen Pass; (ii) interferire con il funzionamento del Sito o aggirare qualsiasi misura tecnica utilizzata o qualsiasi altra tecnologia che possa danneggiare il Sito, gli Asset e/o gli Ellen Pass; (iii) modificare, tradurre o creare in altro modo opere derivate da qualsiasi parte del Sito, dagli Asset e/o dagli Ellen Pass.<br />
                
                <SubTitle>
                    COMUNICAZIONI
                </SubTitle>
                
                Le comunicazioni relative alle presenti CGV dovranno essere inviate ad Ellenika Utility S.r.l. ai seguenti indirizzi: Via Daniele Piccinini n. 2, Bergamo (BG), Email: info@ellenikautility.com;  PEC: ellenikautility@pec.it. <br />
                La Società contatterà l’Utente all’indirizzo email e/o altri recapiti indicati all’interno della propria area personale.<br />
                L’Utente è tenuto ad aggiornare tempestivamente i recapiti inseriti all’interno della propria area personale.<br />
                
                <SubTitle>
                    LEGGE APPLICABILE E FORO COMPETENTE
                </SubTitle>
                
                Le presenti CGV sono disciplinate esclusivamente della legge italiana.<br />
                Fatto salvo quanto previsto da disposizioni inderogabili di legge, qualsiasi contestazione e controversia tra la Società e l’Utente avente ad oggetto la fruizione del servizio di vendita e dei servizi connessi, nonché l’interpretazione e l’esecuzione delle presenti CGV sarà devoluta alla competenza esclusiva del Tribunale di Bergamo, con espressa esclusione di qualsiasi altro foro eventualmente concorrente.<br />
                Laddove l’Utente si qualifichi come “consumatore” ai sensi del Codice del Consumo di cui al Decreto Legislativo n. 206/2005, sarà competente il foro di residenza o domicilio del consumatore.<br />
                
                <SubTitle>
                    INFORMATIVA PRIVACY
                </SubTitle>
                
                Per la disciplina del trattamento dei dati personali dell’Utente si fa rinvio alle disposizioni contenute nell’Informativa Privacy pubblicata sul Sito.<br />
                
                <SubTitle>
                    RISOLUZIONE DELLE CONTROVERSIE ONLINE PER UTENTI CONSUMATORI
                </SubTitle>
                
                L’Utente Consumatore residente in Europa deve essere a conoscenza del fatto che la Commissione Europea ha istituito una piattaforma online che fornisce uno strumento di risoluzione alternativa delle controversie. Tale strumento può essere utilizzato dall’Utente Consumatore per risolvere in via non giudiziale ogni controversia relativa a e/o derivante da contratti di vendita di beni e fornitura di servizi stipulati in rete. Di conseguenza, l’Utente Consumatore può usare tale piattaforma per la risoluzione di ogni disputa nascente dal contratto stipulato online. La piattaforma è disponibile al seguente indirizzo: ec.europa.eu/consumers/odr.<br />
            </Content>            
        </Page>
    );
};

export default TermsAndConditions;
