import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    padding: 20px;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const CardContentBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
`;

export const PartnerImage = styled.img`
    
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    border-radius: 15px;
`;

export const DiscountBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 20px;
`;

export const DiscountRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: nowrap;;
    width: 100%;

    position: relative;

    img{ 
        width: 30px;
    }

    > div {

        display: flex;
        align-items: center;
        justify-content: center;

        flex-wrap: nowrap;
    }
`;

export const PremiumBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid gold;
    border-radius: 50%;
    background: black;
`;

export const GoldBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid white;
    border-radius: 50%;
    background: gold;
`;

export const PlatinumBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid black;
    border-radius: 50%;
    background: white;
`;

export const DescriptionBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    @media ${devices.tablet} {
        padding: 0 20px;
    }
`;

export const PartnerName = styled.h2`

    margin: 0;
    padding: 0;
`;

export const Description = styled.p`

    margin: 0;
    padding: 0;
`;

export const ActionButton = styled.button`

    padding: 20px;

    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;

    background: linear-gradient(0deg, #49B08D, #93D7C0);
    box-shadow: none;
    border: 2px solid green;
    border-radius: 50px;
    cursor: pointer;
`;

export const NotAvailableImage = styled.img`

    max-width: 20px;
    position: absolute;

    right: 15px;

    opacity: 0.5;
`;