import devices from 'style/breakpoints/breakpoints';
import { styled } from 'styled-components';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 40px;

    width: 100%;

    padding: 50px 0 250px;

    @media ${devices.tablet} {
        padding: 100px 0 200px;
    }
`;

export const InfoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 30px;
    flex-wrap: wrap;
    
    width: 100%;

    @media screen and (max-width: 768px) {
        
        align-items: stretch;
        flex-direction: column;
        flex-wrap: unset;

        max-width: 400px;
    }
`;

export const InfoDetail = styled.p`

    margin: 0;
    padding: 0;
`;

export const InfoData = styled.p`

    margin: 0 0 0 15px;
    padding: 0;

    font-weight: 700;
    text-transform: uppercase;
`;

export const Title = styled.h4`

    font-size: 20px;
    color: #011f71;
`;
