import { FC, useEffect } from 'react';

// routing
import { Outlet, useNavigate } from 'react-router';

// components
import BaseCard from 'components/BaseCard/BaseCard.component';

// assets
import logoPNG from 'assets/logo-ellenika.png';

// style
import {
    Page,
    Content,
    LogoBox
} from './style/Authentication.style';

const Authentication:FC<{}> = () => {

    const navigate = useNavigate();
    const authUser = localStorage.getItem('authUser');

    useEffect(() => {

        if (authUser !== null) navigate('/reservedArea');
    }, [authUser, navigate]);

    return (
        <Page>
            <Content>
                <BaseCard>
                    <LogoBox>
                        <img alt='Logo Ellenika' src={logoPNG} />
                    </LogoBox>

                    <Outlet />
                </BaseCard>
            </Content>
        </Page>
    );
};

export default Authentication;
