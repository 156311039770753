import styled from 'styled-components';
import EllenikaBackground from 'assets/background-ellenika.png'


export const Page = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    width: 100%;

    position: relative;
    overflow: hidden;

    background-color: #E0DBE9;
    background-image: url(${ EllenikaBackground });
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
    /* background: #e0dbe9; */
`;

export const PageContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    padding: 0 30px;

    overflow-y: auto;
    overflow-x: hidden;

    background-image: url({EllenikaLogo});
`;
