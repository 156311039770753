import { FC } from 'react';

// routing
import { useNavigate } from 'react-router';

//hooks
import useAppSelector from 'hooks/useAppSelector';

// utils
import { logoutUser } from 'services/firebase.services';
import { SidebarMenu } from './SidebarNavbar.helper';

// tyoes
import { MenuItemProps } from './MenuItem/MenuItem.component.d';

// assets
import home from 'assets/icons/estate-icon.png'
import myAddress from 'assets/icons/my-address-icon.png'
import partner from 'assets/icons/partner-icon.png'
import ellenPass from 'assets/icons/ellen-pass-icon.png'
import state1 from 'assets/icons/buildings-icon.webp'
import contact from 'assets/icons/contact-icon.png'

// components
import MenuItem from './MenuItem/MenuItem.component';

// style
import { 
    Content,
    LogoutItem
} from './style/SidebarNavbar.style';

const _ICONS: any = {
    home,
    myAddress,
    partner,
    ellenPass,
    state1,
    contact
};


const SidebarNavbar: FC<{}> = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const navigate = useNavigate();

    const logoutHandler = async () => {

        try {
            
            await logoutUser();
            navigate('/authentication/login');
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    return (
        <Content>
            {SidebarMenu.map((menuItem: MenuItemProps, index: number) => {
                
                const icon = menuItem.icon !== undefined ?_ICONS[menuItem.icon] : '';
                return (

                    <MenuItem 
                        key={"menuItem" + index}
                        label={menuItem.label}
                        navigateTo={menuItem.navigateTo}
                        icon={icon}
                        categoryName={menuItem.categoryName}
                    />
                )
            })}

            {isAppMobile && (
                <LogoutItem
                    onClick={logoutHandler}
                >
                    Logout
                </LogoutItem>
            )}
        </Content>
    );
};

export default SidebarNavbar;
