import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalOptions } from './slice-modal.d';


interface ModalData extends ModalOptions {

    id: string
};

interface ModalState {

    content: ModalData[]
};

// initial slice state
const initialState: ModalState = {

    content: []
};

const ModalSlice = createSlice(
    {
        name: 'modal',
        initialState,

        reducers: {

            setContent: (state, action: PayloadAction<ModalData[]>) => {
                state.content = action.payload;
            }
        }
    }
);

// slice actions mapping
export const {
    setContent
} = ModalSlice.actions;

// reducer for store
export default ModalSlice.reducer;
