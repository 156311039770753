import { FC } from 'react';

// asset
import LogoPNG from 'assets/logo-orizzontale.png';

// style
import {
    Page,
    Content,
    Title,
    SubTitle
} from './style/Privacy.style';


const Privacy: FC<{}> = () => {

    return (
        <Page>
            <Content>
                <img
                    alt='ellenika logo'
                    src={LogoPNG}
                />

                <Title>
                    Informativa sul trattamento dei dati personali
                </Title>

                Ai sensi e per gli effetti (i) del D. Lgs. 30 giugno 2003, n. 196, il “Codice Privacy”, (ii) del
                Regolamento UE 2016/679 relativo alla “protezione delle persone fisiche con riguardo al
                trattamento dei dati personali, nonché alla libera circolazione di tali dati”, il “GDPR”, art. 13,
                norme denominate congiuntamente anche “Normativa privacy”, sono previsti una serie di
                obblighi in capo a chi effettua il trattamento - “la raccolta, la registrazione, l’organizzazione, la
                strutturazione, la conservazione, l’adattamento o la modifica, l’estrazione, la consultazione,
                l’uso, la comunicazione mediante trasmissione, la diffusione o qualsiasi altra forma di messa a
                disposizione, il raffronto, l’interconnessione, la limitazione, la cancellazione o la distruzione” -
                (nel seguito il “Trattamento”) di informazioni riguardanti una persona fisica identificata o
                identificabile (l’ “Interessato). <br />
                Ellenika Utility S.r.l. con sede legale in Via Piccinini, 2 - 24122 Bergamo (la “Società”)
                desidera informarLa, nelle sezioni che seguono, in merito alle modalità ed alle finalità del
                Trattamento dei dati personali che La riguardano.
                
                <SubTitle>
                    A) Titolare del Trattamento
                </SubTitle>
                
                Il titolare del trattamento è Ellenika Utility S.r.l. (il “Titolare”) e potrà essere contattato via
                e-mail al seguente indirizzo: infoellenika@gmail.com
                
                <SubTitle>
                    B) Modalità di raccolta dei dati dell’Interessato
                </SubTitle>
                
                Il Titolare può venire in possesso dei Suoi dati nelle seguenti circostanze: <br />
                ▪ in caso di richiesta di contatto inoltrata tramite il sito internet aziendale, a mezzo e-mail
                oppure telefonicamente, al fine di richiedere informazioni sui servizi e prodotti forniti
                dalla Società; <br />
                ▪ in caso di acquisto o richiesta di un servizio erogato dalla Società, comprese le
                trattative precontrattuali; <br />
                ▪ laddove fornisca i Suoi dati per ricevere comunicazioni commerciali, newsletter e/o per
                essere aggiornato sugli eventi organizzati e sulle iniziative di marketing intraprese dalla
                Società.
                
                <SubTitle>
                    C) Categorie di dati oggetto del Trattamento
                </SubTitle>
                
                Le seguenti categorie di dati personali che La riguardano esemplificano le tipologie di dati che
                possono essere raccolti tramite i vari servizi e canali di contatto descritti nel presente
                documento: <br />
                Dati di contatto – informazioni inerenti nome, indirizzo, indirizzo e-mail. <br />
                Altri dati personali – informazioni che Lei ci fornisce in merito alla Sua data di nascita,
                documento di identità, codice fiscale, coordinate bancarie, IBAN e indirizzo wallet. <br />
                Dati degli account online – informazioni sugli account personali creati sui nostri siti
                (l’”Account”). <br />
                Dati relativi al rapporto Amministrativo/Contabile – informazioni relative al
                rapporto contrattuale/amministrativo (fatture, saldi, pagamenti in sospeso). <br />
                Utilizzo del Sito e delle Comunicazioni – informazioni relative alle modalità con cui
                utilizza il nostro sito, apre o inoltra le nostre comunicazioni, incluse le informazioni
                raccolte tramite cookie ed altre tecnologie di tracciamento (può trovare la nostra
                Informativa sui Cookie qui).

                <SubTitle>
                    D) Finalità, base giuridica e periodo di conservazione
                </SubTitle>
                
                Ai sensi della Normativa Privacy, il Trattamento di dati personali deve essere legittimato da uno
                tra i vari presupposti giuridici previsti dall’art. 6 del GDPR. Tali presupposti sono di seguito
                espressamente indicati per ciascuna finalità secondo la quale il Titolare esegue il Trattamento
                dei Suoi Dati: <br /> <br />
                a) Creazione degli Account: i Dati saranno utilizzati per consentire all’utente la creazione
                dell’Account, nonché al fine di erogare i servizi richiesti. <br />
                Il conferimento dei Dati nei form contrassegnati dall’asterisco è obbligatorio, mentre per
                gli ulteriori dati il conferimento è facoltativo. L’eventuale rifiuto a fornire i dati
                obbligatori o l’eventuale successiva mancanza di autorizzazione al loro Trattamento
                potrà determinare l’impossibilità per il Titolare di erogare i servizi. <br />
                Base giuridica del trattamento: per dare esecuzione al contratto di cui Lei è parte (art. 6
                par. 1 lett. b del GDPR). <br />
                Politica di conservazione dei Dati: i Dati saranno conservati fino alla Sua richiesta di
                cancellazione dell’Account online o disiscrizione. <br /> <br />
                b) Condivisione di informazioni relative al rapporto in essere: i Dati saranno utilizzati per
                consentirLe l’accesso alle informazioni relative al rapporto amministrativo/contabile in
                essere (fatture, saldi, pagamenti in sospeso) e/o relative ai servizi (es. identificativi del
                “Passaporto implementare” eventualmente installato) tramite l’area riservata. <br />
                Il conferimento dei Dati è obbligatorio l’eventuale rifiuto a fornirli o l’eventuale
                successiva mancanza di autorizzazione al loro Trattamento potrà determinare
                l’impossibilità del Titolare di erogare il servizio. <br />
                Base giuridica del trattamento: per dare esecuzione al contratto di cui Lei è parte (art. 6
                par. 1 lett. b del GDPR). <br />
                Politica di conservazione dei Dati: i Dati saranno conservati fino alla Sua richiesta di
                cancellazione dell’Account online o disiscrizione, e comunque per un periodo non
                superiore a quello stabilito per la conservazione della documentazione oggetto di
                condivisione. <br /> <br />
                c) Rispondere a Sue specifiche richieste: i Dati saranno utilizzati per dare riscontro a Sue
                specifiche richieste di informazione e/o servizi. <br />
                Il conferimento dei dati contrassegnati dall’asterisco è obbligatorio, mentre per gli
                ulteriori dati il conferimento è facoltativo. Ogni responsabilità in merito alla correttezza
                e/o completezza dei dati forniti è in capo all’interessato. <br />
                Base giuridica del trattamento: per dare esecuzione di misure precontrattuali (art.6
                par.1 lett. b del GDPR). <br />
                Politica di conservazione dei Dati: i Dati saranno conservati per il tempo strettamente
                necessario a perseguire le finalità per cui sono stati raccolti. I Dati fornitici nell’ambito
                di una Sua richiesta o per la mera formalizzazione di un preventivo saranno conservati
                per un tempo massimo di cinque anni. I Dati delle richieste non riscontrate i Dati
                verranno conservati non oltre novanta giorni. <br /> <br />
                d) Adempimento degli obblighi giuridicamente vincolanti: i Dati potranno essere trattati
                per adempiere ad ogni obbligo civilistico, amministrativo, fiscale, contabile, previsto
                dalla legge, da un regolamento, dalla normativa europea o da un ordine dell’Autorità e
                derivante dal/i rapporto/i con Lei in essere. <br />
                Il conferimento dei Dati è obbligatorio in quanto richiesto ai fini dell’adempimento degli
                obblighi legali e contrattuali. L’eventuale rifiuto a fornirli o l’eventuale successiva
                mancanza di autorizzazione al loro Trattamento potrà determinare l’impossibilità del
                Titolare a dar corso ai rapporti contrattuali in essere. <br />
                Base giuridica del trattamento: per dare esecuzione al rapporto di cui Lei è parte (art. 6
                par. 1 lett. b del GDPR), per adempiere ad un obbligo legale a cui è soggetto il Titolare
                (art. 6 par. 1 lett. c del GDPR). <br />
                Politica di conservazione dei Dati: i Dati potranno essere conservati per il tempo
                necessario all’assolvimento degli obblighi legali e, in ogni caso, per tutta la durata del
                contratto oltre che per successivi dieci anni decorrenti dal termine dell’anno fiscale
                seguente a quello di competenza. <br /> <br />
                e) Difesa in giudizio per i diritti del Titolare: laddove ne ricorra l’obbligo, il Titolare
                provvederà a fornire informazioni che La riguardano alle Autorità e agli organismi
                preposti all’applicazione della legge, dei regolamenti e degli atti giudiziari, nonché a
                terze parti in contenzioso. <br />
                Il Titolare si riserva di trattare i Dati per prevenire possibili rischi e frodi, nonché per
                difendere i propri diritti derivanti dal contratto in sede giudiziale o stragiudiziale, anche
                ai fini di eventuale recupero del credito, direttamente o attraverso soggetti terzi
                (agenzie/società di recupero credito) ai quali saranno solo a tal fini comunicati. <br />
                Il conferimento dei Dati è obbligatorio in quanto richiesto ai fini dell’adempimento degli
                obblighi legali e contrattuali. L’eventuale rifiuto a fornirli o l’eventuale successiva
                mancanza di autorizzazione al loro Trattamento potrà determinare l’impossibilità del
                Titolare a dar corso ai rapporti contrattuali in essere. <br />
                Base giuridica del trattamento: per il perseguimento di un legittimo interesse del
                Titolare del Trattamento consistente nel prevenire possibili frodi o nel difendere un
                proprio diritto o avanzare una qualche pretesa derivante dal rapporto commerciale con
                Lei in essere, salvo che non prevalgano i Suoi interessi o i Suoi diritti fondamentali (art.
                6 par. 1 lett. f del GDPR). <br />
                Politica di conservazione dei Dati: I dati potranno essere conservati fino a tre anni
                successivi alla cessazione della responsabilità contrattuale fra le parti. <br /> <br />
                f) Fidelizzazione della clientela e marketing: i Dati saranno utilizzati per fornirLe notizie ed
                offerte - tramite modalità automatizzate di contatto (come e-mail, sms) e/o tradizionali
                (come posta cartacea) relative ai servizi offerti dal Titolare – e/o inviti a eventi, nonché
                per la realizzazione di studi di mercato, analisi statistiche e rilevazione del grado di
                soddisfazione. Al momento della raccolta e in occasione dell'invio di ogni
                comunicazione, verrà informato della possibilità di opporsi in ogni momento al
                Trattamento, in maniera agevole e gratuitamente. <br />
                Il conferimento dei Dati è facoltativo ed il loro mancato conferimento o la mancata
                autorizzazione al Trattamento comporterà l’impossibilità di svolgere le attività ivi
                indicate. <br />
                Base giuridica del trattamento: consenso da Lei espresso in qualità di Interessato al
                Trattamento (art. 6 par. 1 lett. a del GDPR). <br />
                Politica di conservazione dei Dati: i Dati trattati per finalità di fidelizzazione della
                clientela e marketing, potranno essere conservati sino alla revoca del Suo consenso
                liberamente espresso. <br /> <br />
                g) Comunicazione dati a Partner commerciali: i Suoi dati potranno essere comunicati a
                Partner commerciali della Società affinché le stesse possano trattare tali dati per proprie
                finalità commerciali e di marketing sia tramite strumenti tecnologici, quali newsletter,
                email, sms, sia tramite strumenti tradizionali quali la posta ed il telefono.
                Il conferimento dei Dati è facoltativo ed il loro mancato conferimento o la mancata
                autorizzazione al Trattamento comporterà l’impossibilità di svolgere le attività ivi
                indicate. <br />
                Base giuridica del trattamento: consenso da Lei espresso in qualità di Interessato al
                Trattamento (art. 6 par. 1 lett. a del GDPR). <br />
                Politica di conservazione dei Dati: il periodo di conservazione è definito dal Partner
                commerciale a cui sono comunicati i Dati. <br />
                Qualora il Titolare intenda trattare i Suoi Dati per finalità diverse da quelle sopra descritte, è
                tenuto a informarLa di tali ulteriori finalità prima del compimento del Trattamento.
                
                <SubTitle>
                    E) Modalità di Trattamento dei Dati
                </SubTitle>
                
                In relazione alle finalità sopra indicate, la Società esegue il Trattamento dei Dati, nel rispetto
                delle misure di sicurezza di cui all’art. 32 del GDPR mediante strumenti manuali, informatici e
                telematici, atti a memorizzare, gestire e trasmettere i Dati medesimi, unicamente allo scopo di
                perseguire le finalità per le quali gli stessi sono stati raccolti e, comunque, in modo tale da
                garantirne la sicurezza e la riservatezza, nonché il rispetto dei principi di correttezza, liceità,
                trasparenza. <br />
                Se Le abbiamo fornito (o Lei ha scelto) una password che Le consenta l’accesso ad alcune
                sezioni del nostro sito web o di altri portali, applicazioni o servizi forniti dalla Società, Lei sarà
                responsabile di mantenere la segretezza di tale password e di rispettare ogni altra procedura di
                sicurezza di cui Le dessimo notizia. Le chiediamo di non condividere le Sue credenziali di
                accesso (ivi inclusa la Sua password) con nessuno. <br />
                Il Titolare effettua Trattamenti che consistono in processi decisionali automatizzati sui Dati
                trattati.
                
                <SubTitle>
                    F) Ambito di comunicazione dei Dati
                </SubTitle>
                
                I Suoi Dati potranno essere resi accessibili a: <br />
                ▪ dipendenti e collaboratori della Società nella loro qualità di persone autorizzate e/o
                designate al Trattamento e/o amministratori di sistema; <br />
                ▪ partners commerciali o terze parti esterne che - per conto del Titolare - svolgono
                attività in outsourcing per finalità di supporto, amministrative, contabili, fiscali o per
                scopi connessi alla gestione del rapporto di fornitura o di tutela legale; <br />
                ▪ altri soggetti terzi al fine di effettuare i servizi specificatamente richiesti. A tali soggetti
                terzi sono fornite solo le informazioni necessarie all'espletamento delle relative funzioni; <br />
                ▪ Organismi di Vigilanza, Autorità giudiziarie nonché a tutti gli Enti istituzionali ai quali la
                comunicazione sia obbligatoria per legge per l’espletamento delle finalità dette.
                
                <SubTitle>
                    G) Trasferimento dei Dati a un Paese terzo o a un’organizzazione internazionale
                </SubTitle>
                
                I Dati personali sono trattati all’interno dell’Unione Europea e conservati su server ivi ubicati.
                Resta in ogni caso inteso che il Titolare, ove si rendesse necessario, avrà facoltà di trasmettere
                tali Dati ad un Paese terzo o a un’organizzazione internazionale e/o spostare i server anche
                extra-UE. In tal caso, il Titolare assicura sin d’ora che il trasferimento dei Dati extra-UE avverrà
                in conformità alle disposizioni di legge applicabili, di cui all’art. 44 e ss. del GDPR.
                
                <SubTitle>
                    H) Diritti dell’Interessato
                </SubTitle>
                
                La Società La informa, infine, che ai sensi della normativa vigente in materia di protezione dei
                dati personali, potrà esercitare in ogni momento specifici diritti – di cui agli artt. 15-22 del
                GDPR - ed in particolare potrà chiedere al Titolare: <br /> <br />
                a) il diritto di accesso ossia la possibilità di ottenere dal Titolare la conferma che sia o meno in
                corso il Trattamento di dati personali e in tal caso, di ottenere l’accesso ai propri dati
                personali; <br /> <br />
                b) il diritto alla rettifica, compresa l’integrazione dei dati personali incompleti; <br /> <br />
                c) il diritto alla cancellazione dei dati senza ritardo su richiesta dell’Interessato e <br /> <br />
                obbligatoriamente se: <br />
                ● i dati personali non sono più necessari rispetto alle finalità del Trattamento; <br />
                ● il consenso su cui si basa il Trattamento è revocato e non sussiste altro fondamento
                giuridico per il Trattamento; <br />
                ● i dati personali sono stati trattati illecitamente; <br />
                ● i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal
                diritto dell’UE o dello Stato membro. <br />
                ● l’Interessato si opponga al Trattamento e non sussista alcun motivo legittimo prevalente
                per procedere al Trattamento, oppure quando si opponga al Trattamento nei casi
                previsti dall’art. 21, paragrafo 2, del GDPR (dati personali trattati per finalità di
                marketing diretto); <br /> <br />
                d) il diritto alla limitazione di trattamento nei casi in cui sia contestata l’esattezza dei dati
                personali (per il periodo necessario al Titolare del Trattamento per verificare l’esattezza di
                tali dati personali) ovvero il Trattamento sia illecito e/o l’Interessato si sia opposto al
                Trattamento chiedendone la limitazione; <br /> <br />
                e) il diritto alla portabilità dei dati personali quale facoltà di ricevere dal Titolare in un formato
                strutturato, di uso comune e leggibile da dispositivo automatico, i dati personali e di
                trasmettere tali dati ad altro titolare del Trattamento, solo per i casi in cui il Trattamento
                sia basato sul consenso e per i soli dati il cui Trattamento sia effettuato con mezzi
                automatizzati; <br /> <br />
                f) il diritto di opporsi al Trattamento dei propri dati personali fatto salvo il diritto del Titolare
                del Trattamento di dimostrare l’esistenza di motivi legittimi per procedere comunque al
                Trattamento; <br /> <br />
                g) il diritto di revoca del consenso in qualsiasi momento, qualora il Trattamento sia basato sul
                Suo consenso esplicito, senza che ciò pregiudichi la liceità dei trattamenti effettuati sino
                all’esercizio della revoca; <br /> <br />
                h) il diritto di proporre reclamo a un’Autorità di controllo dello Stato membro in cui risiede o
                lavora abitualmente ovvero dello Stato in cui si è verificata la presunta violazione, fatto
                salvo ogni altro ricorso amministrativo o giurisdizionale, in caso di violazioni alle
                disposizioni del citato Regolamento. <br />
                Se desidera avere maggiori informazioni sul Trattamento dei Suoi dati personali ed esercitare i
                diritti precedentemente indicati, può inviare una richiesta scritta utilizzando i contatti forniti
                nella sezione “Titolare del Trattamento” della presente informativa. In caso di richiesta da parte
                Sua di informazioni relative ai Suoi dati, il Titolare darà riscontro al più presto – salvo che ciò si
                riveli impossibile o implichi uno sforzo sproporzionato – e comunque non oltre i trenta giorni
                dall’istanza. Eventuali impossibilità o ritardi da parte del Titolare nel soddisfare le richieste
                saranno adeguatamente motivati.
            </Content>
        </Page>
    );
};

export default Privacy;
