import { FC, useState } from 'react';

// redux
import { addModal } from 'redux/actions/actions-modal';
import { ModalOptions } from 'redux/slices/slice-modal.d';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utility
import { deleteDiscountCode } from 'services/payments.services';
import { BUILDINGS_IMAGE, NEXI_URL_TEST} from './StateOneCard.helper';

// types
import { StateOneCardProps } from './StateOneCard.component.d';

// style
import {
    Card,
    OfferImg,
    OfferDetails,
    OfferTitle,
    OfferPrice,
    OfferQuantity,
    QuantityChangeButton,
    OfferButton
} from './style/StateOneCard.style'
import PurchaseModal from 'components/PurchaseModal/PurchaseModal.component';


const StateOneCard: FC<StateOneCardProps> = ({
    buildingType,
    buildingZone,
    cuttedPrice,
    discountCode,
    name,
    price,
    quantity
 }) => {

    const [choosenQuantity, setChoosenQuantity] = useState(1);
    const dispatch = useAppDispatch();

    const quantityHandler = (typeOfSetting: string) => {
        
        if (typeOfSetting === 'add') {
            if (choosenQuantity === quantity) return;
            return setChoosenQuantity(prevState => prevState + 1)
        };
        if (quantity === 1) return;
        setChoosenQuantity(prevState => prevState - 1);
    };

    const buyHandler = async (event: any) => {

        event.preventDefault();
        if (quantity === 0) return;

        const finalPrice = +cuttedPrice * choosenQuantity; 
        const finalDescription = `Nr. ${choosenQuantity} ${name}`;

        const modalContent: ModalOptions = {
            children: (
                <PurchaseModal
                    buildingType={buildingType}
                    buildingZone={buildingZone}
                    finalPrice={finalPrice}
                    finalDescription={finalDescription}
                    quantity={choosenQuantity}
                    discountCode={discountCode}
                />
            )
        }
        dispatch( addModal(modalContent) );
    };

    const getImage = () => {

        const buildingImage = BUILDINGS_IMAGE.filter((image) => image.includes(buildingType));
        return buildingImage[0];
    };

    return (
        <Card onSubmit={buyHandler} method='post'>
            <OfferImg src={getImage()} />

            <OfferDetails>
                <OfferTitle>ELLENIKA - {name}</OfferTitle>

                <OfferPrice $isCutted={cuttedPrice !== price}>€ {price}</OfferPrice>

                {(cuttedPrice !== price) && <OfferPrice>€ {cuttedPrice}</OfferPrice>}

                {/* <OfferQuantity isMinimum={quantity === 1} isMaximum={quantity === quantity}> */}
                <OfferQuantity>
                    {/* {quantity > 1 && <QuantityChangeButton onClick={quantityHandler} type='button' >-</QuantityChangeButton>} */}
                    <QuantityChangeButton onClick={() => quantityHandler('')} type='button' >-</QuantityChangeButton>
                        {choosenQuantity}
                    {/* {quantity > quantity && <QuantityChangeButton onClick={() => quantityHandler('add')} type='button' >+</QuantityChangeButton>} */}
                    <QuantityChangeButton onClick={() => quantityHandler('add')} type='button' >+</QuantityChangeButton>
                </OfferQuantity>

                <OfferButton type='submit'>acquista</OfferButton>
            </OfferDetails>
        </Card>
    );
};

export default StateOneCard;
