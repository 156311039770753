import React, { useState } from 'react';

//assets
import InfoIcon from 'assets/icons/info-icon.png';
import WalletIcon from 'assets/icons/wallet-icon.png';
import EstateIcon from 'assets/icons/estate-icon.png';
import EllenPassIcon from 'assets/icons/ellen-pass-icon.png'
import Check from 'assets/icons/check.png';
import PremiumPNG from 'assets/premium-pass.png';
import GoldPNG from 'assets/gold-pass.png';
import PlatinumPNG from 'assets/platinum-pass.png';

//style
import { css, styled } from 'styled-components';

const CARD_ICONS = [
    InfoIcon,
    WalletIcon,
    EstateIcon
];

const _ELLENPASSIMAGE = {
    Premium: PremiumPNG,
    Gold: GoldPNG,
    Platinum: PlatinumPNG
};

const Card = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    /* flex-grow: 1; */
    flex-shrink: 0;
    flex-basis: 30%;
    
    min-width: 300px;

    background: white;
    border-radius: 15px;

    /* border: 5px solid #011f71; */
    border: ${({$flavour}) => (typeof $flavour !== 'string') ? '5px solid #011f71' : 'unset'};

    ${({$flavour}) => $flavour === 'first' && (

        css`
            background: linear-gradient(90deg, #FFE4FC, #EF72E6);
            color: #011f71;
        `
    )}

    ${({$flavour}) => $flavour === 'second' && (

        css`
            background: linear-gradient(90deg, #EF72E6, #721FC6);
            color: white;
        `
    )}
    
    ${({$flavour}) => $flavour === 'third' && (

        css`
            background: linear-gradient(90deg, #721FC6, #1950E8);
            color: white;
        `
    )}

    ${({$flavour}) => $flavour === 'ellenpass' && (

        css`
            background: linear-gradient(90deg, #B829B3, #0535B8);
            color: white;
        `
    )}

    ${({$flavour}) => $flavour === 'forth' && (

        css`
            background: linear-gradient(#FFE4FC, #EF72E6);
            color: white;
        `
    )}
`;

const Content = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 10px;

    width: 100%;
    padding: 20px;

    overflow: hidden;
`;

const SideContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    align-self: stretch;
    flex-direction: column;
    gap: 10px;

    border-left: 5px solid white;

    width: 100%;
    padding: 20px;
`;

const EllenPassRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    img {

        width: 50px;
        margin: 0 0 0 20px;
    }
`;

const TopContent = styled.div``;

const IconBox = styled.div`

    img {
        width: 30px;
    }
`;

const LowContent = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    img {

        width: 30px;
        margin: 0 0 0 20px;
    }
`;

const Title = styled.h4`

    margin: 0;
    padding: 0;
`;

const Description = styled.p`
    
    margin: 0;
    padding: 0;
    white-space: nowrap;

    ${({$isWallet}) => $isWallet && (

        css`
            font-size: 15px;

            @media screen and (max-width: 768px) {

                width: 100%;
                align-self: flex-start;
                overflow-x: scroll;
            }
        `
    )}

    ${({$isEllenPass}) => $isEllenPass && (

        css`
            @media screen and (max-width: 768px) {

                white-space: normal;
            }
        `
    )}
`;

const CheckToolTip = styled.span`

    margin: 0 0 0 20px;

    font-weight: 600;
    color: green;
`;

const InfoCard = (propsData) => {

    const [isCheckToolTipVisible, setIsCheckToolTipVisible] = useState(false);

    const onCheckOver = () => setIsCheckToolTipVisible(true);
    const onCheckLeave = () => setIsCheckToolTipVisible(false);

    return (
        <Card $flavour={propsData.$flavour}>
            <Content>
                <TopContent>
                    <IconBox>
                        {(propsData.cardType === 'info') && <img alt='Card icon' src={InfoIcon} />}

                        {(propsData.cardType === 'wallet') && <img alt='Card icon' src={WalletIcon} />}

                        {(propsData.cardType === 'estate') && <img alt='Card icon' src={EstateIcon} />}

                        {(propsData.cardType === 'ellenPass') && <img alt='Card icon' src={EllenPassIcon} />}
                    </IconBox>
                </TopContent>

                <LowContent>
                    <Title>
                        {propsData.title}
                    </Title>

                    <Description 
                        $isWallet={propsData.cardType === 'wallet'}
                        $isEllenPass={propsData.cardType === 'ellenPass'}
                    >
                        {propsData.description}

                        {(propsData.cardType === 'info') && propsData.isVerified && (
                            <img 
                                alt='verified' 
                                src={Check} 
                                onMouseOver={onCheckOver}
                                onMouseLeave={onCheckLeave}
                            />
                        )}

                        {isCheckToolTipVisible && <CheckToolTip>Mail Verificata</CheckToolTip>}
                    </Description>
                </LowContent>
            </Content>

            {propsData.cardType === 'ellenPass' && 
            propsData.ellenPassOwnedType.length !== 0 && (
                <SideContent>
                    {propsData.ellenPassOwnedType.map((ellenPassType, index) => {

                        const ellenPassImage = _ELLENPASSIMAGE[ellenPassType];

                        return(
                            <EllenPassRow>
                                <Description key={"ellenPassType" + index}>
                                    {ellenPassType}

                                </Description>
                                
                                <img alt='ellenpass' src={ellenPassImage} />
                            </EllenPassRow>
                        )
                    })}
                </SideContent>
            )}
        </Card>
    );
};

export default InfoCard;
