import { FC } from 'react';

// routing
import { Outlet } from 'react-router';

// assets
import LogoPGN from 'assets/logo-ellenika.png';

// style
import { Page } from './style/PaymentsPage.style';


const PaymentsPage: FC<{}> = () => {
    
    return (
        <Page>
            <img src={LogoPGN} alt='loader' />

            <br />
            
            Loading...

            <Outlet />
        </Page>
    );
};

export default PaymentsPage;
