import { useEffect } from 'react';

// redux
import { setIsLoading } from 'redux/slices/slice-ui';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

//utils
import { checkPaymentFromUrlForBuildings } from 'services/state1.services';

//style
import { Page } from './style/State1PaymentPage.style';


const State1PaymentPage = () => {

    const dispatch = useAppDispatch();

    dispatch( setIsLoading(true) );

    const getSearchParams = (url: any, paramsToSearch: string) => { // to do

        if (url.search === undefined) return 'noSearchParams';
        if (url.search === '') return 'noSearchParams';
        
        const paramsValue = url.search.split('&').filter((search: string) => search.includes(paramsToSearch))[0].split('=')[1];
        return paramsValue;
    };

    useEffect(() => {

        const url = window.location;
        const transaction = getSearchParams(url, 'codTrans');
        const purchasedQuantity = getSearchParams(url, 'Note1'); 
        const buildingZone = getSearchParams(url, 'Note3'); 
        const buildingName = getSearchParams(url, 'Note2'); 
        const buildingPrice = getSearchParams(url, 'importo'); 

        checkPaymentFromUrlForBuildings(
            transaction,
            +purchasedQuantity,
            buildingZone,
            buildingName,
            buildingPrice
        );
    }, []);

    return (
        <Page>
            {/* <img alt='spinner' src={Logo} /> */}
        </Page>
    );
};

export default State1PaymentPage;
