import { FC, ReactNode } from 'react';

// style
import {
    BaseBackGround,
    WhiteBackGround,
    RoundedCorner,
    Content
 } from './style/BaseCard.style';


const BaseCard: FC<{children: ReactNode, maxWidth?: string}> = ({ children, maxWidth }) => {

    return (
        <BaseBackGround
            $maxWidth={maxWidth}
        >
            <WhiteBackGround>
                <Content>
                    {children}
                </Content>
            </WhiteBackGround>

            <RoundedCorner />

            <RoundedCorner $isBottom />
        </BaseBackGround>
    );
};

export default BaseCard;
