import styled from 'styled-components';
import { BaseButton } from 'style/components/BaseButton.style';


export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    padding: 20px;
`;

export const Disclaimer = styled.p``;

export const AcceptButton = styled(BaseButton)``;
