import { post } from "./axios.services";

const environment = () => {
    if (window.location.origin.includes('localhost')) return 'dev';
    if (window.location.origin.includes('test')) return 'test';
    return 'prod';
  };
  
  export const sendEmailReg = async (template: string, userMail: string) => {
  
    const urlAfterLogin = (environment() === 'prod' ? 'https://app.ellenikautility.com' : (
      (environment() === 'test') ? 'https://testapp.ellenikautility.com' : 'http://localhost.3000'
      ));
  
    try {
  
      const mailPayload = {
        service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
        template_id: template,
        user_id: process.env.REACT_APP_EMAILJS_USER_ID,
        template_params: {
          user_email: userMail
        },
      };
      
      await post('https://api.emailjs.com/api/v1.0/email/send', mailPayload);
  
      window.open(
        urlAfterLogin,
        '_self'
      )
    } catch (error) {
      console.warn("ERROR:", error);
    }
  };
  
  export const sendEmailPur = async (template: string, userMail: string, payload: any) => {
  
    try {
  
      const mailPayload = {
        service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
        template_id: template,
        user_id: process.env.REACT_APP_EMAILJS_USER_ID,
        template_params: {
          user_email: userMail,
          purchase_name: payload.purchaseName ?? '',
          purchase_quantity: payload.purchaseQuantity ?? '',
          purchase_price: payload.purchasePrice ?? ''
        },
      };
      
      const response = await post('https://api.emailjs.com/api/v1.0/email/send', mailPayload);
  
      return response;
    } catch (error) {
      console.warn("ERROR:", error);
    }
  };
  