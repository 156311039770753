import { useState, useEffect } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utility
import { userDBData } from 'services/firebase.services';
import { checkPaymentForBuildings} from 'services/state1.services';
import { checkEllenPass } from 'services/Web3/Web3.services'; 
import { checkEllenPassPendingPayment } from 'services/ellenPass.services';

// components
import InfoCard from 'components/InfoCard/InfoCard';
import OwnedPropertyCard from './OwnedPropertyCard/OwnedPropertyCard.component';

// style
import {
    Page,
    InfoBox,
    Title
} from './style/DashboardPage.style';


const DashboardPage = () => {
    
    const [userData, setUserData] = useState();
    const [owndePass, setOwnedPass] = useState([false, false, false]);
    const [areTransactionsPending, setAreTransactionsPending] = useState(true);
    const [areEllenPassTransactionsPending, setAreEllenPassTransactionsPending] = useState(true);
    const dispatch = useAppDispatch();

    const authData = JSON.parse(localStorage.getItem('authUser'));
    
    const stopCheckingPaymentsHandler = () => setAreTransactionsPending(false);
    const stopCheckingEllenPassPaymentsHandler = () => setAreEllenPassTransactionsPending(false);

    const getUserData = async () => {

        try {
            
            const response = await userDBData();
            
            if (!response || (response === undefined)) return setUserData('NO DATA');
            setUserData(response);
        } catch (error) {
            console.warn("ERROR:", error);
        }
    };

    const getOwnedPass = async () => {

        try {
            
            const premiumResponse = await checkEllenPass(userData.wallet, 'premium');
            const goldResponse = await checkEllenPass(userData.wallet, 'gold');
            const platinumResponse = await checkEllenPass(userData.wallet, 'platinum');

            setOwnedPass([
                `${premiumResponse}` !== '0',
                `${goldResponse}` !== '0',
                `${platinumResponse}` !== '0'
            ]);
        } catch (error) {
            console.warn("ERROR:", error);
        }
    };

    const checkState1Pendings = async () => {

        // const snackbarData = {};

        try {
            
            await checkPaymentForBuildings(stopCheckingPaymentsHandler);

            // snackbarData.message = 'Complimenti! Un nuovo Immobile State1 è stato aggiunto alla tua collezione!';
            // snackbarData.type = 'success';

            // dispatch( addSnackbar(snackbarData) );
        } catch (error) {
            console.warn("ERROR:", error);
        }
    };

    const checkEllenPassPendings = async () => {

        // const snackbarData = {};
        
        try {
            
            await checkEllenPassPendingPayment(stopCheckingEllenPassPaymentsHandler);
            
            // snackbarData.message = 'Complimenti! Un nuovo Ellen Pass è stato aggiunto alla tua collezione!';
            // snackbarData.type = 'success';

            // dispatch( addSnackbar(snackbarData) );

            await getOwnedPass();
        } catch (error) {
            console.warn("ERROR:", error);
            await getOwnedPass();
            stopCheckingEllenPassPaymentsHandler();
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {

        if ((userData === undefined) || !userData.wallet || (userData.wallet === undefined)) return;
        
        getOwnedPass();
    }, [userData]);

    useEffect(() => {

        if (!userData) return;
        if (userData === undefined) return;
        if (!areTransactionsPending) return;
        
        checkState1Pendings();
    }, [areTransactionsPending, userData]);

    useEffect(() => {

        // if (!userData) return;
        if (userData === undefined) return;
        if (!areEllenPassTransactionsPending) return;
        
            checkEllenPassPendings();
    }, [areEllenPassTransactionsPending, userData]);

    const ellenPassOwnedType = () => {

        let suppArr = [];

        if (owndePass[0]) suppArr.push('Premium'); 
        if (owndePass[1]) suppArr.push('Gold'); 
        if (owndePass[2]) suppArr.push('Platinum'); 

        return suppArr;
    };

    if (userData === undefined) return <></>;

    return (
        <Page>
            <InfoBox>
                <InfoCard 
                    $flavour='first'
                    title='Mail'
                    description={authData.email}
                    cardType='info'
                    isVerified={authData.emailVerified}
                />

                <InfoCard 
                    $flavour='second'
                    title='State1 UserName'
                    description={userData.stateOneUserName ?? "Username STATE1 non inserito"}
                    cardType='estate'
                />

                <InfoCard 
                    $flavour='third'
                    title='Wallet'
                    description={userData.wallet ?? "Wallet non inserito"}
                    cardType='wallet'
                />

                <InfoCard 
                    $flavour='ellenpass'
                    title='Ellen Pass'
                    description={ellenPassOwnedType().length !== 0 ? "Ellen Pass posseduti:" : "Nessun Ellen Pass posseduto!"}
                    cardType='ellenPass'
                    ellenPassOwnedType={ellenPassOwnedType()}
                />
            </InfoBox>

            {userData.stateOneBuildings && 
            <Title>
                Proprietà State1 possedute:
            </Title>}

            {(userData.stateOneBuildings !== undefined) && Object.keys(userData.stateOneBuildings).map((building, index) => {

                if (userData.stateOneBuildings[building].quantity === 0) return <></>;

                return (
                    <OwnedPropertyCard
                        key={index}
                        zone={userData.stateOneBuildings[building].zone}
                        name={userData.stateOneBuildings[building].buildingType}
                        quantity={userData.stateOneBuildings[building].quantity}
                    />
                )
            })}
        </Page>
    );
};

export default DashboardPage;
