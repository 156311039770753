import { FC, useEffect, useRef, useState } from 'react';

// utils
import { 
    stateOneDBData, 
    updateDBUserData,
} from 'services/state1.services';
import { ELLENIKA_STATE1_ZONE } from './StateOnePage.helper';
import { discountCodesDBData } from 'services/payments.services';
import { userDBData } from 'services/firebase.services';

// assets
import TelegramLogo from './assets/telegram-logo.webp';
import BannerDesktopIMG from './assets/banner-desktop.png';
import BannerMobileIMG from './assets/banner-mobile.png';

// components
import StateOneCard from './StateOneCard/StateOneCard.component';

// style
import {
    Page,
    BannerImgDesktop,
    BannerImgMobile,
    StateOneOfferBox,
    OfferBox,
    EllenikaStateOneExplanation,
    ZoneTitle,
    ZoneChoiceButton,
    ZoneBox,
    Zone,
    DownloadRow,
    PDFBox,
    StateOneDetailRow,
    InputBox,
    InputSaveButton,
    BuildingsBox,
    SocialBox,
    FooterDisclaimer
} from './style/StateOnePage.style'

  
const StateOnePage: FC<{}> = () => {

    const [buildings, setBuildings] = useState<any>(); // to do
    // const [mallBuildings, setMallBuildings] = useState();
    const [userData, setUserData] = useState<any>(); // to do
    const [userSavedName, setUserSavedName] = useState<string | undefined>(undefined);
    const [isUserNameChangeable, setIsUserNameChangeable] = useState<boolean>(false);
    const [discountCodes, setDiscountCodes] = useState<any>(undefined); // to do
    // const [chosenDiscountCode, setChosenDiscountCode] = useState(undefined);
    const [discountCodeResponse, setDiscountCodeResponse] = useState<any>(undefined); // to do
    const [ellenikaZone, setEllenikaZone] = useState<string>('Scegli una Zona');
    const [isZoneBoxVisible, setIsZoneBoxVisible] = useState<boolean>(false);
    const stateOneUserRef = useRef<HTMLInputElement>(null);
    const stateOneChangeUserRef = useRef<HTMLInputElement>(null);
    const stateOneDiscountCodeRef = useRef<HTMLInputElement>(null);
    // const stateOneZoneRef = useRef(null);

    const openTelegramHandler = () => {
        window.open(
            "https://t.me/ellenikautility",
            "_blank",
            "noopener noreferrer"
        )
    };

    const onModifyUserNameHandler = () => setIsUserNameChangeable(prevState => !prevState);
    const abortStateOneUserNameClickHandler = () => setIsUserNameChangeable(false);
    const onZoneButtonClickHandler = () => setIsZoneBoxVisible(prevState => !prevState);
    const onZoneSelectClickHandler = (zone: string) => {

        onZoneButtonClickHandler();
        setEllenikaZone(zone)
    };

    const saveStateOneUserNameClickHandler = async () => {

        const valueToSave = stateOneUserRef.current !== null ? 
            stateOneUserRef.current.value : (
                stateOneChangeUserRef.current !== null ? 
                stateOneChangeUserRef.current.value : 
                ''
            );

        const payload = {
            stateOneUserName: valueToSave
        }

        try {
            
            await updateDBUserData(payload, ''); // to do check path

            await getUserData();
            setUserSavedName(valueToSave);
            setIsUserNameChangeable(false);
        } catch (error) {
            console.warn("ERROR", error);
        }
    };

    const saveStateOneDiscountCodeClickHandler = async () => {

        if (stateOneDiscountCodeRef.current === null) return;

        const userCode = stateOneDiscountCodeRef.current.value;
        // const payload = {
        //     stateOneDiscountCode: userCode
        // }

        if (userCode === 'ellenika10-210683') return setDiscountCodeResponse('accettato10');
        if (userCode === 'ellenika20-270894') return setDiscountCodeResponse('accettato20');
        if (userCode === 'ellenika40-110693') return setDiscountCodeResponse('accettato40');

        if (discountCodes.filter((code: number) => code === +userCode).length === 0) return setDiscountCodeResponse("errato");
        setDiscountCodeResponse('accettato');
    };

    const getStateOneData = async () => {

        try {
            
            const response = await stateOneDBData();

            setBuildings(response);
        } catch (error) {
            console.warn("ERROR:", error)
        }
    };

    const getDiscountCodes = async () => {

        try {
            
            const response = await discountCodesDBData();

            setDiscountCodes(response);
        } catch (error) {
            console.warn("ERROR:", error);
        }
    }
    
    const getUserData =  async () => {

        const response = await userDBData();
        setUserData(response);
    };

    useEffect(() => { // to do reactivate DB calls

        getUserData();
        getStateOneData();
        getDiscountCodes();
    }, []);
    
    const userHasStateOneUserName = ((userData !== undefined) && (userData.stateOneUserName !== undefined) && (userData.stateOneUserNAme !== '')) ?? false;
    const discountCode = (stateOneDiscountCodeRef.current !== null) ? stateOneDiscountCodeRef.current.value : null;

    return (
        <Page>
            <BannerImgDesktop alt='banner' src={BannerDesktopIMG} />

            <BannerImgMobile alt='banner' src={BannerMobileIMG} />

            {(!userHasStateOneUserName && !userSavedName) && (
                <>
                    <StateOneOfferBox>
                        <strong>IMPORTANTE:</strong> inserisci lo username con il quale sei registrato nel sito www.state1.io.
                        Questo ci serve per accreditarti la tua proprietà direttamente nel tuo profilo personale di State 1 metaverse.
                        Non ti sei ancora registrato in State 1? Registrati subito da qui: 
                        {' '}
                        <a href='https://www.state1.io/it/signup?ref=g5vQ4MMbY46snbh' target='_blank' rel='noreferrer noopener'>
                            https://www.state1.io/it/signup
                        </a>
                    </StateOneOfferBox>

                    <StateOneDetailRow>
                        Il mio username in state1

                        <InputBox 
                            ref={stateOneUserRef}
                        />

                        <InputSaveButton onClick={saveStateOneUserNameClickHandler}>Salva</InputSaveButton>

                        {/* <SaveIcon /> */}
                    </StateOneDetailRow>
                </>
            )}
            
            {(userHasStateOneUserName || userSavedName) && (
                <>
                    {!isUserNameChangeable && (
                        <StateOneDetailRow>
                            Il mio username in state1 <span>{ userSavedName ?? userData?.stateOneUserName }</span>
                            
                            {"   "}

                            <InputSaveButton onClick={onModifyUserNameHandler}>Modifica</InputSaveButton>
                        </StateOneDetailRow>
                    )}


                    {isUserNameChangeable && (
                        <StateOneDetailRow>
                            Inserisci il tuo nuovo nome di State1 

                            <InputBox 
                                ref={stateOneChangeUserRef}
                            />

                            <InputSaveButton onClick={saveStateOneUserNameClickHandler}>Salva</InputSaveButton>
                            
                            <InputSaveButton onClick={abortStateOneUserNameClickHandler}>Annulla</InputSaveButton>
                        </StateOneDetailRow>
                    )}
                </>
            )}
            
            <OfferBox>                
                <ZoneTitle>
                    Ellenika zone - state 1
                </ZoneTitle>

                <EllenikaStateOneExplanation>
                    Ellenika zone è la nostra zona esclusiva di 8.000.000 mq all’interno del metaverso di State1. 
                    Questa zona è stata acquisita per offrire un servizio a 360° ai nostri utenti e ai nostri 
                    partner in modo da conciliare il tutto in un’unica soluzione. Accedi al metaverso per 
                    sviluppare i tuoi progetti più ambiziosi. Potrai usufruire di molteplici vantaggi acquistando 
                    il tuo immobile all’interno di Ellenika zone.
                </EllenikaStateOneExplanation>

                <DownloadRow>
                    Scarica i file PDF con le informazioni:

                    <PDFBox>
                        <a
                            href='https://ellenikautility.com/docs/tabella_omaggi_acquisto_immobili.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                            download='Tabella Omaggi'
                        >
                            Tabella Omaggi
                        </a>

                        <a
                            href='https://ellenikautility.com/docs/EllenikaZone.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                            download='Ellenika Zone'
                        >
                            Ellenika zone
                        </a>

                        <a
                            href='https://ellenikautility.com/docs/state1-guide-ita.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                            download='State1'
                        >
                            state 1
                        </a>
                    </PDFBox>
                </DownloadRow>

                <StateOneDetailRow>
                    Inserisci QUI il tuo codice sconto
                    <InputBox 
                        ref={stateOneDiscountCodeRef}
                    />

                    <InputSaveButton onClick={saveStateOneDiscountCodeClickHandler}>Applica codice sconto</InputSaveButton>

                    {(discountCodeResponse === 'accettato' || 'accettato10' || 'accettato20' || 'accettato40') && (discountCodeResponse !== 'errato') && (discountCodeResponse !== undefined) && `Codice Accettato!`}

                    {(discountCodeResponse === 'errato') && 'Il codice inserito NON è valido...'}
                </StateOneDetailRow>

                <ZoneChoiceButton onClick={onZoneButtonClickHandler}>
                    {(ellenikaZone === 'Scegli una Zona') && ellenikaZone}

                    {(ellenikaZone !== 'Scegli una Zona') && `Zona Scelta: ${ellenikaZone.replace(/([a-z])([A-Z])/g, '$1 $2')}`}
                </ZoneChoiceButton>

                {isZoneBoxVisible && (
                    <ZoneBox>
                        {ELLENIKA_STATE1_ZONE.map((zone, i) =>  <Zone key={"S1Z" + i} onClick={() => onZoneSelectClickHandler(zone)}>{zone.replace(/([a-z])([A-Z])/g, '$1 $2')}</Zone>)}
                    </ZoneBox>
                )}

                {(ellenikaZone !== 'Scegli una Zona') && (
                    <>
                        <ZoneTitle>
                            Ellenika zone - {ellenikaZone.replace(/([a-z])([A-Z])/g, '$1 $2')}
                        </ZoneTitle>

                        <BuildingsBox>
                            {(buildings !== undefined) && Object.keys(buildings[ellenikaZone]).map((building, index) => {

                                if (buildings[ellenikaZone][building].quantity === 0) return <div key={"S1C" + index}></div>;
                                if (buildings[ellenikaZone][building].price === 0) return <div key={"S1C" + index}></div>;
                                
                                let finalPrice = buildings[ellenikaZone][building].price;
                                if (discountCodeResponse === 'accettato10') finalPrice = buildings[ellenikaZone][building].price * 0.9;
                                if (discountCodeResponse === 'accettato20') finalPrice = buildings[ellenikaZone][building].price * 0.8;
                                if (discountCodeResponse === 'accettato40') finalPrice = buildings[ellenikaZone][building].price * 0.6;
                                if (discountCodeResponse === 'accettato') finalPrice = buildings[ellenikaZone][building].price * 0.5;

                                if ((buildings[ellenikaZone][building].price === 0) || (buildings[ellenikaZone][building].quantity === 0)) return <></>;

                                return (
                                    <StateOneCard
                                        key={"S1C" + index}
                                        name={buildings[ellenikaZone][building].name}
                                        price={buildings[ellenikaZone][building].price}
                                        cuttedPrice={finalPrice}
                                        quantity={buildings[ellenikaZone][building].quantity}
                                        buildingType={building}
                                        discountCode={discountCode}
                                        buildingZone={ellenikaZone}
                                    />
                                )
                            })}
                        </BuildingsBox>
                    </>
                )}

                {/* <ZoneTitle>
                    Ellenika zone - mezza luna shopping center
                </ZoneTitle>

                {(mallBuildings !== undefined) && Object.keys(mallBuildings).map((building, index) => {

                    if (mallBuildings[building].quantity === 0) return;
                    
                    return (
                        <StateOneCard
                            key={index}
                            name={mallBuildings[building].name}
                            price={(discountCodeResponse === 'accettato') ? (+mallBuildings[building].price * 0.5) : mallBuildings[building].price}
                            quantity={mallBuildings[building].quantity}
                            buildingType={building}
                            discountCode={stateOneDiscountCodeRef.current.value}
                            buildingZone='mall'
                        />
                    )
                })} */}
            </OfferBox>

            <StateOneDetailRow>
                Resta in contatto con noi per le novità di State1 su Telegram

                <SocialBox onClick={openTelegramHandler}>
                    <img alt='telegram' src={TelegramLogo} />
                </SocialBox>
            </StateOneDetailRow>

            <FooterDisclaimer>
                Per pagamenti per mezzo bonifico si prega di 
                contattare {" "} <a href='mailto: info@ellenikautility.com'>info@ellenikautility.com</a>
            </FooterDisclaimer>

            <FooterDisclaimer>
                N.B Solo una volta che sarà reso disponibile accredito sul c.c di Ellenika Utility, 
                verrà effettuata la procedura di aggiunta manuale del relativo asset.
            </FooterDisclaimer>

            <FooterDisclaimer>
                Per richiedere fattura dell&#39;acquisto effettuato inviare una mail 
                a <a href='mailto: info@ellenikautility.com'>info@ellenikautility.com</a>
            </FooterDisclaimer>
        </Page>
    );
};

export default StateOnePage;