import { styled } from 'styled-components';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    padding: 10px 20px;
`;

export const BannerImgDesktop = styled.img`

    width: 100%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const BannerImgMobile = styled.img`

    width: 100%;

    
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const StateOneOfferBox = styled.div`

    display: block;

    width: 100%;

    font-size: 20px;
`;

export const OfferBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;

    margin: 50px 0 0;
    padding: 10px;

    font-size: 20px;
    font-weight: 700;
    `;

export const EllenikaStateOneExplanation = styled.p`

    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 500;
`;

export const ZoneChoiceButton = styled.button`

    align-self: center;

    width: 100%;
    max-width: 500px;

    margin: 0;
    padding: 10px 20px;

    border: 5px solid #011f71;
    border-radius: 5px;
    color: #011f71;
    background: white;

    text-transform: uppercase;
    font-size: 20px;

`;

export const ZoneBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    align-self: center;

    width: 100%;
    max-width: 500px;

    > div:first-child {

        border-radius: 5px 5px 0 0;
    }
    
    > div:last-child {

        border-bottom: 1px solid #011f71;
        border-radius: 0 0 5px 5px;
    }
`;

export const Zone = styled.div`

    width: 100%;
    padding: 10px;

    border: 1px solid #011f71;
    border-bottom: none;
    cursor: pointer;
`;

export const ZoneTitle = styled.h3`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-grow: 1;

    margin: 20px 0 0 20px;

    font-size: 30px;
    text-transform: uppercase;
`;

export const DownloadRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-grow: 1;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        
        flex-direction: column;
    }
`;

export const PDFBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: row;
    gap: 20px;

    a {
        margin: 0;
        padding: 10px 20px;

        border: 5px solid #df03e8;
        border-radius: 50px;
        color: #011f71;
        background: white;

        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
    }

    @media screen and (max-width: 768px) {
        
        flex-direction: column;
        margin: 20px 0 0;
    }
`;

export const StateOneDetailRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 30px 0 0;

    font-size: 20px;
    text-transform: uppercase;

    span {

        margin: 0 10px;
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        
        flex-direction: column;
        gap: 20px;
    }
`;

export const InputBox = styled.input`

    margin: 0 0 0 20px;
    
    width: 300px;

    font-size: 20px;

    border: 1px solid #011f71;
    border-radius: 5px 0 0 5px;

    @media screen and (max-width: 768px) {
        
        border-radius: 5px;
    }
`;

export const InputSaveButton = styled.button`

    margin: 0 10px 0 0;
    padding: 1px 20px;

    background: #011f71;

    font-size: 20px;
    font-weight: 600;
    color: white;

    border: 1px solid #011f71;
    border-radius: 0 5px 5px 0;

    white-space: nowrap;

    @media screen and (max-width: 768px) {
        
        margin: 0 10px 0 0;
        border-radius: 5px;
    }
`;

export const BuildingsBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    flex-wrap: wrap;
    gap: 20px;

    width: 100%;
    
    @media screen and (min-width: 768) {
        align-items: center;
        justify-content: center;
    }
`;

export const SocialBox = styled.div`

    width: 50px;
    height: 50px;
    
    margin: 0 0 0 20px;

    border-radius: 5px;
    background: #229ED9;
    cursor: pointer;

    img {

        width: 50px;
    }
`;

export const FooterDisclaimer = styled.div`

    font-size: 15px;
    font-style: italic;
`;