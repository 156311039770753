import { PartnerCardProps } from "./PartnerCard/PartnerCard.component.d";


export const _PARTNERS: PartnerCardProps[] = [
    {
        name: '88 Vini',
        image: '88vini',
        typeOfDiscount: {
            premium: 5,
            gold: 10,
            platinum: 15
        },
        isToPromote: false,
        category: 'Wine',
        description: ("88Vini Wine Shop è una nuova enoteca a Castiglione delle Stiviere, Mantova. La holding offre una ampia selezione di vini italiani di massima qualità, inclusi Sauvignon Blanc, Lugana, Amarone, Barolo, Brunello, Fiano, vini siciliani e sardi. Oltre ai nomi citati, si trovano anche vini internazionali, Champagne, spirits e prodotti gourmet. 88 vini dispone di un E-Commerce di alto livello e sta sviluppando un importante progetto Experience, con molteplici Wine Club che offrono promozioni esclusive ed eventi dedicati ai clienti, curati nel minimo dettaglio. I clienti potranno partecipare a degustazioni guidate con i produttori,verticali di annate storiche, degustazioni a tema, nuove uscite di vini in anteprima, masterclass con wine educator, cene in abbinamento con chef selezionati e molto altro. Con questa tipologia di servizio, per gli amanti del buon vino, 88 Vini wine shop è una garanzia di soddisfazione. Il supporto online dell'azienda è sempre attivo e disponibile")
    },
    {
        name: 'Allevamento NewStarWood',
        image: 'newstarwood',
        typeOfDiscount: {
            premium: 2,
            gold: 5,
            platinum: 8
        },
        isToPromote: true,
        category: 'Pet',
        description: "L'Allevamento NewStarWood, situato in Friuli, è il punto di riferimento per l'acquisto di cuccioli pregiati di Shiba Inu e Akita Americano in Italia. Oltre alla vendita di cuccioli di alta qualità con pedigree, NewStarWood offre anche servizi di toelettatura e pensione per cani. <br/><br/> Nel servizio di pensione per cani viene offre anche : <br/> - Box confortevoli con zona notte riscaldata in Inverno e climatizzata in estate. <br/> - Abbeveratoi a riempimento automatico per l’acqua. <br/> - Servizio di Webcam per vedere sempre il tuo amico a quattro zampe. <br/> - Diete personalizzate <br/> - Area svago e giochi di 3000 metri quadrati con servizio di custodia 24 H. <br/><br/> Tutto ciò permette di fornire la massima tranquillità e il benessere dei nostri amici a quattro zampe. <br/><br/> In un settore in continua crescita come quello degli animali domestici, la partnership con NewStarWood è di fondamentale importanza per gli utenti Ellenika. Grazie alla loro reputazione e esperienza nel campo, NewStarWood offre prodotti e servizi di alta qualità per soddisfare le sempre crescenti esigenze dei proprietari di animali domestici in tutta Italia.",
        minPurchase: 2000
    },
    {
        name: 'Anima Architettura',
        image: 'anima-architettura',
        typeOfDiscount: {
            premium: 15,
            gold: 25,
            platinum: 35
        },
        isToPromote: true,
        category: 'Edilizia',
        description: "Lo studio di architetti Anima Architettura situato nel cuore di Brescia, è un laboratorio creativo che fonde interior design, architettura, strategia sul posto di lavoro e branding. Con un team giovane e dinamico, l'approccio innovativo si esprime attraverso l'uso audace dei materiali e la creazione di spazi funzionali, riflettendo le esigenze dei clienti. La passione per il design d'interni e la struttura organizzativa efficiente permettono a Anima Creativa di tradurre concetti in progetti distintivi, rispettando le scadenze con precisione."
    },
    {
        name: 'Az. Agr. Al Berlinghetto',
        image: 'berlinghetto',
        typeOfDiscount: {
            premium: 3,
            gold: 6,
            platinum: 10
        },
        isToPromote: false,
        category: 'Food & Beverage',
        description: "Al Berlinghetto è un'azienda agricola specializzata nella produzione di salumi e affettati di alta qualità, situata nel Nord Italia. Rinomata per la sua produzione artigianale e la selezione accurata degli ingredienti, Al Berlinghetto offre sapori autentici che rappresentano la tradizione culinaria del territorio. L'azienda ha ottenuto altissime recensioni e attestazioni di eccellenza da parte di esperti del settore gastronomico nazionale. Inoltre, fornisce i propri prodotti a numerosi ristoranti di alto livello, contribuendo a elevarne il livello. Scegliere Al Berlinghetto significa assaporare il meglio della tradizione e della produzione artigianale, con spedizioni direttamente a casa tua, in qualsiasi regione d'Italia. Scopri questo viaggio gastronomico e assapora il gusto autentico dell'azienda agricola Al Berlinghetto."
    },
    {
        name: 'Blaze Rec',
        image: 'blaze',
        typeOfDiscount: {
            premium: 5,
            gold: 10,
            platinum: 15
        },
        isToPromote: true,
        category: 'Video Making',
        description: "BlazeRec è un affermato libero professionista nel settore del videomaking, con una solida esperienza pluriennale. Specializzato in produzioni video e fotografiche di alta qualità, BlazeRec si distingue per la sua capacità di catturare l'essenza di piccole e medie imprese e liberi professionisti. La sua creatività e maestria nella regia, insieme a una solida competenza nell'editing, portano a risultati visivi coinvolgenti e professionali. Con una profonda comprensione delle esigenze del mercato, BlazeRec offre soluzioni visive su misura che lasciano un'impronta duratura."
    },
    {
        name: 'Crypto Ciudad',
        image: 'crypto-ciudad',
        typeOfDiscount: {
            premium: 10,
            gold: 10,
            platinum: 15
        },
        isToPromote: false,
        category: 'E-commerce',
        description: "Acquista computer, televisori, smartphone e accessori con criptovalute e goditi sconti esclusivi! Esplora categorie come fitness, sport, viaggi, giocattoli, abbigliamento, e immergiti nel lusso di orologi, profumi, occhiali da sole e tanto altro! CryptoCiudad rende il tuo shopping con criptovalute facile e conveniente, offrendoti l'opportunità di risparmiare in grande stile. Scopri il futuro dello shopping, con ELLEN PASS e CryptoCiudad! <br/> <br/> 40.000 e + soluzioni offerte!!! Crypto Ciudad vi permette di acquistare in ben 15 Coin differenti tra cui anche Theter <br/> <br/> <ul><li>compreso al codice promozionale sarà erogato anche un coupon da 10 o 15 tether da poter applicare come sconto ulteriore</li></ul>"
    },
    {
        name: 'Crypto Fisco',
        image: 'crypto-fisco',
        typeOfDiscount: { },
        isToPromote: true,
        category: 'Fiscalità',
        description: "Siamo estremamente lieti di annunciare che Andrea Russo, una delle autorità di spicco in Italia nel campo della fiscalità legata alle criptovalute, sarà a disposizione degli utenti di Ellenika per offrire la sua competenza in un argomento altamente complesso come la crypto fiscalità. Con la sua vasta esperienza e la qualifica di Dottore Commercialista, Andrea rappresenta un'opportunità imperdibile per ottenere una consulenza altamente professionale. La sua presenza potrà consentire una gestione fiscale chiara e conforme nell'ambito delle criptovalute, supportando la sicurezza finanziaria degli utenti di Ellenika Utility. Andrea Russo è un membro stimato della 'Commissione Fintech Criptovalute NFT e Metaverso' dell'Ordine dei Dottori Commercialisti di Bologna. Investitore appassionato e esperto di blockchain e cripto-attività, offre consulenza e servizi di dichiarazioni fiscali specializzate in questo settore. Con una laurea magistrale in Economia e Professione dall'Università degli Studi di Bologna e una qualifica di Dottore Commercialista, Andrea è un professionista autorevole nel campo della consulenza fiscale per criptovalute e affini, nonché nel contenzioso tributario, crisi e risanamento d'impresa, e consulenza fiscale e societaria. La sua iscrizione all'Albo Dottori Commercialisti e degli Esperti Contabili di Bologna risale al 2018, con il numero di iscrizione 2975/A."
    },
    {
        name: 'Crypto Shields',
        image: 'crypto-shields',
        typeOfDiscount: {
            premium: 5,
            gold: 15,
            platinum: 20
        },
        isToPromote: true,
        category: 'Studio Legale',
        description: "CryptoShields è una società leader nel settore della consulenza per crypto-asset. Con un team di esperti con competenze legali e finanziarie, lo studio offre una gamma completa di servizi per proteggere e far crescere i progetti crypto. Questi servizi includono l'assistenza legale, la due diligence legale, l'assistenza strategica nella definizione della roadmap e del modello di business, nonché la progettazione di tokenomics e incentivi. CryptoShields supporta anche la costituzione di entità giuridiche e l'implementazione di procedure KYC/AML, offrendo un supporto completo per la raccolta di fondi e la conformità normativa. Scegli CryptoShields per navigare con sicurezza nel mondo dei crypto-asset e proteggere il tuo progetto da rischi legali e frodi."
    },
    {
        name: 'Dancan',
        image: 'dancan',
        typeOfDiscount: {
            premium: 10,
            gold: 15,
            platinum: 20
        },
        isToPromote: true,
        category: 'Formazione Aziendale',
        description: "DANCAN Srl è un'azienda che offre un insieme di competenze professionali messe a disposizione di organizzazioni aziendali e micro imprese con il fine di promuovere lo sviluppo e l'ottimizzazione in molteplici aree di intervento, il Metaverso e l'Intelligenza Artificiale sono nostri servizi in grande sviluppo e consentono ai clienti e fornitori di creare opportunità incredibili nel nuovo mondo virtuale, a luglio 2023 è stata aperta l'esclusiva area di 1.200.000mq dal nome “Paradise Valley”, un mondo virtuale con le opportunità reali di avere una seconda opportunità per il business, il relax, lo sport e i giochi, entrare oggi significa essere i primi a posizionarsi con prezzi bassi e grandi scelte di location. DESCRIZIONE DANCAN CARD: Le fidelity card hanno come obiettivo quello di “agevolare il commercio” di beni e servizi tramite scontistiche riservate dai “FORNITORI PARTNER” ai “CLIENTI” possessori delle DANCAN CARD."
    },
    {
        name: 'Evoluzione Imprese',
        image: 'evoluzione-imprese',
        typeOfDiscount: {
            premium: 10,
            gold: 20,
            platinum: 30
        },
        isToPromote: false,
        category: 'Consulenza Aziende',
        description: "Evoluzione Imprese, è una giovane azienda all'avanguardia che si dedica con passione e lungimiranza a guidare altre aziende e imprenditori lungo il cammino dell'evoluzione. La loro visione innovativa, basata su un ibrido tra vendita 4.0 e gestione aziendale, li posiziona come figure chiave in  questo campo. Un elemento distintivo di Evoluzione Imprese è il loro ruolo di general contract di servizi, una formula unica nel suo genere. Con una prospettiva pionieristica, si sono affermati come i primi a introdurre questa soluzione nel mercato. Il punto di forza principale di Evoluzione Imprese risiede nelle competenze verticali dei loro partner. Queste competenze sono mobilizzate in modo strategico per affrontare con successo le sfide aziendali, garantendo soluzioni efficaci e su misura. In conclusione, Evoluzione Imprese rappresenta un affidabile alleato per le aziende e per gli imprenditori in cerca di innovazione e crescita. Il loro approccio visionario, le competenze verticali e i legami con società di rilievo li pongono in una posizione privilegiata per accompagnarvi nelle sfide del mercato contemporaneo."
    },
    {
        name: 'GinAro',
        image: 'ginaro',
        typeOfDiscount: {
            premium: 3,
            gold: 5,
            platinum: 10
        },
        isToPromote: false,
        category: 'Food & Beverage',
        description: "Il GinAro è un produttore di gin artigianale nato dalla passione di Aro (Luca Scaroni) per i liquori. Dopo aver lavorato come barman, ha scoperto il fascino dei liquori fatti in casa durante il suo soggiorno in Australia e successivamente si è appassionato alla produzione di gin artigianali durante un viaggio in Asia. Nel 2018, Aro ha deciso di dedicarsi seriamente alla produzione di un proprio gin, ispirato dal potenziale del Gin Tonic. Ha quindi ideato e creato due fantastici e apprezzatissimi gin, il GinAro classico e il GinAro blu, entrambi dal sapore euroasiatico, utilizzando botaniche provenienti da diverse parti del mondo. Inoltre, ha sviluppato una sua ricetta di acqua tonica neutra per abbinarla a diversi tipi di gin. Oltre alla produzione di gin, GinAro offre confezioni regalo speciali che includono una bottiglia di gin, la tonica di Aro, spezie e tanto altro. Il tutto in un packaging originale e di altissima qualità, perfetto per regali o bomboniere. Il GinAro classico è stato il primo prodotto di Aro ed è vincitore della medaglia d’argento allo Spirits Selection di Bruxelles 2020. Il GinAro Blu invece è uno dei Gin più apprezzati e venduti d’Italia per la sua particolarità di mutare colorazione a contatto con l’acqua tonica."
    },
    {
        name: 'Glaux Security Solutions',
        image: 'glaux',
        typeOfDiscount: {
            premium: 10,
            gold: 15,
            platinum: 20
        },
        isToPromote: true,
        category: 'Security Travel',
        description: "La Glaux Security Solutions è una società con sede a Roma che si concentra su security risk assessment & management e travel assistance. Fondata da due ex professionisti militari con esperienza operativa e accademica rilevante, Glaux ha creato un team di analisti internazionali con diverse prospettive metodologiche e formazione interdisciplinare. L'obiettivo principale è coinvolgere risorse giovani e qualificate nel settore della sicurezza e del risk assessment, con un focus sui servizi di travel security. Il team di Glaux ha una vasta preparazione accademica, esperienza in agenzie di sicurezza, risk management, formazione e unità di crisi, sia a livello nazionale che internazionale. Questa competenza permette loro di interpretare scenari di crisi, identificare criticità e proporre contromisure dinamiche. La società si distingue per un'analisi ampia delle dinamiche internazionali, considerando aspetti culturali, storici, religiosi e sociali nelle loro valutazioni. Concentrandosi su questioni come sicurezza cibernetica, terrorismo, cambiamenti climatici e rischi per la salute, Glaux offre analisi approfondite e professionale ai clienti. La missione di Glaux è diventare un punto di riferimento nel risk management a livello nazionale e internazionale. Utilizzando Open Source Intelligence (OSINT) e Open Source Information (OSINF), intendono fornire conoscenze dettagliate sulle dinamiche locali e internazionali. L'obiettivo principale è garantire la sicurezza dei clienti, delle loro famiglie, dipendenti e attività imprenditoriali. Inoltre, credono che la sicurezza sia un diritto universale, estendendo la protezione anche alle piccole e medie imprese.",
        minPurchase: 1000
    },
    // {
    //     name: 'ImportAuto',
    //     image: 'importauto',
    //     typeOfDiscount: {},
    //     isToPromote: true,
    //     category: 'Concessionarie',
    //     description: ""
    // },
    {
        name: 'Intermediazione Postale BTC',
        image: 'intermediazione-postale-btc',
        typeOfDiscount: { },
        isToPromote: true,
        category: 'Privacy Payment',
        description: "Siamo super entusiasti di presentare questa partnership di grande rilevanza per tutto il nostro ecosistema. Grazie alla collaborazione con Intermediazione postale Bitcoin tutti i nostri utenti avranno la possibilità di poter acquistare i molteplici servizi offerti dalle nostre numerose aziende/attività partner pagando direttamente in Bitcoin. Grazie a questa soluzione Ellenika offre un servizio di pagamenti completo per qualsiasi tipo di esigenza, soprattutto a tutela della privacy del cliente. Potrete usufruire dei nostri benefit e realizzare tutti i vostri progetti personali in totale sicurezza e anonimato bypassando il pagamento in valuta fiat. Ci stiamo predisponendo per alimentare un grande ecosistema. Il nostro Ellen pass sarà la chiave che sbloccherà tutto questo… Intermediazione Postale BTC fornisce i migliori servizi di spedizioni e acquisti Privacy Oriented in tutta Italia ed Europa, pagabili esclusivamente in Bitcoin. Affidandoti a Int. Postale BTC avrai la garanzia di rimanere in privacy a prezzi molto competitivi. Devi spedire o acquistare su E-commerce? ora basta solo una mail…"
    },
    {
        name: 'Tenute La Montina',
        image: 'la-montina',
        typeOfDiscount: {
            premium: 5,
            gold: 10,
            platinum: 15
        },
        category: 'Wine',
        isToPromote: false,
        description: "Le Tenute La Montina rappresentano l'epitome dell'eccellenza vinicola in Franciacorta e sono universalmente riconosciute come una delle cantine più iconiche d'Italia. Il marchio La Montina si estende in tutto il territorio nazionale, consolidando la sua presenza in ogni angolo del Bel Paese. Inoltre, l'azienda collabora ufficialmente con l'AC Milan, attraverso la produzione esclusiva del prestigioso Franciacorta Rossonero. Oltre alla rinomata azienda vinicola e all'enoteca per la vendita diretta, le Tenute La Montina offrono un'esperienza eccezionale con la seicentesca Villa Baiana, location perfetta per matrimoni e eventi privati, il Baiana Centro Congressi e il primo Museo di Arte Contemporanea in Franciacorta. Un luogo dove accoglienza e professionalità si fondono in un ambiente di rara bellezza rappresentato da un prodotto di altissima qualità. La villa è ideale per matrimoni, degustazioni, meeting aziendali, cene, feste e sfilate, offrendo spazi e saloni di primissimo livello.",
        minPurchase: 90
    },
    {
        name: 'Limitlex',
        image: 'limitlex',
        typeOfDiscount: {
            premium: 5,
            gold: 10,
            platinum: 20
        },
        category: 'Wear',
        isToPromote: false,
        description: "Limitlex è un brand di abbigliamento che unisce moda e arte digitale, nato nel 2020 a Bologna. <br/>Nello specifico, a livello artistico, Limitlex realizza t-shirt e felpe in cotone organico certificato, con grafiche innovative ed uniche, disegnate da Alessandro Pautasso, illustratore di Torino. (In arte Kaneda99). <br/>Per ogni t-shirt stampata è incluso il packaging che diventa un vero e proprio quadro, pronto da appendere.<br/> Parallelamente Limitlex presenta due volte l’anno dei prodotti di maglieria più ricercati, con vestibilità moderne, dando importanza al Made in Italy e alla qualità dei materiali.<br/> Attualmente Limitlex è presente in circa 30 negozi nel Nord Italia. Per tutti i possessori di Ellen Pass oltre alle scontistiche LIFE TIME sull'abbigliamento, sarà possibile visitare lo Showroom PRIVATO a Bologna per vedere i prodotti dal vivo."
    },
    {
        name: 'MustNode',
        image: 'mustnode',
        typeOfDiscount: {
            premium: 3,
            gold: 6,
            platinum: 10
        },
        isToPromote: true,
        category: 'Software House',
        description: "Mustnode è una startup che offre servizi di digitalizzazione nel contesto del Web 3.0. Specializzandosi nell'integrazione blockchain, lo sviluppo di dApp personalizzate e l'integrazione IoT con blockchain, aiutano le aziende a diventare scalabili, sicure e innovative. Il loro obiettivo è accompagnare le aziende in un percorso di digitalizzazione completo, fornendo soluzioni su misura per soddisfare le loro esigenze specifiche. Mustnode si propone come un partner affidabile per le aziende che desiderano abbracciare la trasformazione digitale e trarre vantaggio dalle nuove tecnologie emergenti."
    },
    {
        name: 'Nasasud',
        image: 'nasasud',
        typeOfDiscount: {
            premium: 5,
            gold: 10,
            platinum: 15
        },
        isToPromote: false,
        category: 'Artigianato Italiano',
        description: "Nasasud è un'azienda con sede a Terracina, fondata nel 1987. Nel corso degli anni, ha sviluppato una solida reputazione nel fornire soluzioni aziendali su misura, sfruttando l'esperienza acquisita e una vasta gamma di competenze. Dal concepimento del progetto fino alla sua realizzazione, Nasasud accompagna i clienti in tutte le fasi del processo, con un impegno costante per il miglioramento e la perfezione. Ciò significa che, partendo da un'idea iniziale, Nasasud lavora diligentemente per trasformarla in un risultato finale impeccabile. Il sito web di Nasasud è una vetrina di idee e progetti precedenti, offrendo un'opportunità per esplorare il loro portafoglio e scoprire come l'azienda ha trasformato le visioni dei clienti in realtà concrete. Inoltre, Nasasud è sempre disponibile per rispondere a qualsiasi richiesta di informazioni o domande che i clienti possano avere. Le aree di competenza di Nasasud includono servizi in vari settori, tra cui la progettazione e la realizzazione di insegne personalizzate, lavorazioni di materiali plastici, fresatura CNC, incisione CNC, stampa digitale, stampa ecosol, stampa UV, soluzioni di visualizzazione personalizzate, segnaletica e cartellonistica, dispositivi di sicurezza sul lavoro, abbigliamento personalizzato, targhe personalizzate, rivestimento veicoli, trattamenti per oscurare i vetri, tappeti intarsiati e servizi di interior design. Nasasud è orgogliosa di adattare queste competenze per soddisfare le esigenze specifiche di ciascun cliente, garantendo risultati di alta qualità e professionalità in ogni progetto.",
        minPurchase: 149
    },
    {
        name: 'NeonFlexItalia',
        image: 'neonflexitalia',
        typeOfDiscount: {
            premium: 10,
            gold: 15,
            platinum: 20
        },
        isToPromote: false,
        category: 'Artigianato Italiano',
        description: "NeonFlexItalia è un marchio artigianale italiano specializzato nella produzione e personalizzazione di insegne luminose di alta qualità. Con sede a Terracina, nel Lazio, la loro squadra di grafici e artigiani lavora sinergicamente per creare design unici e spettacolari. La precisione è al centro del loro processo di produzione, garantendo risultati perfetti. Le insegne luminose personalizzate vengono spedite direttamente ai clienti e, su richiesta, possono essere anche installate dai tecnici esperti di NeonFlexItalia. Direttamente dal sito Web, Grazie a un configuratore online intuitivo, i clienti possono scegliere font, colore, dimensioni e accessori. I prezzi sono chiari sin dall'inizio grazie a un sistema di quotazione immediata, garantendo una piacevole esperienza di acquisto per soluzioni luminose personalizzate e di eccellenza.",
        minPurchase: 130
    },
    {
        name: 'Resinoso',
        image: 'resinoso',
        typeOfDiscount: {
            premium: 10,
            gold: 15,
            platinum: 15
        },
        isToPromote: false,
        category: 'Arte 3.0',
        description: "L’artista Resinoso, nonché astro nascente dell’arte 3.0, realizza opere uniche con tanto di certificato di autenticità. Manufatti realizzati con passione e materiali di altissima qualità. Entra in un mondo dove arte e tecnologia si fondono. Ogni opera d’arte è unica e innovativa, realizzata con resine di altissima qualità e con la possibilità di richiedere la sua versione digitale (certificata in blockchain). Con Resinoso ogni acquisto è un’occasione per possedere un pezzo esclusivo e raro, destinato a crescere di valore nel tempo. Entra nel mondo di Resinoso e scopri l’arte 3.0, l'arte del del futuro. <br/><br/> Il platinum dà diritto anche ad un NFT in omaggio!!!"
    },
    {
        name: 'Scaling Parrots',
        image: 'scaling-parrots',
        typeOfDiscount: {
            premium: 5,
            gold: 7.5,
            platinum: 10
        },
        isToPromote: true,
        category: 'Software House',
        description: "Scaling Parrots è l’azienda leader in Italia e tra le primissime eccellenze in Europa in ambito blockchain. Un team di esperti, fondendo sviluppo, consulenza, economia ed informatica, conduce passo a passo aziende e privati a compiere la propria trasformazione digitale. Scaling Parrots collabora con brand di rilievo, industrie e istituti finanziari, ma anche con piccole realtà in svariati settori, offrendo soluzioni su misura e altissima qualità. Il curriculum è costellato di use case di successo. Non si tratta solo di prodotti, ma di un know-how senza pari. L'approccio di Scaling Parrots spazia dalla tokenizzazione all'integrazione IoT, dalla notarizzazione alle DApp, incanalando il vasto potenziale della Blockchain in soluzioni concrete. Con una comprensione profonda della tecnologia Blockchain, il team offre un supporto costante, adattando ogni strumento alla singola esigenza. Nell'era dell'innovazione digitale, Scaling Parrots si colloca come il partner ideale. La loro dedizione nel tradurre le potenzialità della Blockchain in risultati tangibili è il segreto del loro successo. Un'orchestra di creatività e competenza, pronta a dirigere ogni azienda verso le ghiotte opportunità che offre la blockchain per rendere il proprio business scalabile."
    },
    {
        name: 'State1',
        image: 'state1',
        typeOfDiscount: { },
        isToPromote: true,
        category: 'Metaverse',
        description: "State1 Metaverse, il metaverso business del futuro! 225 milioni di metri quadri di opportunità commerciali e creatività senza limiti. Un mondo dove ogni cosa è possibile. Scoprite le 18 zone uniche, dai quartieri centrali, alla zona Ellenika fino alle aree dedicate allo sport, alla tecnologia e alla finanza. State1 Metaverse sta per rivoluzionare il modo in cui facciamo business. Preparati a unirti a celebrità di tutto il mondo e a plasmare il futuro. Il team di State1 sta lavorando duramente per portare un’esperienza completa sotto tutti i punti di vista. Di elevata importanza la moneta interna, il 'Goldbrick', fondamentale per l'economia interna del metaverso e non solo... Alcune zone sono già aperte, (tra cui la nostra Ellenika Zone) e si possono già acquistare lotti di diverse metrature direttamente all’interno della mappa per poi personalizzarli a piacimento. State1 Metaverse è già operativo e visitabile in grafica fotorealistica. In arrivo anche un editor per creare le tue strutture virtuali personalizzate. Entra nel futuro! 'Benvenuti in Ellenika Zone, l'area esclusiva di 8.000.000 Mq acquisita da Ellenika Utility per potenziare il nostro programma. Siamo entusiasti di essere parte dell'ecosistema di State1 e di sfruttare al massimo le sue elevate potenzialità. Qui, il nostro focus principale è la zona vinicola, (Distretto n. 4) dove ospiteremo cantine pregiate per offrire un'esperienza senza paragoni. Aziende e attività commerciali affiliate ad Ellenika Utility possono offrire promozioni uniche, creando un servizio a 360° per voi. Unisciti a noi e scopri l'ecosistema in continua evoluzione di Ellenika Zone!!!"
    },
    {
        name: 'TecnoModulo',
        image: 'tecnomodulo',
        typeOfDiscount: {
            premium: 4,
            gold: 7,
            platinum: 10
        },
        isToPromote: true,
        category: 'Edilizia',
        description: "TecnoModulo è un'azienda fondata da un gruppo di professionisti e imprenditori determinati a offrire una vasta gamma di servizi nel settore dell'edilizia. La missione di TecnoModulo è quella di fornire ai clienti la migliore qualità di servizi e prodotti per l'edilizia, con soluzioni attentamente progettate per soddisfare le loro esigenze e quelle dei loro clienti, nonché le possibilità dei materiali utilizzati. TecnoModulo offre una vasta gamma di servizi, tra cui cartongessi, serramenti, fornitura e posa di parquet, e tinteggiatura. L'azienda è qui per aiutare i suoi clienti a realizzare i loro progetti edilizi con competenza e dedizione. La filosofia di TecnoModulo si basa sull'affinità tra i suoi clienti e i rispettivi progetti. Ogni nuovo progetto rappresenta un'opportunità di costruire insieme un percorso condiviso. TecnoModulo crede fermamente nella partnership con i propri clienti, nel lavoro di squadra e nella valorizzazione delle risorse comuni. Per l'azienda, il servizio al cliente significa fornire un supporto totale a chi ha scelto di affidarsi a loro, anticipando le esigenze, creando ambienti sicuri e confortevoli nei cantieri e contribuendo a immaginare un futuro prospero.",
        minPurchase: 1500
    }
]