import styled from 'styled-components';
import devices from 'style/breakpoints';
// import colors from 'style/palette/palette';


/* background: ${colors.transparent}; */
export const Backdrop = styled.div`

    display: none;
    align-items: center;
    justify-content: center;
    
    flex-direction: column;
    gap: 50px;

    width: 100vw;
    height: 100vh;

    font-size: 35px;
    font-weight: 600;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.85);

    img {
        max-width: 200px;
    }

    @media ${devices.appMinWidth} {
        display: flex;
    }
`;
