import styled from 'styled-components';

export const LinearDiscordButton = styled.button`

    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin: 50px 20px;
    padding: 10px 20px;

    background: linear-gradient(90deg, #31d7ec 0%, #6671ff 50%, #ea44e5 100%); 
    color: white;

    border: 5px solid white;
    border-radius: 50px;
    box-shadow: 2px 2px 5px grey;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    cursor: pointer;
    white-space: nowrap;

    img {
        width: 25px;
        margin: 0 0 5px 10px;
    }
`;

