import { FC } from 'react';

// utils
import { CONTACT_DATA } from './ContactsPage.helper';

// components
import TableCard from 'components/TableCard/TableCard';

// style
import { Page } from './style/ContactsPage.style'


const ContactsPage: FC<{}> = () => {

    return (
        <Page>
            <TableCard 
                data={CONTACT_DATA} 
                flavor='contacts'
            />
        </Page>
    );
};

export default ContactsPage;