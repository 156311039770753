import { FC } from 'react';

// redux
import { ModalOptions } from 'redux/slices/slice-modal.d';
import { addModal } from 'redux/actions/actions-modal';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

// assets
import DiscordPNG from 'assets/icons/discord-logo.png';
import YoutubePNG from 'assets/icons/youtube-logo.png';
import InstagramPNG from 'assets/icons/instagram-logo.webp';
// import OpenSeaPNG from 'assets/icons/openSea-logo.png';
import TwitterPNG from 'assets/icons/twitter-logo.png';

// components
import CookieManagementModal from 'components/CookieBar/CookieManagementModal/CookieManagementModal.component';

// style
import { 
    FooterRow,
    InfoBox,
    Info,
    SocialBox
} from './style/Footer.style';

const BASEURL = ((window.location.origin.includes('localhost') ? 'http://localhost:3000' : (
    window.location.origin.includes('test')) ? 'https://testapp.ellenikautility.com' : 
    'https://app.ellenikautility.com')
);


const Footer: FC<{}> = () => {

    const { isAppMobile } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();

    const cookieManagementHandler = () => {

        const modalContent: ModalOptions = {
            children: <CookieManagementModal />
        };

        dispatch( addModal(modalContent) );
    };

    return (
        <FooterRow>
            {isAppMobile && (
                <SocialBox>
                    <a 
                        href='https://www.instagram.com/ellenika_utility/' 
                        target='_blank' 
                        rel='noreferrer noopener'
                    >
                        <img 
                            alt='isntagram icon' 
                            src={InstagramPNG} 
                        />
                    </a>
                    <a 
                        href='https://www.youtube.com/@ellenika_utilityofficial/featured' 
                        target='_blank' 
                        rel='noreferrer noopener'
                    >
                        <img 
                            alt='youtube icon' 
                            src={YoutubePNG} 
                        />
                    </a>
                    <a 
                        href='https://discord.gg/Gn5qpDdqwF' 
                        target='_blank' 
                        rel='noreferrer noopener'
                    >
                        <img 
                            alt='discord icon' 
                            src={DiscordPNG} 
                        />
                    </a>
                    <a 
                        href='https://twitter.com/Ellenika_uNFT' 
                        target='_blank' 
                        rel='noreferrer noopener'
                    >
                        <img 
                            alt='twitter icon' 
                            src={TwitterPNG} 
                        />
                    </a>
                    {/* <a 
                        href='' 
                        target='_blank' 
                        rel='noreferrer noopener'
                    >
                        <img 
                            alt='opensea icon' 
                            src={OpenSea} 
                        />
                    </a> */}
                </SocialBox>
            )}

            <InfoBox>
                <Info>
                    2023 © Ellenika Utility Srl
                </Info>

                <Info>
                    Via Daniele Piccinini, 2 - 24122 Bergamo (BG)
                </Info>
            </InfoBox>

            <InfoBox>
                <Info>
                    Capitale Sociale 10000€ interamente versato
                </Info>

                <Info>
                    P.IVA - COD.FISC. - REG.IMPR. 04660680168
                </Info>
            </InfoBox>

            <InfoBox>
                <Info>
                    REA BG-479563
                </Info>

                <Info>
                    <a
                        href='mailto:info@ellenikautility.com'
                    >
                        email: info@ellenikautility.com
                    </a>
                </Info>
            </InfoBox>

            {isAppMobile && (
                <InfoBox>
                    <Info>
                        <a
                            href={'https://ellenikautility.com/docs/Regolamento_EllenPass.pdf'}
                            target='_blank'
                            rel='noopener noreferrer'
                            download
                        >
                            Regolamento EllenPass
                        </a>
                    </Info>

                    <Info>
                        <a
                            href={'https://ellenikautility.com/docs/Lightpaper_Ellenika_utility.pdf'}
                            target='_blank'
                            rel='noopener noreferrer'
                            download
                        >
                            Lightpaper
                        </a>
                    </Info>

                    <Info>
                        <a
                            href={'https://ellenikautility.com/docs/Ellenika_B2C_User_utility_club.pdf'}
                            target='_blank'
                            rel='noopener noreferrer'
                            download
                        >
                            B2C
                        </a>
                    </Info>
                </InfoBox>
            )}

            <InfoBox>
                <Info>
                    <a
                        href={`${BASEURL}/terms`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        T&C
                    </a>
                </Info>

                <Info>
                    <a
                        href={`${BASEURL}/privacy`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Privacy
                    </a>
                </Info>
            </InfoBox>

            <InfoBox>
                <Info>
                    <a
                        href={`${BASEURL}/cookie`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Cookie
                    </a>
                </Info>

                <Info
                    onClick={cookieManagementHandler}
                >
                    <span>Gestione Cookie</span>
                </Info>
            </InfoBox>
        </FooterRow>
    );
};

export default Footer;
