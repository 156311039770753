import { FC } from 'react';

// routing
import { Outlet } from 'react-router';

// style
import { Page } from './style/FallbackPages.style';


const FallbackPages: FC<{}> = () => {

    return (
        <Page>
            <Outlet />
        </Page>
    );
};

export default FallbackPages;
