import { styled } from 'styled-components';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 100px 20px 200px;
`;

export const WalletDisclaimer = styled.p`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 20px;
    padding: 0;

    font-size: 20px;
    font-weight: 600;

    a {

        color: inherit;
        text-decoration: underline !important;
        cursor: pointer;
    }

    svg {

        max-width: 50px;
        margin: 0 0 0 20px;
    }
`;

export const InputWalletBox = styled.div`

    @media screen and (max-width: 768px) {
        
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 20px;
        flex-direction: column;
    }

`;

export const WalletInput = styled.input`

    width: 300px;

    font-size: 20px;

    border: 1px solid #011f71;
    border-radius: 5px 0 0 5px;

    @media screen and (max-width: 768px) {
        
        border-radius: 5px;
    }
`;

export const SaveWalletButton = styled.button`

    margin: 0;
    padding: 1px 20px;

    background: #011f71;

    font-size: 20px;
    font-weight: 600;
    color: white;

    border: 1px solid #011f71;
    border-radius: 0 5px 5px 0;

    @media screen and (max-width: 768px) {
        
        border-radius: 5px;
    }
`;
