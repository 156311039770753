import { styled } from 'styled-components';


export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 100px 20px 200px;
`;
