import { set, ref } from "firebase/database";
import { getDBData, getFirebaseDB, removeDataDB, updateDB, userDBData } from "./firebase.services";
import { assignEllenPass } from "./Web3/Web3.services";

export const discountCodesDBData = async () => {

    const discountCodesDataPath = `discountCodes`;
  
    return await getDBData(discountCodesDataPath);
  };
  
export const deleteDiscountCode = async (dicountCodeToDelete: string) => {
  
    if ((dicountCodeToDelete === 'ellenika10-210683') || (dicountCodeToDelete === 'ellenika20-270894') || (dicountCodeToDelete === 'ellenika40-110693')) return;
  
    const discountCodesDataPath = `discountCodes`;
    const discountCodes = await discountCodesDBData();
    const remainigCodes = discountCodes.filter((code: number) => code !== +dicountCodeToDelete);
  
    removeDataDB(discountCodesDataPath, remainigCodes);
};
  
const transactionsToVerifyDBData = async (path?: string) => {
  
    const transactionsToVerifyDataPath = path ?? `transactionsToVerify`;
  
    return await getDBData(transactionsToVerifyDataPath);
};
  
const processedTransactionsDBData = async () => {
  
    const processedTransactionsDataPath = `closedTransactions`;
  
    return await getDBData(processedTransactionsDataPath);
};
  
export const transactionFromVerifyToProcessed = async (transaction: string, transactionPayload: any) => {
  
    const transactionsToVerifyDataPath = `transactionsToVerify`;
    const processedTransactionsDataPath = `closedTransactions`;
    const processedPayload = {
      [transaction]: transactionPayload
    };
  
    const transactionsToVerifyData = await transactionsToVerifyDBData();
    const remainingTransactionsToVerify = Object.keys(transactionsToVerifyData).filter(transactionData => transactionData !== transaction);
    removeDataDB(transactionsToVerifyDataPath, remainingTransactionsToVerify);
    updateDB(processedTransactionsDataPath, processedPayload);
};
  
export const ellenPassTransactionFromVerifyToProcessed = async (transaction: string, transactionPayload: any) => {
  
    const authUser = await userDBData();
    if (authUser === null) return;
    if (authUser.wallet === null) return;

    const transactionsToVerifyDataPath = `ellenPassTransactionsToVerify`;
    const processedTransactionsDataPath = `closedTransactions`;
    const processedPayload = {
      [transaction]: transactionPayload
    };
  
    const transactionsToVerifyData = await transactionsToVerifyDBData(transactionsToVerifyDataPath);
    // to do mint here
    await assignEllenPass(authUser.wallet, transactionPayload.data.typeOfPass);
    
    delete transactionsToVerifyData[transaction];

    await removeDataDB(transactionsToVerifyDataPath, transactionsToVerifyData); 
    await updateDB(processedTransactionsDataPath, processedPayload);
    
    return;
};
  
export const checkTransaction = async (transaction: string) => {
  
    try {
      
      const transactionsToVerifyData = await transactionsToVerifyDBData();
      if ((transactionsToVerifyData.length !== 0) && transactionsToVerifyData[transaction]) return 'Transaction to verify found';
      
      const processedTransactionsData = await processedTransactionsDBData();
      if ((processedTransactionsData.length !== 0) && processedTransactionsData[transaction]) return 'Transaction closed found';
    
      return 'To process';
    } catch (error) {
      throw error;
    }
};
  
export const checkEllenPassTransaction = async (transaction: string) => {
  
    const transactionsToVerifyDataPath = `transactionsToVerify`;

    try {
      
      const transactionsToVerifyData = await transactionsToVerifyDBData(transactionsToVerifyDataPath);
      if ((transactionsToVerifyData.length !== 0) && transactionsToVerifyData[transaction]) return 'Transaction to verify found';
      
      const processedTransactionsData = await processedTransactionsDBData();
      if ((processedTransactionsData.length !== 0) && processedTransactionsData[transaction]) return 'Transaction closed found';
    
      return 'To process';
    } catch (error) {
      throw error;
    }
};
  
  export const addTransactionToVerify = async (transaction: string, userId: string, payload: any) => {
  
    const transactionsToVerifyDataPath = `transactionsToVerify`;
    const checkStatus = await checkTransaction(transaction);  
  
    if (checkStatus === 'Transaction closed found') return 'Transaction already processed';
    if (checkStatus === 'Transaction to verify found') return 'Transaction must be matched';
  
    const database = await getFirebaseDB();
    const transactionsToVerifyData = await transactionsToVerifyDBData();
  
    if (transactionsToVerifyData.length === 0) return set(ref(database, `${transactionsToVerifyDataPath}`), {...payload});
  
    // updateDB(transactionsToVerifyDataPath, payload);
    updateDB(`${transactionsToVerifyDataPath}/${transaction}`, {userId, payload});
    return 'Transaction added';
};

  export const addEllenPassTransactionToVerify = async (transaction: string, userId: string, payload: any) => {
  
    const transactionsToVerifyDataPath = `ellenPassTransactionsToVerify`;
    const checkStatus = await checkTransaction(transaction);  
  
    if (checkStatus === 'Transaction closed found') return 'Transaction already processed';
    if (checkStatus === 'Transaction to verify found') return 'Transaction must be matched';
  
    const database = await getFirebaseDB();
    const transactionsToVerifyData = await transactionsToVerifyDBData(transactionsToVerifyDataPath);
  
    if (transactionsToVerifyData.length === 0) return set(ref(database, `${transactionsToVerifyDataPath}`), {...payload});
  
    // updateDB(transactionsToVerifyDataPath, payload);
    updateDB(`${transactionsToVerifyDataPath}/${transaction}`, {userId, ...payload});
    return 'Transaction added';
};

export const addProcessedTransaction = async (transactionPayloadData: any) => {
  
    const processedTransactionsDataPath = `closedTransactions`;
    
    updateDB(processedTransactionsDataPath, transactionPayloadData);
};
  
export const getUserPendingTransactions = async (userId: string) => {
  
    const pendingTransactionsData = await transactionsToVerifyDBData();
    const userPendingTransactionsData = Object.keys(pendingTransactionsData).filter(transaction => pendingTransactionsData[transaction].userId === userId);
  
    let userPendingTransactions = [];
  
    for (let i = 0; i < userPendingTransactionsData.length; i++) {
      userPendingTransactions.push(pendingTransactionsData[userPendingTransactionsData[i]]);
    }
  
    return userPendingTransactions;
};
  
export const getUserEllenPassPendingTransactions = async (userId: string) => {
  
    const transactionsToVerifyDataPath = `ellenPassTransactionsToVerify`;

    const pendingTransactionsData = await transactionsToVerifyDBData(transactionsToVerifyDataPath);
    const userPendingTransactionsData = Object.keys(pendingTransactionsData).filter(transaction => pendingTransactionsData[transaction].data.userId === userId);
  
    let userPendingTransactions = [];
  
    for (let i = 0; i < userPendingTransactionsData.length; i++) {
      userPendingTransactions.push({data: pendingTransactionsData[userPendingTransactionsData[i]], codTrans: userPendingTransactionsData[i]});
    }
  
    return userPendingTransactions;
};
  