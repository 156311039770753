import { FC } from 'react';
import ReactDOM from 'react-dom';

// redux
import useAppSelector from 'hooks/useAppSelector';

// assets
import LogoPGN from 'assets/logo-ellenika.png';

// style
import { Backdrop } from './style/BaseLoader.style';


const BaseLoader: FC<{}> = () => {

    const { isLoading } = useAppSelector(state => state.ui);
    const backdropPortalElement = document.getElementById('backdrop-root');

    if (backdropPortalElement === null) return <></>;

    return (
        <>
            {isLoading && (
                ReactDOM.createPortal(
                    <Backdrop>
                        <img src={LogoPGN} alt='loader' />
                        Loading...
                    </Backdrop>,
                    backdropPortalElement
                )
            )}
        </>
    );
}

export default BaseLoader;
