import { FC } from 'react';

// asset
import LogoPNG from 'assets/logo-orizzontale.png';
import CookiePrimaPNG from './assets/cookie-prima.png';
import CookieTerzaPNG from './assets/cookie-terza.png';

// style
import {
    Page,
    Content,
    Title,
    SubTitle
} from './style/Cookie.style';


const Cookie: FC<{}> = () => {

    return (
        <Page>
            <Content>
                <img
                    alt='ellenika logo'
                    src={LogoPNG}
                />

                <Title>
                    Cookie Policy
                </Title>
                
                Ellenika Utility S.r.l. (“Società”) Vi ringrazia per aver visitato le nostre pagine web e per
                l’interesse dimostrato nei confronti della nostra azienda e dei nostri prodotti. <br /> <br />
                La presente cookie policy (“Policy”) si applica al sito http://ellenikautility.com (“Sito”) e
                si riferisce al trattamento dei dati personali effettuato dalla Società in occasione
                dell’interazione con il Sito. La presente Policy è resa solo per il Sito e non per altri siti di
                terze parti eventualmente consultati dall’utente tramite link. <br />
                La Policy è stata redatta in conformità al Provvedimento generale del Garante Privacy
                del 10 giugno 2021 “Linee guida cookie e altri strumenti di tracciamento” e al del
                Regolamento UE 2016/679 relativo alla “protezione delle persone fisiche con riguardo al
                trattamento dei dati personali, nonché alla libera circolazione di tali dati” (“GDPR”), dal
                momento che “Le persone fisiche possono essere associate a identificativi online prodotti
                dai dispositivi, dalle applicazioni, dagli strumenti e dai protocolli utilizzati, quali gli
                indirizzi IP, marcatori temporanei ("Cookie”) o identificativi di altro tipo, quali i tag di
                identificazione a radiofrequenza. Tali identificativi (“Dati”) possono lasciare tracce che,
                in particolare se combinate con identificativi univoci e altre informazioni ricevute dai
                server, possono essere utilizzate per creare profili delle persone fisiche e identificarle”
                (“Trattamento”).
                
                <SubTitle>
                    TITOLARE DEL TRATTAMENTO
                </SubTitle>
                
                Ellenika Utility S.r.l. con sede legale in Via Piccinini, 2 – 24122 Bergamo (BG) è il
                Titolare del Trattamento Dati ed è contattabile all’indirizzo: infoellenika@gmail.com
                
                <SubTitle>
                    INFORMAZIONI GENERALI SUI COOKIE
                </SubTitle>
                
                I Cookie sono stringhe di testo che i siti web posizionano ed archiviano all’interno di un
                dispositivo terminale dell’utente, i software per la navigazione in internet e i dispositivi
                dopo aver memorizzato i Cookie possono trasmetterli nuovamente ai siti che li hanno
                generati in occasione di una successiva visita del medesimo utente, mantenendo così
                memoria della sua precedente interazione con uno o più siti web. <br />
                I Cookie possono svolgere importanti funzioni e possono essere classificati nelle
                seguenti categorie: <br />
                I Cookie tecnici sono quelli utilizzati dal titolare di un sito al solo fine di effettuare la
                
                <SubTitle>
                    Cookie Tecnici
                </SubTitle>
                
                trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella
                misura strettamente necessaria al fornitore di un servizio della società dell’informazione
                esplicitamente richiesto dall’abbonato o dall’utente a erogare tale servizio. I Cookie
                Tecnici possono scadere al termine di una sessione del browser oppure possono essere
                conservati per un arco di tempo più lungo, per periodi di tempo variabili, e permettono
                di memorizzare le preferenze o le azioni dell’utente rispetto a un sito web. <br />
                - Cookie di navigazione o sessione <br />
                I Cookie di Navigazione, anche detti “necessari, permettono la normale navigazione del
                Sito Questi Cookie non memorizzano informazioni e dati identificativi. Per l’installazione
                di tali Cookie non è richiesto il preventivo consenso degli utenti laddove utilizzati
                direttamente dal gestore del sito. <br /> <br />
                - Cookie di Funzionalità <br />
                I Cookie di Funzionalità permettono all’utente la navigazione in funzione di una serie di
                criteri preselezionati. Per l’installazione di tali Cookie non è richiesto il preventivo
                consenso degli utenti laddove utilizzati direttamente dal gestore del sito. <br />
                - Analitici <br />
                I Cookie analitici permettono al gestore del sito o a terze parti di monitorare l’utilizzo del
                sito da parte degli utenti e svolgere analisi statistiche.
                
                <SubTitle>
                    Cookie di Profilazione
                </SubTitle>
                
                I cookie di profilazione sono volti a creare profili relativi all’utente e vengono utilizzati
                per inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso
                nell’ambito della navigazione in rete. Per l’installazione di tali Cookie è richiesto il
                preventivo consenso che l’utente può esprimere la prima volta che visita il sito.
                
                <SubTitle>
                    Cookie di Prima parte e Cookie di terza parte
                </SubTitle>
                
                Un Cookie è definito “di prima parte” (c.d. first party cookie) o “di terze parti” (c.d. third
                party cookie) in base al sito web o al dominio da cui proviene. <br />
                I Cookie di prima parte sono quelli impostati e gestiti dal titolare del sito. Per questi
                Cookie, l’obbligo dell’informativa, dell’acquisizione dell’eventuale previo consenso e/o il
                possibile blocco dei Cookie spetta al titolare del sito. I Cookie di terza parte sono i
                cookie impostati da un dominio diverso da quello visitato dall’utente. Per questi Cookie,
                l’obbligo dell’informativa e dell’indicazione delle modalità per l’eventuale consenso e/ o
                blocco dei Cookie spetta alla terza parte.
                
                <SubTitle>
                    COOKIE UTILIZZATI DAL SITO
                </SubTitle>

                <img
                    alt='cookie prima parte'
                    src={CookiePrimaPNG}
                />

                <img
                    alt='cookie terza parte'
                    src={CookieTerzaPNG}
                />

                <SubTitle>
                    COSA CONSENTE L’UTILIZZO DEI COOKIE
                </SubTitle>
                
                Il Sito tratta i dati prevalentemente in base al consenso dell’utente. Il conferimento del
                consenso avviene tramite il banner posto in fondo alla pagina. Con l'uso o la
                consultazione del Sito l’utente acconsente al Trattamento dei dati personali in relazione
                alle modalità e alle finalità ivi descritte, compreso l'eventuale diffusione a terzi se
                necessaria per l'erogazione di un servizio. Tramite i moduli di comunicazione o di
                richiesta di servizi vengono raccolti ulteriori consensi relativi alla finalità specifica del
                servizio. <br />
                Il consenso all’uso di Cookie viene prestato nel seguente modo: Cliccando sul pulsante
                “Accettare tutto” l’Utente acconsente all’uso di tutte le tipologie di Cookie utilizzati sul
                Sito web. Cliccando il pulsante “Rifiuta tutto”, verranno installati solo i Cookie Necessari
                al funzionamento del Sito.
                
                <SubTitle>
                    COME MODIFICARE LE IMPOSTAZIONI SUI COOKIE
                </SubTitle>
                
                È possibile configurare il browser utilizzato per la navigazione per eliminare o per
                impedire l’installazione di cookie, ma la disattivazione di alcuni cookie potrebbe
                impedire il corretto funzionamento del Sito. L’Utente può controllare quali cookie sono
                installati, qual è la loro durata ed eliminarli. I passaggi per eseguire queste operazioni
                differiscono da browser a browser: <br />
                ● Microsoft Explorer <br />
                ● Safari <br />
                ● Chrome <br />
                ● Firefox
                
                <SubTitle>
                    LUOGO DEL TRATTAMENTO DEI DATI
                </SubTitle>
                
                I trattamenti connessi ai servizi web di questo Sito hanno luogo presso la sede della
                Società e sono curati solo da personale tecnico dell'ufficio incaricato del Trattamento,
                oppure da eventuali incaricati di occasionali operazioni di manutenzione.
                
                <SubTitle>
                    MODIFICHE A QUESTA COOKIE POLICY
                </SubTitle>
                
                Considerando l'evoluzione tecnica della materia e degli strumenti che consentono la
                tutela della privacy sul web, la Società si impegna ad aggiornare periodicamente tale
                documento al fine di garantire la sicurezza dei dati e del Sito. Ti invitiamo a consultare
                periodicamente questa pagina dove pubblicheremo eventuali modifiche alla presente
                Policy. <br /> <br />
                Ultima modifica: 28 MARZO 2023
            </Content>
        </Page>
    );
};

export default Cookie;
