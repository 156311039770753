import React from 'react';

// assets
// import NotAvailableImg from 'assets/images/not-available-img.jpg'
import Building11Fl from 'assets/state1Buildings/11Fl.jpg';
import Building17Fl from 'assets/state1Buildings/17Fl.jpg';
import Building80In from 'assets/state1Buildings/80In.jpg';
import Building150In from 'assets/state1Buildings/150In.jpg';
import Building190In from 'assets/state1Buildings/190In.jpg';
import Building190Out from 'assets/state1Buildings/190Out.jpg';
import Building380InUp from 'assets/state1Buildings/380InUp.jpg';
import BuildingVillas from 'assets/state1Buildings/villas.jpg';

// style
import {
    PropertyCard,
    ImageBox,
    DetailsBox,
    PropertyName,
    Description,
    Quantity
} from './style/OwnedPropertyCard.style';

const BUILDINGS_IMAGE = [
    Building11Fl,
    Building150In,
    Building17Fl,
    Building190In,
    Building190Out,
    Building380InUp,
    Building80In,
    BuildingVillas
]

const BUILDINGS_DESC = [
    {
        code: '11Fl',
        name: 'Pallazzo di 11 piani',
        desc: `Palazzo di 11 piani (piano terra e 10 piani). Il piano terra è composto da 2 locali commerciali di 190mq, l'ultimo da 1 attico di 380mq e i restanti da 2 appartamenti di 150mq e 1 di 80mq.`
    },
    {
        code: '150In',
        name: 'Appartamento di 150mq',
        desc: `Appartamento/ufficio di 150mq che si trova all'interno di un palazzo.`
    },
    {
        code: '17Fl',
        name: 'Pallazzo di 11 piani',
        desc: `Palazzo di 17 piani (piano terra e 16 piani). Il piano terra è composto da 4 locali commerciali di 190mq, l'ultimo da 2 attici di 380mq e i restanti da 4 appartamenti di 150mq e 2 di 80mq.`
    },
    {
        code: '190In',
        name: 'Appartamento di 190mq',
        desc: `Locale commerciale di 190mq con le vetrine verso l'esterno che si trova al piano terra di un palazzo.`
    },
    {
        code: '190Out',
        name: 'Appartamento di 190mq',
        desc: `Locale commerciale di 190mq con le vetrine verso l'esterno che si trova in un lotto.`
    },
    {
        code: '380InUp',
        name: 'Attico di 380mq',
        desc: `Attico di 380mq che si trova all'ultimo piano di un palazzo.`
    },
    {
        code: '80In',
        name: 'Ufficio di 80mq',
        desc: `Appartamento/ufficio di 80mq che si trova all'interno di un palazzo.`
    },
    {
        code: 'villas',
        name: 'Villa',
        desc: 'Villa esclusiva.'
    },
]


const OwnedPropertyCard = (propsData) => {

    const getImage = () => {

        let buildingToSearch = propsData.name;
        // if (propsData.name.includes('MALL')) buildingToSearch = propsData.name.split('MALL')[0];

        const buildingImage = BUILDINGS_IMAGE.filter((image) => image.includes(buildingToSearch));
        return buildingImage[0];
    };

    const buildingDesc = BUILDINGS_DESC.filter(desc => desc.code === propsData.name)[0];

    return (

        <PropertyCard>
            <ImageBox>
                <img alt='building' src={getImage()} />
            </ImageBox>

            <DetailsBox>
                <PropertyName>
                    Zona: <span>{propsData.zone.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
                </PropertyName>

                <PropertyName>
                    Proprietà: <span>{buildingDesc.name}</span>
                </PropertyName>

                <Description>
                    Descrizione: {buildingDesc.desc}
                </Description>

                <Quantity>
                    Quantità possedute: {propsData.quantity}
                </Quantity>
            </DetailsBox>
        </PropertyCard>
    );
};

export default OwnedPropertyCard;
