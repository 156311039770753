import { FC, useEffect } from 'react';

// redux
import { setIsSidebarMenuOpen } from 'redux/slices/slice-ui';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

// assets
import LogoPNG from 'assets/logo-orizzontale.png'

// components
import SidebarNavbar from './SidebarNavbar/SidebarNavbar.component';
import DiscordButton from 'components/DiscordButton/DiscordButton.component';

// style
import { 
    Content,
    LogoBox
} from './style/Sidebar.style';


const Sidebar: FC<{}> = () => {

    const { isSidebarMenuOpen, isAppMobile } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();

    useEffect(() => {

        if (isAppMobile) dispatch( setIsSidebarMenuOpen(false) );
    }, [isAppMobile, dispatch]);

    return (
        <>
            {isSidebarMenuOpen && (
                <Content>
                    <LogoBox>
                        <img src={LogoPNG} alt='Ellenika Logo' />
                    </LogoBox>

                    <SidebarNavbar />

                    <DiscordButton />
                </Content>
            )}
        </>
    );
};

export default Sidebar;
