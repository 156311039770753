import React from 'react';
import ReactDOM from 'react-dom';

// redux
import { Provider } from 'react-redux';
import store from 'redux/store';

// routing
import { BrowserRouter as Router } from 'react-router-dom';

// components
import App from './App/App.component';

// style
import './index.css';


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
