import { sendEmailPur } from "./emailjs.services";
import { nexiPaymentCheck } from "./nexi.services";
import { getUserEllenPassPendingTransactions, ellenPassTransactionFromVerifyToProcessed } from "./payments.services";


export const checkEllenPassPendingPayment = async (stopFunction?: () => void, transaction?: number | string, typeOfPass? : 'premium' | 'gold' | 'platinum') => {
    // http://localhost:3000/payments?xpay__lb__token=&mail=frank.berni.79%40gmail.com&data=20230702&messaggio=Message+OK&cognome=Berni&nazionalita=ITA&regione=&mac=17a6da9b57ecfa633f9062eb5137531e0ef5eb3f&codAut=46J2MC&tipoProdotto=VISA+CLASSIC+-+CREDIT+-+N&selectedcard=&alias=ALIAS_WEB_00070342&pan=453997******0006&brand=VISA&Note3=center&Note2=150In&Note1=1&orario=173051&divisa=EUR&scadenza_pan=203012&importo=200000&codiceEsito=0&languageId=ITA&nome=Frank&check=&tipoTransazione=VBV_FULL&codiceConvenzione=00070342104&codTrans=EUTRANSCOD1688311823015&descrizione=Nr.+1+Appartamento%2Fufficio+di+150mq&esito=OK&aliasEffettivo=&OPTION_CF=&num_contratto=
    
    const authUser = JSON.parse(window.localStorage.getItem('authUser')!); // to do manage null

    try {
                    
        const userPendingTransactions = await getUserEllenPassPendingTransactions(authUser.uid);

        if ((userPendingTransactions.length === 0) || (userPendingTransactions === undefined)) {
        
            if (stopFunction) stopFunction();
            throw new Error ("No pending transactions...");
        };
        
        for (let i = 0; i < userPendingTransactions.length; i++) {
            
            const passPrice = (userPendingTransactions[i].data.typeOfPass === 'premium') ?
                99 : ((userPendingTransactions[i].data.typeOfPass === 'gold') ?
                149 : 199);
                
            if (userPendingTransactions[i].codTrans === undefined) {
                
                if (stopFunction) stopFunction();
                throw new Error("Nexi payment not found")
            };
    
            try {
                
                const nexiResponse = await nexiPaymentCheck(userPendingTransactions[i].codTrans);
    
                if (nexiResponse.esito !== 'OK') {
                
                    // if (stopFunction) stopFunction();
                    throw new Error("Nexi payment not found")
                };
    
                await ellenPassTransactionFromVerifyToProcessed(`${userPendingTransactions[i].codTrans}`, userPendingTransactions[i].data);
    
                const purchasePayload = {
                    purchaseName: `Ellen Pass di tipo ${userPendingTransactions[i].data.typeOfPass}`,
                    purchaseQuantity: 1,
                    purchasePrice: passPrice
                    };
                await sendEmailPur('template_acquisto', authUser.email, purchasePayload);
                
            } catch (error) {
                
                if (stopFunction) stopFunction(); 
                throw error;
            }

            // if (stopFunction) stopFunction();
        }
    
    } catch (error: any) {

        if (error.message === "No pending transactions...") return;
        console.warn("ERROR:", error);
    }
    }; 
    