import { FC, useState } from 'react';

// redux
import { setIsSidebarMenuOpen } from 'redux/actions/actions-ui';

// hooks
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';

// utils
import { logoutUser } from 'services/firebase.services';

// assets
import HamburgerPNG from 'assets/icons/hamburger-icon.png'
import DiscordPNG from 'assets/icons/discord-logo.png';
import YoutubePNG from 'assets/icons/youtube-logo.png';
import InstagramPNG from 'assets/icons/instagram-logo.webp';
import OpenSeaPNG from 'assets/icons/openSea-logo.png';
import TwitterPNG from 'assets/icons/twitter-logo.png';
import UserPNG from 'assets/icons/user-icon.webp';
import SocialPNG from 'assets/icons/social-icon.png';

//style
import { 
    HeaderRow,
    NavContent,
    SocialBox,
    UserBox
} from './style/Header.style';


const Header: FC<{}> = () => {

    const [isSocialBoxOpen, setIsSocialBoxOpen] = useState<boolean>(false);
    const [isUserBoxOpen, setIsUserBoxOpen] = useState<boolean>(false);
    const { isSidebarMenuOpen, isAppMobile } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const sidebarToggleHandler = () => {
        dispatch( setIsSidebarMenuOpen(!isSidebarMenuOpen));
        setIsUserBoxOpen(false);
        setIsSocialBoxOpen(false);
    };

    const socialBoxTogglerHandler = () => {

        setIsSocialBoxOpen(prevState => !prevState);
        setIsUserBoxOpen(false);
    };
    
    const userTogglerHandler = () => {
        setIsUserBoxOpen(prevState => !prevState);
        setIsSocialBoxOpen(false);
    };
    
    const logoutHandler = async () => {

        try {
            
            await logoutUser();
            navigate('/authentication/login');
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };
    
    return (
        <HeaderRow>
            <img 
                alt='sidebar icon' 
                src={HamburgerPNG} 
                onClick={sidebarToggleHandler}
            />

            {!isAppMobile && <NavContent>
                <a
                    href='https://ellenikautility.com/docs/Regolamento_EllenPass.pdf' 
                    target='_blank' 
                    rel='noreferrer noopener'
                    download={'Papers B2B'}
                >
                    Regolamento EllenPass
                </a>
                
                <a
                    href='https://ellenikautility.com/docs/Lightpaper_Ellenika_utility.pdf' 
                    target='_blank' 
                    rel='noreferrer noopener'
                    download={'Papers B2B'}
                >
                    Lightpaper
                </a>
                
                <a
                    href='https://ellenikautility.com/docs/Ellenika_B2C_User_utility_club.pdf' 
                    target='_blank' 
                    rel='noreferrer noopener'
                    download={'Papers B2C'}
                >
                    B2C
                </a>
                
                <img 
                    alt='sidebar icon' 
                    src={SocialPNG} 
                    onClick={socialBoxTogglerHandler}
                />
                
                {isSocialBoxOpen && (
                    <SocialBox>
                        <a 
                            href='https://www.instagram.com/ellenika_utility/' 
                            target='_blank' 
                            rel='noreferrer noopener'
                        >
                            <img 
                                alt='isntagram icon' 
                                src={InstagramPNG} 
                            />
                        </a>
                        <a 
                            href='https://www.youtube.com/@ellenika_utilityofficial/featured' 
                            target='_blank' 
                            rel='noreferrer noopener'
                        >
                            <img 
                                alt='youtube icon' 
                                src={YoutubePNG} 
                            />
                        </a>
                        <a 
                            href='https://discord.gg/Gn5qpDdqwF' 
                            target='_blank' 
                            rel='noreferrer noopener'
                        >
                            <img 
                                alt='discord icon' 
                                src={DiscordPNG} 
                            />
                        </a>
                        <a 
                            href='https://twitter.com/Ellenika_uNFT' 
                            target='_blank' 
                            rel='noreferrer noopener'
                        >
                            <img 
                                alt='twitter icon' 
                                src={TwitterPNG} 
                            />
                        </a>
                        <a 
                            href='https://opensea.io/collection/ellen-pass-official' 
                            target='_blank' 
                            rel='noreferrer noopener'
                        >
                            <img 
                                alt='opensea icon' 
                                src={OpenSeaPNG} 
                            />
                        </a>
                    </SocialBox>
                )}

                <img 
                    alt='sidebar icon' 
                    src={UserPNG} 
                    onClick={userTogglerHandler}
                />
                
                {isUserBoxOpen && (
                    <UserBox
                        onClick={logoutHandler}
                    >
                        Logout
                    </UserBox>
                )}
            </NavContent>}
        </HeaderRow>
    );
};

export default Header;
