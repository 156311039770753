import { FC } from 'react';

// redux
import { ModalOptions } from 'redux/slices/slice-modal.d';
import { addModal } from 'redux/actions/actions-modal';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// types
import { PartnerCardProps } from './PartnerCard.component.d';

// assets
import RedXPNG from './assets/red-x.png';
import PremiumPNG from 'assets/premium-pass.png'
import GoldPNG from 'assets/gold-pass.png'
import PlatinumPNG from 'assets/platinum-pass.png'

// components
import PartnerModal from './PartnerModal/PartnerModal.component';

// style
import {
    Card,
    PartnerImage,
    PartnerName,
    TypeOfDiscount,
    // PremiumBadge,
    // GoldBadge,
    // PlatinumBadge,
    NotAvailableImage,
    PercentageBox,
    CategoryText,
    // StarsRow
} from './style/PartnerCard.style';


const PartnerCard: FC<PartnerCardProps> = ({
    name,
    typeOfDiscount,
    image,
    description,
    ownedPass,
    isToPromote,
    category,
    minPurchase
}) => {

    const dispatch = useAppDispatch();

    const onPartnerClickHandler = () => {

        const modalContent: ModalOptions = {

            children: (
                <PartnerModal
                    name={name}
                    typeOfDiscount={typeOfDiscount}
                    description={description}
                    image={image}
                    ownedPass={ownedPass}
                    isToPromote={isToPromote}
                    category={category}
                    minPurchase={minPurchase}
                />
            ),
            isBackDropCloseActive: true
        };

        dispatch( addModal(modalContent) );
    };

    return (
        <Card
            onClick={onPartnerClickHandler}
        >
            <CategoryText>
                {category}                
            </CategoryText>

            {image && (
                <PartnerImage 
                    alt='partner'
                    src={`https://ellenikautility.com/assets/partner/${image}.png`}
                />
            )}

            <PartnerName>
                {name}
            </PartnerName>

            {Object.keys(typeOfDiscount).length !== 0 && (
                <TypeOfDiscount>
                    <PercentageBox>
                        {/* <PremiumBadge /> */}
                        <img alt='premium' src={PremiumPNG} />

                        - {typeOfDiscount.premium ?? '/'} %

                        {(ownedPass !== undefined) && !ownedPass.includes('Premium') && (
                            <NotAvailableImage
                                alt='not available'
                                src={RedXPNG}
                            />
                        )}
                    </PercentageBox>

                    <PercentageBox>
                        {/* <GoldBadge /> */}
                        <img alt='premium' src={GoldPNG} />

                        - {typeOfDiscount.gold ?? '/'} %

                        {(ownedPass !== undefined) && !ownedPass.includes('Gold') && (
                            <NotAvailableImage
                                alt='not available'
                                src={RedXPNG}
                            />
                        )}
                    </PercentageBox>

                    <PercentageBox>
                        {/* <PlatinumBadge /> */}
                        <img alt='premium' src={PlatinumPNG} />

                        - {typeOfDiscount.platinum ?? '/'} %

                        {(ownedPass !== undefined) && !ownedPass.includes('Platinum') && (
                            <NotAvailableImage
                                alt='not available'
                                src={RedXPNG}
                            />
                        )}
                    </PercentageBox>
                </TypeOfDiscount>
            )}

            {/* <StarsRow></StarsRow> */}
        </Card>
    );
};

export default PartnerCard;
