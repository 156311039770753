import styled from 'styled-components';


export const Content = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    margin: 30px 0 0;
`;

export const LogoutItem = styled.div`

    width: 100%;
    margin: 20px 0 0;
    
    text-align: center;
    font-size: 20px;
`;
