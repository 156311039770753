import { FC, useEffect } from 'react';

// style
import {
    Page,
    Content,
    PageTitle,
    Description
} from './style/NotFoundPage.style';
import { useNavigate } from 'react-router';


const NotFoundPage: FC<{}> = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const timeout = setTimeout(() => navigate('/'), 5000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <Page>
            <Content>
                <PageTitle>
                    404 Pagina non trovata
                </PageTitle>

                <Description>
                    Qualcosa è andato storto... verrai a breve reindirizzatto alla home...
                </Description>
            </Content>
        </Page>
    );
};

export default NotFoundPage;
