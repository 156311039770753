import styled from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 100%;

    font-size: 30px;
    font-weight: 500;

    img {
        max-width: 300px;
    }
`;
