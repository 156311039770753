import CookieConsent, { Cookies } from "react-cookie-consent";

// hooks
import useAppDispatch from "hooks/useAppDispatch";
import { ModalOptions } from "redux/slices/slice-modal.d";
import CookieManagementModal from "./CookieManagementModal/CookieManagementModal.component";
import { addModal } from "redux/actions/actions-modal";
import { useState } from "react";


const CookieBar = () => {

    const [isOverlayOn, setIsOverlayOn] = useState<boolean>(true)
    const dispatch = useAppDispatch();

    const onAcceptCookieHandler = () => {

        Cookies.set('Cookie_Funzionali', 'true', {expires: 100});
        Cookies.set('Cookie_Analitici', 'true', {expires: 100});
        Cookies.set('Cookie_Profilazione', 'true', {expires: 100});
    };

    const onDeclineCookieHandler = () => {

        Cookies.set('Cookie_Funzionali', 'false', {expires: 100});
        Cookies.set('Cookie_Analitici', 'false', {expires: 100});
        Cookies.set('Cookie_Profilazione', 'false', {expires: 100});
    };

    const onCloseCookieBannerHandler = () => {

        Cookies.set('Cookie_Funzionali', 'false', {expires: 100});
        Cookies.set('Cookie_Analitici', 'false', {expires: 100});
        Cookies.set('Cookie_Profilazione', 'false', {expires: 100});
        Cookies.set('CookieConsent', 'false', {expires: 100, sameSite: 'Lax'});
        window.location.reload();
    };
    const cookieManagementHandler = () => {

        setIsOverlayOn(false);
        const modalContent: ModalOptions = {
            children: <CookieManagementModal />
        };

        dispatch( addModal(modalContent) );
    };

    return (

        <CookieConsent
            expires={100}
            buttonText="ACCETTARE TUTTO"
            onAccept={onAcceptCookieHandler}
            declineButtonText="RIFIUTARE TUTTO"
            onDecline={onDeclineCookieHandler}
            enableDeclineButton
            flipButtons
            overlay={isOverlayOn}
            style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "width": "100%",
                "background": "black",
                "color": "white",
                "lineHeight": "25px",
            }}
            buttonStyle={{
                "padding": "5px 20px",
                "background": "white",
                "borderRadius": "50px",
                "border": "5px solid #1E2251FC",
                "color": "#1E2251FC"
            }}
            declineButtonStyle={{
                "padding": "5px 20px",
                "background": "white",
                "borderRadius": "50px",
                "border": "5px solid #1E2251FC",
                "color": "#1E2251FC"
            }}
            overlayStyle={{
                // "background": "rgba(255, 255, 255, 0.5)"
                "background": "rgba(0, 0, 0, 0.5)"
            }}

        >
            <button
                style={{
                "background": "transparent",
                "border": "none",
                "color": "yellow",
                "fontSize": "25px",
                "padding": "20px",
                "cursor": "pointer"
                }}
                onClick={onCloseCookieBannerHandler}
            >
                X
            </button>

            <br />

            Utilizziamo i Cookie per migliorare la tua esperienza di navigazione, 
            offriti pubblicità o contenuti personalizzati e analizzare il nostro traffico. 
            Cliccando sul pulsante “Accettare tutto” l’Utente acconsente all’uso di tutte 
            le tipologie di Cookie utilizzate dal Sito web. Cliccando il pulsante “Rifiuta tutto”, 
            verranno installati solo i Cookie Necessari al funzionamento del Sito. 
            
            <br />
            
            <a href='https://drive.google.com/file/d/1PTXn-NEp9z8ZT_Hnnz07QxCoMAB6T9Ve/view?usp=drive_link'>Clicca qui per il documento sui Cookie utilizzati</a>

            <button 
                style={{
                    "padding": "5px 20px",
                    "background": "white",
                    "borderRadius": "50px",
                    "border": "5px solid #1E2251FC",
                    "color": "#1E2251FC",
                    "margin": "20px"
                }}
                onClick={cookieManagementHandler}
            >
                Gestisci Cookie
            </button>
        </CookieConsent>
    );
};

export default CookieBar;
