import { v4 as uuidv4 } from 'uuid';

// types
import { AppDispatch, RootState } from '../store';
import { ModalOptions } from '../slices/slice-modal.d';

// slice actions
import {
    setContent
} from '../slices/slice-modal';


export const addModal = (modalChildren: ModalOptions) => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { content } = getState().modal;

        const newContent = [

            ...content,
            {
                ...modalChildren,
                id: uuidv4()
            }
        ];

        dispatch( setContent(newContent) );
    };
};

export const removeModalByID = (modalID: string) => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { content } = getState().modal;
        const newContent = content.filter((modalData) => modalData!.id !== modalID);
        
        dispatch( setContent(newContent) );
    };
};

export const removeFirstModal = () => {

    return async (dispatch: AppDispatch, getState: () => RootState) => {

        const { content } = getState().modal;        
        const newContent = [...content];

        newContent.shift();
        dispatch( setContent(newContent) );
    };
};
