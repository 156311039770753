import styled from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: stretch;

    flex-direction: column;

    gap: 20px;

    padding: 20px;
`;

export const Title = styled.h2``;

export const Description = styled.p``;

export const TitleContent = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
`;

export const CardContent = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-wrap: wrap;
    gap: 20px;
`;