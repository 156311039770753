export const isInputBlank = (stringToCheck: string) => {

    if (stringToCheck.trim().length === 0) return true;
    return false;
};

export const emailValidityCheck = (email: string) => {
    
    if (isInputBlank(email)) return false;
    if (email.includes('@')) return true;
    return false;
};

export const passwordValidityCheck = (password: string) => {

    if (isInputBlank(password)) return false;
    if (password.length >= 8) return true;
    return false;
};