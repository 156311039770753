export const CONTACT_DATA = [
    {
        title: 'e-mail',
        value: 'info@ellenikautility.com'
    },
    {
        title: 'sito istituzionale',
        value: 'www.ellenikautility.com'
    },
    {
        title: 'discord',
        value: '@ellenikautility'
    },
    {
        title: 'telegram',
        value: 'ellenikautility'
    },
]
