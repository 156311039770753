import devices from "style/breakpoints/breakpoints";
import { css, styled } from "styled-components";

export const Page = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;

    width: 100%;
    padding: 20px 0;

    @media ${devices.tablet} {
        
        flex-direction: row;
    }
`;

export const Content = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    width: 100%;
    padding: 50px;

    @media screen and (max-width: 768px) {
        
        padding: 0;
        
        img {
            width: 100%;
        }
    }

`;

export const Title = styled.h2`

    margin: 0;
    padding: 0;

    font-size: 30px;
    text-transform: uppercase;
    color: #011f71;
`;

export const Description = styled.p`

    margin: 0;
    padding: 0;
`;

export const EllenPassChoiceRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 30px;
    flex-direction: column;

    width: 100%;

    @media ${devices.tablet} {

        flex-direction: row;
    }
`;

export const EllenPassBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 20px;
    border: 5px solid #011f71;
    border-radius: 25px;

    background: #F8F8FB;

    cursor: pointer;
`;

export const EllenPassType = styled.h4`

    margin: 0;
    padding: 0;

    text-transform: uppercase;
`;

export const EllenPassImage = styled.img`

    width: 100%;
    margin: 20px 0 0;
`;

export const EllenPassChoicedType = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    flex-direction: column;

    padding: 20px;
    border: 5px solid #011f71;
    border-radius: 25px;

    background: #F8F8FB;

    @media ${devices.tablet} {

        flex-direction: row;
    }
`;

export const VideoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;
    /* border: 5px solid white; */
    border-radius: 25px;

    background: transparent;
`;

export const ChoicedPassVideo = styled.video`

    width: 100%;
    max-width: 400px;

    border: 5px solid white;
    border-radius: 25px;
`;

export const ChoicedDescriptionBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const ChoicedTitle = styled.h4`

    margin: 0;
    padding: 0;

    text-transform: uppercase;
    text-decoration: underline;

    color: black;
`;

export const CoicedDescriptionList = styled.ul``;

export const ListItem = styled.li``;

export const ChoicedPrice = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px;
    
    border: 5px solid #df03e8;
    border-radius: 50px;

    background: white;
    font-size: 25px;
`;

export const MintingBox = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-direction: column;
    gap: 30px;

    max-width: 400px;
    margin: 50px 0 0;
    padding: 20px;

    border: 5px solid #df03e8;
    border-radius: 25px;

    background: linear-gradient(180deg, #011f71, #df03e8);
    color: white;
    text-transform: uppercase;
`;

export const MintingTitle = styled.h4`

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;

    font-size: 25px;
    font-weight: 700;
`;

export const MintingChoicedType = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;

    border: 5px solid #df03e8;
    border-radius: 50px;
    background: #F8F8FB;
    color: black;
    font-size: 20px;
    font-weight: 600;
`;

export const AddressRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
`;

export const AddressIcon = styled.div`

    flex-grow: 0;
    flex-shrink: 0;

    width: 100%;
    max-width: 30px;
    margin: 0 20px -10px 0;

    svg {
        path {
            fill: white;
        }
    }
`;

export const AddressBox = styled.div<{ $isDisabled?: boolean }>`

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;

    border: 5px solid #df03e8;
    border-radius: 50px;
    background: #F8F8FB;
    color: black;
    font-size: 8px;
    font-weight: 600;

    overflow-x: hidden;

    ${({$isDisabled}) => $isDisabled && (

        css`

            background-color: black;
            color: white;

            cursor: pointer;
        `
    )}

    @media ${devices.tablet} {
        font-size: 15px;
    }
`;

export const PriceRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
`;

export const MintingButton = styled.button<{ $isDisabled?: boolean }>`

    padding: 20px;
    
    border: 5px solid white;
    border-radius: 40px;

    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    background: #011f71;

    cursor: pointer;

    ${({$isDisabled}) => $isDisabled && (

        css`

            background-color: gray;
            color: black;
            border-color: black;

            cursor: default;
        `
    )}

    @media ${devices.tablet} {
        font-size: 40px;
    }
`;
