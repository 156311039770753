import devices from 'style/breakpoints/breakpoints';
import styled from 'styled-components';


export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;

    width: 250px;
    /* height: 100vh; */
    padding: 20px;

    background: white;

    overflow: auto;

    /* border-right: 5px solid linear-gradient(45deg, #011f71, #df03e8);;     */
    /* border-right: 5px solid #011f71; */

    @media ${devices.tablet} {
        overflow: hidden;
    }
`;

export const LogoBox = styled.div`

    width: 100%;

    img {
        width: 100%;
    }
`;
