import styled, { css } from 'styled-components';

export const BaseBackGround = styled.div<{ $maxWidth?: string}>`

    width: 100%;
    max-width: ${({$maxWidth}) => $maxWidth ?? '500px'};
    padding: 0 10px 10px 0;

    border-radius: 15px 0 10px 0;

    background: linear-gradient(45deg, #011f71, #df03e8);

    position: relative;
`;

export const WhiteBackGround = styled.div`

    border-radius: 5px 0 5px 0;

    background: white;
`;

export const RoundedCorner = styled.div<{ $isBottom?: boolean }>`

    width: 10px;
    height: 10px;

    background: #df03e8;

    border-radius: 50px 0 0 0;
    position: absolute;
    top: -10px;
    right: 0;

    ${({$isBottom}) => $isBottom && (

        css`

            background: #011f71;

            top: unset;
            bottom: 0;
            right: unset;
            left: -10px;
        `
    )}
`;

export const Content = styled.div`

        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
        width: 100%;
`;
