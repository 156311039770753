import { FC, useEffect } from "react";

// routing
import { useNavigate } from "react-router";

// redux
import { setIsLoading } from "redux/slices/slice-ui";

// hooks
import useAppDispatch from "hooks/useAppDispatch";

// style
import {
    Page,
    Title,
    Desctiption
 } from './style/PaymentClosed.style';


const PaymentClosed: FC<{}> = () => {
    
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    dispatch( setIsLoading(false) );

    useEffect(() => {
      const timeout = setTimeout(() => {
        navigate('/reservedarea');
      }, 5000);
    
      return () => {
        clearTimeout(timeout);
      }
    }, [])

    return (
        <Page>
            <Title>
                Questo Pagamento è stato già processato.
            </Title>

            <Desctiption>
                Il pagamento è stato già processato, il prodotto relativo all&#39;acquisto è stato già associato.
            </Desctiption>
        </Page>
    );
};

export default PaymentClosed;
