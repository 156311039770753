import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

// routing
import { useNavigate } from 'react-router';

// redux
import { setIsLoading } from 'redux/slices/slice-ui';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { logoutUser, registerUser } from 'services/firebase.services';
import { sendEmailVerification } from 'firebase/auth';

// components
import BaseInput from 'components/BaseInput/BaseInput.component';

// style
import {
    Content,
    LoginText,
    LoginButton,
    LinkBox,
    SwitchText,
    CheckBoxRow,
    CheckBox,
    Text
 } from './style/SignupForm.style';


const SignupForm: FC<{}> = () => {

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswodValid] = useState<boolean>(false);
    const [isCheckboxFlagged, setIsCheckboxFlagged] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const mailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const emailValidityHandler = (isEmailValid: boolean) => setIsEmailValid(isEmailValid);
    const passwordValidityHandler = (isPasswordValid: boolean) => setIsPasswodValid(isPasswordValid);
    const checkboxClickHandler = () => setIsCheckboxFlagged(prevState => !prevState);

    const loginHandler = () => navigate('/authentication/login');

    const openLegalHandler = (navigateTo: 'terms' | 'cookie' | 'privacy') => {
        
        const baseUrl = ((window.location.origin.includes('localhost') ? 'http://localhost:3000' : (
            window.location.origin.includes('test')) ? 'https://testapp.ellenikautility.com' : 
            'https://app.ellenikautility.com'));
        window.open(
            `${baseUrl}/${navigateTo}`,
            '_blank',
            'noreferrer noopener'
        );
    }

    const onSubmitFormHandler = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {

        e.preventDefault();
        if (isSubmitButtonDisabled) return;
        if ((mailRef === null) || (mailRef.current === null)) return;
        if ((passwordRef === null) || (passwordRef.current === null)) return;

        dispatch( setIsLoading(true) );
        try {

            const response = await registerUser(mailRef.current.value, passwordRef.current.value);

            if (response === null) return;

            await sendEmailVerification(response);
            await logoutUser();
            
            dispatch( setIsLoading(false) );
            navigate('/verify-email');
        } catch (error: any) {

            const snackbarData: SnackbarOptions = {};

            snackbarData.message = error.message;
            snackbarData.type = 'error';
                
            dispatch( addSnackbar(snackbarData) );

            console.warn("ERRORE:", error);
            dispatch( setIsLoading(false) );
        }
    };

    useEffect(() => {

        if (isEmailValid && isPasswordValid && isCheckboxFlagged) return setIsSubmitButtonDisabled(false);
        setIsSubmitButtonDisabled(true);
    }, [isEmailValid, isPasswordValid, isCheckboxFlagged]);

    return (
        <Content
            onSubmit={onSubmitFormHandler}
        >
            <LoginText>
                Iscriviti ad Ellenika Utility
            </LoginText>

            <BaseInput 
                inputRef={mailRef}
                validityChecker={emailValidityHandler}
            />

            <BaseInput 
                inputRef={passwordRef}
                type='password'
                validityChecker={passwordValidityHandler}
            />

            <CheckBoxRow>
                <CheckBox 
                    type='checkbox'
                    onClick={checkboxClickHandler}
                    />

                <Text>
                    Ho letto ed accettato i documenti riguardanti T&C, Pvicay e Cookie
                </Text>
            </CheckBoxRow>

            <LoginButton
                isDisabled={isSubmitButtonDisabled}
            >
                Iscriviti
            </LoginButton>

            <SwitchText>
                Se hai già un account su Ellenika Utility clicca qui per <span onClick={loginHandler}>accedere</span>
            </SwitchText>

            <LinkBox>
            Clicca qui per visionare <span onClick={() => openLegalHandler('terms')}>T&C</span>, <span onClick={() => openLegalHandler('cookie')}>Cookie</span> e <span onClick={() => openLegalHandler('privacy')}>Privacy</span>.
            </LinkBox>
        </Content>
    );
};

export default SignupForm;
