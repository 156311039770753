import { FC, useEffect, useRef, MouseEvent, useCallback } from 'react';
import ReactDOM from 'react-dom';

// redux
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { removeModalByID } from 'redux/actions/actions-modal';
// import { disableAppMain, enableAppMain } from 'redux/actions/actions-app-main';

// types
import { BaseModalProps } from './BaseModal.component.d';

// assets
import ClosePNG from 'assets/icons/closure-icon.png'

// components
import BaseCard from 'components/BaseCard/BaseCard.component';

// style
import { 
    Backdrop,
    ModalWindow,
    ModalPanel,
    CloseButtonRow
} from './style/BaseModal.style';


const BaseModal: FC<BaseModalProps> = ({
    isBackdropClickActive,
    isCloseButtonClicked,
    isModalCardClickActive,
    modalFixedStart,
    overflow,
    paddingBottom,
    width,
    onClose,
    children
}) => {

    const { content } = useAppSelector(state => state.modal);
    const backdropPortalElement = document.getElementById('backdrop-root');
    const modalPortalElement = document.getElementById('modal-root');
    const modalPanelRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();

    const closeModalHandler = useCallback(() => {

        if (content.length === 0) return;

        dispatch( removeModalByID(content[0].id) );
        // if (!onClose) return;
        // onClose();
        // dispatch( enableAppMain() );

    }, [content, dispatch]);

    const modalClickHandler = (event: MouseEvent<HTMLDivElement>) => {

        if (modalPanelRef == null || !modalPanelRef?.current) return;

        if (content[0].isBackDropCloseActive && !modalPanelRef.current.contains(event.target as Node)) {
            
            closeModalHandler();
            return;
        }

        // if (isModalCardClickActive && modalPanelRef.current.contains(event.target as Node)) {
        if (content[0].isModalCardCloseActive && modalPanelRef.current.contains(event.target as Node)) {

            closeModalHandler();
            return;
        }
    };

    // useEffect(() => dispatch( disableAppMain() ), [dispatch]);

    useEffect(() => {
        
        if (!isCloseButtonClicked) return;
        closeModalHandler();

    }, [isCloseButtonClicked, closeModalHandler]);

    if (backdropPortalElement == null || modalPortalElement == null) {
        return <></>;
    };

    if ((content.length === 0) || (content[0].children === undefined)) return (<></>);

    return (
        <>
            {(content.length !== 0) && (
                    ReactDOM.createPortal(
                    <Backdrop 
                        onClick={closeModalHandler}
                    />,
                    backdropPortalElement
                )
            )}

            {((content.length !== 0) ||
            (children !== undefined)) && (
                ReactDOM.createPortal(
                    <ModalWindow onClick={modalClickHandler}>
                        <ModalPanel 
                            ref={modalPanelRef}
                            modalFixedStart={modalFixedStart}    
                            width={width}
                            paddingBottom={paddingBottom}
                            overflow={overflow}
                            onClick={modalClickHandler}
                        >
                            <BaseCard
                                maxWidth='800px'
                            >
                                <CloseButtonRow
                                    onClick={closeModalHandler}
                                >
                                    <img alt='closure' src={ClosePNG} />
                                </CloseButtonRow>
                                {children ?? content[0].children}
                            </BaseCard>
                        </ModalPanel>
                    </ModalWindow>,
                    modalPortalElement
                )
            )}
        </>
    );
}

export default BaseModal;
