import { configureStore } from '@reduxjs/toolkit';

// slices
import uiReducer from './slices/slice-ui';
import snackbarReducer from './slices/slice-snackbar';
import modalReducer from './slices/slice-modal';


/* store map to provide to the toolkit provider ctx */
const store = configureStore(
    {
        reducer: {
            ui: uiReducer,
            snackbar: snackbarReducer,
            modal: modalReducer
        },
        
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(
            { serializableCheck: false }
        )
    }
);

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
