import sha1 from 'sha1';


const environment = () => {
    if (window.location.origin.includes('localhost')) return 'dev';
    if (window.location.origin.includes('test')) return 'test';
    return 'prod';
};

const NEXI_BASE = {
    // alias: (environment() === 'prod' ?  process.env.REACT_APP_NEXI_ALIAS : process.env.REACT_APP_NEXI_ALIAS_TEST),
    alias: process.env.REACT_APP_NEXI_ALIAS,
    divisa: 'EUR',
    url_state1: (environment() === 'prod' ? 'https:%2F%2Fapp.ellenikautility.com%2Fpayments%2Fstate1' : (
        (environment() === 'test') ? 'https:%2F%2Ftestapp.ellenikautility.com%2Fpayments%2Fstate1' : 'http:%2F%2Flocalhost.3000%2Fpayments%2Fstate1'
        )),
    url_base_back: (environment() === 'prod' ? 'https:%2F%2Fapp.ellenikautility.com%2Fpayments/refused' : (
        (environment() === 'test') ? 'https:%2F%2Ftestapp.ellenikautility.com%2Fpayments/refused' : 'http:%2F%2Flocalhost.3000%2Fpayments%2Frefused'
        )),
    url_ellenPass: (environment() === 'prod' ? 'https:%2F%2Fapp.ellenikautility.com%2Fpayments%2Fellenpass' : (
        (environment() === 'test') ? 'https:%2F%2Ftestapp.ellenikautility.com%2Fpayments%2Fellenpass' : 'http:%2F%2Flocalhost.3000%2Fpayments%2Fellenpass'
        )),
};

// const NEXI_URL = (environment() === 'prod' ? 'https://ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet' : 'https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet'); 
// const NEXI_SHORT_URL = (environment() === 'prod' ? 'https://ecommerce.nexi.it' : 'https://int-ecommerce.nexi.it'); 
// const macKey = (environment() === 'prod' ? process.env.REACT_APP_NEXI_MACKEY : process.env.REACT_APP_NEXI_MACKEY_TEST);
const NEXI_URL = 'https://ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet'; 
const NEXI_SHORT_URL = 'https://ecommerce.nexi.it'; 
const macKey = process.env.REACT_APP_NEXI_MACKEY;

const macCalculator = (codTrans: number | string, price: number | string) => {
    
    const stringToHash = `codTrans=${codTrans}divisa=EURimporto=${price}00${macKey}`;
    const macString = sha1(stringToHash);
    return macString;
};

const macCheckCalculator = (codTrans: number | string, ts: number | string) => {
    
    const stringToHash = `apiKey=${NEXI_BASE.alias}codiceTransazione=${codTrans}timeStamp=${ts}${macKey}`;
    const macString = sha1(stringToHash);
    return macString;
};

export const nexiState1PaymentCall = (buildingType: string, buildingZone: string, price: number | string, description: string, quantity: number | string) => {

    const codTrans = `EUTRANSCOD${new Date().getTime()}`;
    const mac = macCalculator(codTrans, price);
    const priceToSend = `${price}00`;
    
    window.location.href = `${NEXI_URL}?alias=${NEXI_BASE.alias}&divisa=EUR&url=${NEXI_BASE.url_state1}&url_back=${NEXI_BASE.url_base_back}&importo=${priceToSend}&codTrans=${codTrans}&mac=${mac}&descrizione=${description}&Note1=${quantity}&Note2=${buildingType}&Note3=${buildingZone}`
};

export const nexiEllenPassPaymentCall = (ellenPassType: 'premium' | 'gold' | 'platinum', price: number | string) => {

    const codTrans = `EUTRANSCOD${new Date().getTime()}`;
    const mac = macCalculator(codTrans, price);
    const priceToSend = `${price}00`;
    
    window.location.href = `${NEXI_URL}?alias=${NEXI_BASE.alias}&divisa=EUR&url=${NEXI_BASE.url_ellenPass}&url_back=${NEXI_BASE.url_base_back}&importo=${priceToSend}&codTrans=${codTrans}&mac=${mac}&descrizione=1 Ellenika Pass di tipo ${ellenPassType}&Note1=${ellenPassType}`
};

export const nexiPaymentCheck = async (codTrans: number | string) => {

    const ts = new Date().getTime();
    const mac = macCheckCalculator(codTrans, ts);
    const checkUrl = `${NEXI_SHORT_URL}/ecomm/api/bo/situazioneOrdine`;
    
    try {

        const payload = {
            apiKey: NEXI_BASE.alias,
            codiceTransazione: codTrans,
            timeStamp: ts,
            mac
        };
        
        const response = await fetch(checkUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
            .then(response => response.json())
            .then(data => data)
            .catch(err => console.warn("ERROR:", err));

        return response;
    } catch (error) {
        console.warn("ERROR:", error);
    }
};
