import styled, { css } from 'styled-components';
import { BaseButton } from 'style/components/BaseButton.style';

export const Content = styled.form`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    padding: 30px 20px;
`;

export const LoginText = styled.h2`

    margin: 0;
    padding: 0;

    color: #011f71;
    text-align: center;
`;

export const LoginButton = styled(BaseButton)<{ isDisabled: boolean}>`

    ${({isDisabled}) => isDisabled && (

        css`

            background: gray;
            color: black;

            cursor: default;
        `
    )}
`;

export const LinkBox = styled.div`

    font-size: 13px;
    
    span {

        font-style: italic;
        font-weight: 600;
        color: #df03e8;

        cursor: pointer;
    }
`;

export const SwitchText = styled.p`

    margin: 0;
    padding: 0;

    font-size: 15px;

    span {

        /* font-size: 14px; */
        font-style: italic;
        font-weight: 700;
        color: #df03e8;

        cursor: pointer;
    }
`;

export const CheckBoxRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 100%;
    max-width: 300px;
`;

export const CheckBox = styled.input``;

export const Text = styled.p``;