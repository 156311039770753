import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Routes, Route } from 'react-router-dom';

// utils
import { AppRoute } from 'routes';
import appRoutes from 'routes';


const buildElementForRoute = (route: AppRoute) => {

    return (
            route?.element ?? <></>
    );
};

const buildRoutes = (routes: AppRoute[]) => {

    return (
        <>
            {routes.map((route, _) => {

                if (!route.subRoutes) {

                    return (
                        <Route
                            key={uuidv4()}
                            path={route.isIndex === undefined ? route.path : ""}
                            index={route?.isIndex}
                            element={buildElementForRoute(route)}
                        />
                    );
                }

                return (
                    <Route
                        key={uuidv4()}
                        path={route.isIndex === undefined ? route.path : ""}
                        // index={route?.isIndex}
                        element={buildElementForRoute(route)}
                    >
                        {buildRoutes(route.subRoutes)}
                    </Route>
                );
            })}
        </>
    );
};

const BuildRoutes: FC<{}> = () => {

    if (!appRoutes || appRoutes === undefined || !Array.isArray(appRoutes) || appRoutes.length === 0) {

        return (
            <Routes>
                <Route
                    index
                    element={<></>}
                />

                <Route
                    path='*'
                    element={<></>}
                />
            </Routes>
        );
    }

    return (
        <Routes>
            {buildRoutes(appRoutes)}
        </Routes>
    );
};

export default BuildRoutes;
