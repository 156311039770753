import { FC } from 'react';

// routing
import BuildRoutes from 'routes/BuildRoutes/BuildRoutes';

// redux
import SnackbarList from 'components/SnackbarList';

// hooks
// import useAppSelector from 'hooks/useAppSelector';
import useAppIsMobile from 'hooks/useAppIsMobile';

// utils
import { initFirebaseBackend } from 'services/firebase.services';

// assets
// import logo from '../logo.svg';

// components
import BaseModal from 'components/BaseModal';
import BaseLoader from 'components/BaseLoader/BaseLoader.component';
import CookieBar from 'components/CookieBar/CookieBar.component';

// style
// import './style/App.css';
import { 
    MinRangeGuard,
    AppBox,
    // AppLogo,
    Disclaimer
} from './style/App.style';

  
const App: FC<{}> = () => {

    useAppIsMobile();
    initFirebaseBackend();
    // const { isAppMobile } = useAppSelector(state => state.ui);

    return (
        <>
            <MinRangeGuard>
                <Disclaimer>
                    Ellenika Utility

                    <br/>

                    <br/>

                    Ci dispiace, questo schermo è troppo piccolo per visualizzare bene la nostra applicazione...
                </Disclaimer>
            </MinRangeGuard>

            <AppBox>
                <BuildRoutes />
            </AppBox>

            <SnackbarList />

            <BaseModal />

            <BaseLoader />

            <CookieBar />
        </>
    );
}

export default App;
