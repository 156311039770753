import styled from 'styled-components';

export const HeaderRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-shrink: 0;

    width: 100%;
    height: 80px;
    padding: 0 30px;

    background: white;
    position: relative;

    img {
        
        max-width: 30px;
        cursor: pointer;
    }
`;

export const OpenSidebarButton = styled.div``;

export const NavContent = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 50px;

    > a {

        padding: 10px 20px;
        border: 5px solid #011f71;
        border-radius: 50px;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
    }
`;

export const SocialBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
    padding: 20px;

    background: white;
    border: 2px solid gray;
    border-radius: 5px;

    position: absolute;
    top: 90px;
    right: 50px;
`;

export const UserBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
    padding: 20px;

    background: white;
    border: 2px solid gray;
    border-radius: 5px;

    position: absolute;
    top: 90px;
    right: 50px;

    cursor: pointer;
`;
