import styled, { css } from 'styled-components';

export const Item = styled.div<{ $isOnFocus: boolean }>`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    font-size: 20px;
    
    a {
        
        padding: 0 0 5px 0;
        text-decoration: none;
        color: #011f71;
    }

    img {

        max-width: 20px;
        margin: 0 10px 0 0;
    }

    ${({$isOnFocus}) => $isOnFocus && (

        css`
            a {
                color: #df03e8;
            }
        `
    )}
`;

export const Category = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
`;
