import styled from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    justify-self: center;
    flex-direction: column;
    width: 100%;

    padding: 50px 20px;
`;

export const LogoBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    img {
        max-width: 200px;
    }
`;
