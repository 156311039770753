import { styled } from 'styled-components';

export const PropertyCard = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    width: 700px;
    padding: 20px;

    border: 5px solid #011f71;
    border-radius: 15px;

    @media screen and (max-width: 768px) {

        flex-direction: column;
        
        width: 100%;
        max-width: 700px;
    }
`;

export const ImageBox = styled.div`

    img {

        width: 250px;
    }
`;

export const DetailsBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
`;

export const PropertyName = styled.h3`

    font-size: 30px;
    font-weight: 300;

    span {

        font-style: italic;
        font-weight: 500;
    }
`;

export const Description = styled.p``;

export const Quantity = styled.p`

    font-size: 20px;
`;
