import { FC, useEffect, useState } from 'react';

// utils
import { 
    COOKIE_TYPES, 
    COOKIE_DESC,
    COOKIE_NAME
} from './CookieManagementModal.helper';

// style
import {
    Content,
    Title,
    Description,
    ButtonRow,
    AcceptButton,
    DeniedButton
} from './style/CookieManagementModal.style';
import { Cookies } from 'react-cookie-consent';


const CookieManagementModal: FC<{}> = () => {

    const [counter, setCounter] = useState<number>(0);

    const acceptClickHandler = () => {
        
        if (counter === 0) Cookies.set(COOKIE_NAME[counter], 'true', { expires: 100, sameSite: 'Lax'});
        
        if (counter !== 0) Cookies.set(COOKIE_NAME[counter], 'true', { expires: 100 });
        setCounter(prevState => prevState + 1);
    };
    const deniedClickHandler = () => {

        Cookies.set(COOKIE_NAME[counter], 'false', { expires: 100 });
        setCounter(prevState => prevState + 1)
    };

    useEffect(() => {
        if (counter === 4) window.location.reload();
    }, [counter]);

    return (
        <Content>
            <Title>
                {COOKIE_TYPES[counter]}
            </Title>

            <Description>
                {COOKIE_DESC[counter]}
            </Description>

            <ButtonRow>
                <AcceptButton
                    onClick={acceptClickHandler}
                >
                    Accetta
                </AcceptButton>

                {counter > 0 && (
                    <DeniedButton
                        onClick={deniedClickHandler}
                    >
                        Rifiuta
                    </DeniedButton>
                )}
            </ButtonRow>
        </Content>
    );
};

export default CookieManagementModal;
