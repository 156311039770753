// types
import { AppDispatch } from '../store';

// slice actions
import {
    setIsAppMobile as actionSetIsAppMobile,
    setIsLoading as actionSetIsLoading,
    setIsSidebarMenuOpen as actionSetIsSidebarMenuOpen
} from '../slices/slice-ui';


export const setIsAppMobile = (isMobile: boolean) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetIsAppMobile(isMobile) );
    };
};

export const setIsLoading = (isLoading: boolean) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetIsLoading(isLoading) );
    };
};

export const setIsSidebarMenuOpen = (isSidebarMenuOpen: boolean) => {

    return async (dispatch: AppDispatch) => {
        dispatch( actionSetIsSidebarMenuOpen(isSidebarMenuOpen) );
    };
};
