import { FC, useEffect, useState } from 'react';

// redux
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { setIsLoading } from 'redux/slices/slice-ui';
import { addModal } from 'redux/actions/actions-modal';
import { ModalOptions } from 'redux/slices/slice-modal.d';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';

// routing
import { useNavigate } from 'react-router';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { userDBData } from 'services/firebase.services';
import { EllenPassData } from './EllenPass.helper';
import { checkEllenPass } from 'services/Web3/Web3.services';

// components
import PurchaseModal from 'components/PurchaseModal/PurchaseModal.component';

// assets
import GoldPNG from 'assets/gold-pass.png';
import PremiumPNG from 'assets/premium-pass.png';
import PlatinumPNG from 'assets/platinum-pass.png';
import { ReactComponent as PoligonSVG } from 'assets/polygon-matic-logo.svg';

// style
import {
    Page,
    Content,
    Title,
    Description,
    EllenPassChoiceRow,
    EllenPassBox,
    EllenPassType,
    EllenPassImage,
    EllenPassChoicedType,
    VideoBox,
    ChoicedPassVideo,
    ChoicedDescriptionBox,
    ChoicedTitle,
    CoicedDescriptionList,
    ListItem,
    ChoicedPrice,
    MintingBox,
    MintingTitle,
    MintingChoicedType,
    AddressRow,
    AddressIcon,
    AddressBox,
    PriceRow,
    MintingButton
 } from './style/EllenPass.style';


const EllenPass: FC<{}> = () => {

    const [userData, setUserData] = useState<any>(); // to do
    const [passType, setPassType] = useState<'premium' | 'gold' | 'platinum'>('premium');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const typeHandler = (newType: 'premium' | 'gold' | 'platinum') => setPassType(newType);

    const mintHandler = async () => {
        
        if ((userData === undefined) || (userData.wallet === undefined) || (userData.wallet === '')) return;

        dispatch( setIsLoading(true) );

        try {

            const checkResponse = await checkEllenPass(userData.wallet, passType);

            if (`${checkResponse}` === '1') {

                const snackbarData: SnackbarOptions = {
                    type: 'error',
                    message: 'Hai già un Ellen Pass di questa rarità.'
                };

                dispatch( addSnackbar(snackbarData) );
                dispatch( setIsLoading(false) );
                return;
            };
            
            const modalContent: ModalOptions = {
                children: (
                    <PurchaseModal 
                        isEllenPassPurchase
                        finalPrice={EllenPassData[passType].price}
                        ellenPassType={passType}
                    />
                )
            };
            dispatch( addModal(modalContent) );

            dispatch( setIsLoading(false) );
        } catch (error: any) {

            console.warn("ERROR:", error);
            dispatch( setIsLoading(false) );
        }
    };

    const insertWalletHandler = () => {
        navigate('/reservedArea/wallet');
    };

    const getUserData =  async () => {

        const response = await userDBData();
        setUserData(response);
    };

    useEffect(() => {
        getUserData();
    }, []);
    
    const userHasWallet = ((userData !== undefined) && (userData.wallet !== '') && (userData.wallet !== undefined)) ?? false;
    
    return (
        <Page>
            <Content>
                <Title>
                    Ellen Pass Utility
                </Title>

                <Description>
                    Acquista il tuo Ellen Pass per sbloccare tutte le promozioni
                    lifetime offerte dai nostri partner e per avere molti altri vantaggi
                </Description>

                <EllenPassChoiceRow>
                    <EllenPassBox
                        onClick={() => typeHandler('premium')}
                    >
                        <EllenPassType>
                            Premium
                        </EllenPassType>

                        <EllenPassImage src={PremiumPNG} />
                    </EllenPassBox>

                    <EllenPassBox
                        onClick={() => typeHandler('gold')}
                    >
                        <EllenPassType>
                            Gold
                        </EllenPassType>

                        <EllenPassImage src={GoldPNG} />
                    </EllenPassBox>

                    <EllenPassBox
                        onClick={() => typeHandler('platinum')}
                    >
                        <EllenPassType>
                            Platinum/B2B
                        </EllenPassType>

                        <EllenPassImage src={PlatinumPNG} />
                    </EllenPassBox>
                </EllenPassChoiceRow>

                <EllenPassChoicedType>
                    <VideoBox>
                        <ChoicedPassVideo
                            src={EllenPassData[passType].videoUrl}
                            autoPlay
                            loop
                        />
                    </VideoBox>

                    <ChoicedDescriptionBox>
                        <ChoicedTitle>
                            {EllenPassData[passType].name}
                        </ChoicedTitle>

                        <CoicedDescriptionList>
                            {EllenPassData[passType].benefitsList.map ((benefit: string, index: number) => {

                                return (
                                    <ListItem key={'listItem' + index}>
                                        {benefit}
                                    </ListItem>
                                );
                            })}
                        </CoicedDescriptionList>

                        <ChoicedPrice>
                        {EllenPassData[passType].price} €
                        </ChoicedPrice>
                    </ChoicedDescriptionBox>
                </EllenPassChoicedType>
            </Content>

            <MintingBox>
                <MintingTitle>
                    Rarità Ellen Pass
                </MintingTitle>

                <MintingChoicedType>
                    {EllenPassData[passType].name}
                </MintingChoicedType>

                <AddressRow>
                    <AddressIcon>
                        <PoligonSVG />
                    </AddressIcon>

                    Address wallet
                </AddressRow>

                {userHasWallet && (
                    <AddressBox>
                        {userData.wallet}
                    </AddressBox>
                )}

                {!userHasWallet && (
                    <AddressBox
                        $isDisabled
                        onClick={insertWalletHandler}
                    >
                        Per Procedere con l'acquisto dell'ellen pass bisogna aver inserito 
                        il proprio Address Wallet, clicca qui per inserirlo
                    </AddressBox>
                )}

                <PriceRow>
                    {EllenPassData[passType].name}: {EllenPassData[passType].price}€
                </PriceRow>

                <MintingButton
                    onClick={mintHandler}
                    $isDisabled={!userHasWallet}
                >
                    Mint/Acquista
                </MintingButton>
            </MintingBox>
        </Page>
    );
};

export default EllenPass;
