import { FC, useEffect, useState } from 'react';

// utils
import { userDBData } from 'services/firebase.services';
import { checkEllenPass } from 'services/Web3/Web3.services';

// assets
import { _PARTNERS } from './PartnerPage.helper';
import { PartnerCardProps } from './PartnerCard/PartnerCard.component.d';

// components
import PartnerCard from './PartnerCard/PartnerCard.component';

// style
import { 
    Page,
    Description,
    Title,
    TitleContent,
    CardContent
} from './style/PartnerPage.style';


const PartnerPage: FC<{}> = () => {

    const [ownedPass, setOwnedPass] = useState<string[]>([]);

    const getUserData = async () => {

        try {
            
            const userDataOnDB = await userDBData();

            const premiumResponse = await checkEllenPass(userDataOnDB.wallet, 'premium');
            const goldResponse = await checkEllenPass(userDataOnDB.wallet, 'gold');
            const platinumResponse = await checkEllenPass(userDataOnDB.wallet, 'platinum');

            const ellenPassOwnedType = () => {

                const ownedPassRes =[
                    +`${premiumResponse}` !== 0,
                    +`${goldResponse}` !== 0,
                    +`${platinumResponse}` !== 0
                ];

                let suppArr = [];
        
                if (ownedPassRes[0]) suppArr.push('Premium'); 
                if (ownedPassRes[1]) suppArr.push('Gold'); 
                if (ownedPassRes[2]) suppArr.push('Platinum'); 
                
                return suppArr;
            }
        
            setOwnedPass(ellenPassOwnedType());
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    useEffect(() => {
      getUserData()
    }, [])
    
    return (
        <Page>
            <TitleContent>
                <Title>
                    Parco PROMOZIONI offerte dai nostri PARTNER
                </Title>

                <Description>
                    Le promozioni possono essere riscattate in base alla rarità dei pass che possiedi
                </Description>
            </TitleContent>

            <CardContent>
                {_PARTNERS.map((partner: PartnerCardProps, index: number) => {

                return (
                    <PartnerCard 
                        key={"partnerCard" + index}
                        name={partner.name}
                        typeOfDiscount={partner.typeOfDiscount}
                        image={partner.image}
                        description={partner.description}
                        ownedPass={ownedPass}
                        isToPromote={partner.isToPromote}
                        category={partner.category}
                        minPurchase={partner.minPurchase}
                    />
                )})}
            </CardContent>
        </Page>
    );
};

export default PartnerPage;
