import { ref, set } from "firebase/database";
import { getDBData, getFirebaseDB, updateDB } from "./firebase.services";
import { nexiPaymentCheck } from "./nexi.services";
import { addProcessedTransaction, addTransactionToVerify, checkTransaction, getUserPendingTransactions, transactionFromVerifyToProcessed } from "./payments.services";
import { sendEmailPur } from "./emailjs.services";
import "firebase/compat/auth";
// import "firebase/compat/firestore";

const ELLENIKA_STATE_1_ZONES: string[] = [
    'ArenaPark',
    'EllenIsland',
    'LakeCityLine',
    'MezzaLuna',
    'PanoramaHill',
    'RedKongBeach',
    'Sportswear',
    'WhiteWeekZone',
    'WineZone'
  ];
  

  export const stateOneDBData = async () => {
  
    const stateOneDataPath = `stateOneBuildings`;
    let state1Data: any = {
      ArenaPark: {},
      EllenIsland: {},
      LakeCityLine: {},
      MezzaLuna: {},
      PanoramaHill: {},
      RedKongBeach: {},
      Sportswear: {},
      WhiteWeekZone: {},
      WineZone: {}
    };
  
    for (let i = 0; i < ELLENIKA_STATE_1_ZONES.length; i++) {
  
      const zoneData = await getDBData(`${stateOneDataPath}${ELLENIKA_STATE_1_ZONES[i]}`);
      state1Data[ELLENIKA_STATE_1_ZONES[i]] = zoneData;
    }
  
    return state1Data;
  };
  
  export const stateOneZoneDBData = async (zone: any) => {
  
    const stateOneDataPath = `stateOneBuildings${zone}`;
  
    const zoneData = await getDBData(stateOneDataPath);
    return zoneData;
  };
  
  export const stateOneMallDBData = async () => {
  
    const stateOneMallDataPath = `stateOneBuildingsShopCenter`;
  
    return await getDBData(stateOneMallDataPath);
  };
  
  export const updateStateOneBuildingQuantity = async (zone: string, building: string, buyedQuantity: number | string) => {
  
    const stateOneBuildingPath = `stateOneBuildings${zone}/${building}`;
    
    updateDB(stateOneBuildingPath, {quantity: buyedQuantity}); // to do manage trycatch
  };
  
  export const updateStateOneMallBuildingQuantity = async (building: string, buyedQuantity: string | number) => {
  
    const stateOneMallBuildingPath = `stateOneBuildingsShopCenter/${building}`;
    
    updateDB(stateOneMallBuildingPath, {quantity: buyedQuantity}); // to do manage trycatch
  };
  
  export const updateDBUserData = async (payload: any, path: string) => {
  
    const userID = JSON.parse(localStorage.getItem('authUser')!).uid; // to do manage null and trycatch
    let userDataPath = `userData/${userID}`;
    if (path) userDataPath = `userData/${userID}/${path}`;
    
    return updateDB(userDataPath, payload);
  };
  
  export const updateDBUserStateOneBuildings = async (userId: string, payload: any, path: string) => {
  
    // const userID = JSON.parse(localStorage.getItem('authUser')).uid;
    let userDataPath = `userData/${userId}`;
    if (path) userDataPath = `userData/${userId}/${path}`;
    
    const database = await getFirebaseDB(); // to do manage trycatch
    const dbData = await getDBData(`${userDataPath}`);
    
    if (dbData.length === 0) return set(ref(database, `${userDataPath}`), {...payload});
  
    const updatedPayload = {
      quantity: dbData.quantity + payload.quantity
    }
    set(ref(database, `${userDataPath}`), {...dbData, ...updatedPayload});
  
  };
  
  const updateBuildingOnDB = async (zone: string, quantity: string | number, buildingType: string) => {

    // const buildingType = getSearchParams(WINDOW_URL, 'Note2');

    try {
        const dataDB = await stateOneZoneDBData(zone);

        // const dbQuantity = data[buildingType].quantity;

        await updateStateOneBuildingQuantity(zone, buildingType, (+dataDB[buildingType].quantity - +quantity));
        // if (zone === 'center') return await updateStateOneBuildingQuantity(buildingType, (+data[buildingType].quantity - +quantity));
        // if (zone === 'mall') return await updateStateOneMallBuildingQuantity(buildingType, (+data[buildingType].quantity - +quantity));
    } catch (error) {
        console.warn("ERROR:", error);
    }
};

const associateBuilding = async (userId: string, zone: string, quantity: number | string, buildingType: string) => {

    // const buildingType = getSearchParams(WINDOW_URL, 'Note2');

    try {
        
        const payload = {
            quantity: +quantity,
            zone, 
            buildingType
        };

        await updateDBUserStateOneBuildings(userId, payload, `stateOneBuildings/stateOneBuildings${zone}`);
    } catch (error) {
        console.warn("ERROR:", error);
    }
};

export const checkPaymentFromUrlForBuildings = async (
    transaction: string, 
    purchasedQuantity: number | string,
    buildingZone: string,
    buildingName: string,
    buildingPrice: number | string
) => {
    
// acquisto su ArenaPark
// http://localhost:3000/payments?xpay__lb__token=&mail=frank.berni.79%40gmail.com&data=20230702&messaggio=Message+OK&cognome=Berni&nazionalita=ITA&regione=&mac=17a6da9b57ecfa633f9062eb5137531e0ef5eb3f&codAut=46J2MC&tipoProdotto=VISA+CLASSIC+-+CREDIT+-+N&selectedcard=&alias=ALIAS_WEB_00070342&pan=453997******0006&brand=VISA&Note3=ArenaPark&Note2=150In&Note1=1&orario=173051&divisa=EUR&scadenza_pan=203012&importo=200000&codiceEsito=0&languageId=ITA&nome=Frank&check=&tipoTransazione=VBV_FULL&codiceConvenzione=00070342104&codTrans=EUTRANSCOD1688311823015&descrizione=Nr.+1+Appartamento%2Fufficio+di+150mq&esito=OK&aliasEffettivo=&OPTION_CF=&num_contratto=

if (!transaction || transaction === 'noSearchParams') {

    window.history.replaceState({}, document.title, "/");
    window.location.href = "/";
    
    return
};
// if (!transactionStatus || transactionStatus !== 'OK') return;

const authUser = JSON.parse(window.localStorage.getItem('authUser')!); // to do manage null

try {
    
    const response = await nexiPaymentCheck(transaction);

    // to do manage codTrans not found
    if (response.esito !== 'OK') {

        window.history.replaceState({}, document.title, "/" + "refused");
        window.location.href = "payments/refused";
        return;
    }

    const transactionResponse = await checkTransaction(transaction);

    if (transactionResponse === 'Transaction closed found') { 

        // alert("Il pagamento è stato già gestito");
        window.history.replaceState({}, document.title, "/");
        window.location.href = "/payments/closed";
        return;
    };

    if (transactionResponse === 'Transaction to verify found') {

        try {
            
            await checkPaymentForBuildings();
            return;
        } catch (error) {

            console.warn("ERROR:", error);
            return;
        }
    };

    const transactionPayload = {
        [transaction]: {
            userId: authUser.uid,
            purchaseName: buildingName,
            buildingZone,
            purchaseQuantity: +purchasedQuantity,
            purchasePrice: +buildingPrice / 100
        }
    }

    await addProcessedTransaction(transactionPayload);
    await updateBuildingOnDB(buildingZone, +purchasedQuantity, buildingName);
    await associateBuilding(authUser.uid, buildingZone, +purchasedQuantity, buildingName);

    const purchasePayload = {
        purchaseName: buildingName,
        purchaseQuantity: purchasedQuantity,
        purchasePrice: +buildingPrice / 100
    };
    await sendEmailPur('template_acquisto', authUser.email, purchasePayload);

    window.history.replaceState({}, document.title, "/" + "confirmed");
    window.location.href = "payments/confirmed";
} catch (error) {
    const transactionPayload = {
        [transaction]: {
            userId: authUser.uid,
            purchaseName: buildingName,
            buildingZone,
            purchaseQuantity: purchasedQuantity,
            purchasePrice: +buildingPrice / 100
        }
    }
    await addTransactionToVerify(transaction, authUser.uid, transactionPayload);

    window.history.replaceState({}, document.title, "/");
    window.location.href = "/payments/tomanage";

    console.warn("ERROR:", error);
}
}; 

export const checkPaymentForBuildings = async (stopFunction?: () => void, transaction?: number | string, buildingData? : any) => {
// http://localhost:3000/payments?xpay__lb__token=&mail=frank.berni.79%40gmail.com&data=20230702&messaggio=Message+OK&cognome=Berni&nazionalita=ITA&regione=&mac=17a6da9b57ecfa633f9062eb5137531e0ef5eb3f&codAut=46J2MC&tipoProdotto=VISA+CLASSIC+-+CREDIT+-+N&selectedcard=&alias=ALIAS_WEB_00070342&pan=453997******0006&brand=VISA&Note3=center&Note2=150In&Note1=1&orario=173051&divisa=EUR&scadenza_pan=203012&importo=200000&codiceEsito=0&languageId=ITA&nome=Frank&check=&tipoTransazione=VBV_FULL&codiceConvenzione=00070342104&codTrans=EUTRANSCOD1688311823015&descrizione=Nr.+1+Appartamento%2Fufficio+di+150mq&esito=OK&aliasEffettivo=&OPTION_CF=&num_contratto=

const authUser = JSON.parse(window.localStorage.getItem('authUser')!); // to do manage null

try {
                
    const userPendingTransactions = await getUserPendingTransactions(authUser.uid);

    if ((userPendingTransactions.length === 0) || (userPendingTransactions === undefined)) {
    
        if (stopFunction) stopFunction();
        return
    };
    
    for (let i = 0; i < userPendingTransactions.length; i++) {
        
        if (userPendingTransactions[i] === undefined) continue;

        try {
            
            const nexiResponse = await nexiPaymentCheck(userPendingTransactions[i]);

            if (nexiResponse.esito !== 'OK') return;

            await transactionFromVerifyToProcessed(`${userPendingTransactions[i]}`, userPendingTransactions[i]);
            await updateBuildingOnDB(userPendingTransactions[i].buildingZone, +userPendingTransactions[i].purchasedQuantity, userPendingTransactions[i].buildingType);
            await associateBuilding(userPendingTransactions[i].userId, userPendingTransactions[i].buildingZone, +userPendingTransactions[i].purchasedQuantity, userPendingTransactions[i].buildingType);

            const purchasePayload = {
                purchaseName: buildingData.buildingName,
                purchaseQuantity: buildingData.purchasedQuantity,
                purchasePrice: buildingData.buildingPrice / 100
            };
            await sendEmailPur('template_acquisto', authUser.email, purchasePayload);
            if (stopFunction) stopFunction();

        } catch (error) {

            console.warn("ERROR:", error);
            if (stopFunction) stopFunction(); 
        }
    }

} catch (error) {
    console.warn("ERROR:", error);
}
}; 
