import { FC, useState } from 'react';

//types
import { BaseInputProps } from './BaseInput.component.d';

// utility
import { emailValidityCheck, passwordValidityCheck } from './BaseInput.helper';

// assets
import showPasswordIcon from './assets/show-password-icon.png';
import hidePasswordIcon from './assets/hide-password-icon.png';

// style
import {
    BaseLabel,
    CustomInput,
    InputBox,
    SeePwButton
 } from './style/BaseInput.style';


const BaseInput: FC<BaseInputProps> = ({ 
    type, 
    inputRef,
    validityChecker
}) => {

    // const [inputLabel, setInputLabel] = useState<'e-mail' | 'password'>(type ?? 'e-mail')
    const [inputType, setInputType] = useState<'e-mail' | 'password'>(type ?? 'e-mail')
    const [inputError, setInputError] = useState<boolean | undefined>(undefined);

    const onBlurHandler = () => {

        if (inputRef.current === null) return;
        
        if (type === 'password') {

            setInputError(!passwordValidityCheck(inputRef.current.value));
            validityChecker(passwordValidityCheck(inputRef.current.value));
            return;
        };

        setInputError(!emailValidityCheck(inputRef.current.value))
        validityChecker(emailValidityCheck(inputRef.current.value));
    };

    const onFocuseHandler = () => {
        setInputError(false);
    };

    const showPasswordHandler = () => {

        if (inputType === 'e-mail') return setInputType('password');
        setInputType('e-mail');
    };

    const errorMessage = (type === 'password' ? 
        "La password deve essere di almeno 8 caratteri" : 
        "Email non valida"
    );

    const inputLabel = type ?? 'email';

    return (
        <BaseLabel>
            <span>{inputLabel}</span>

            <InputBox>
                <CustomInput
                    ref={inputRef}
                    type={inputType}
                    onBlur={onBlurHandler}
                    onFocus={onFocuseHandler}
                    $isInputInError={inputError}
                />

                {inputLabel === 'password' && (
                    <SeePwButton
                        onClick={showPasswordHandler}
                    >
                        {inputType === 'e-mail' && <img alt='Show password' src={hidePasswordIcon} />}

                        {inputType === 'password' && <img alt='Hide password' src={showPasswordIcon} />}
                    </SeePwButton>
                    )}
            </InputBox>

            {inputError && errorMessage}
        </BaseLabel>
    );
};

export default BaseInput;
