import { FC } from 'react';

// utils
import { PartnerCardProps } from '../PartnerCard.component.d';

// assets
import RedXPNG from '../assets/red-x.png';
import PremiumPNG from 'assets/premium-pass.png'
import GoldPNG from 'assets/gold-pass.png'
import PlatinumPNG from 'assets/platinum-pass.png'

// style
import {
    Content,
    CardContentBox,
    PartnerImage,
    DiscountBox,
    DiscountRow,
    // PremiumBadge,
    // GoldBadge,
    // PlatinumBadge,
    DescriptionBox,
    PartnerName,
    Description,
    ActionButton,
    NotAvailableImage
} from './style/PartnerModal.style';


const PartnerModal: FC<PartnerCardProps> = ({
    name,
    typeOfDiscount,
    description,
    image,
    ownedPass,
    isToPromote,
    minPurchase
}) => {

    const actionButtonHandler = () => {

        window.open(
            'https://discord.gg/Gn5qpDdqwF',
            '_blank',
            'noreferrer noopener'
        )
    };

    return (
        <Content>
            <CardContentBox>
                <PartnerImage
                    alt='partner'
                    src={`https://ellenikautility.com/assets/partner/${image}.png`}
                />

                {Object.keys(typeOfDiscount).length > 0 && (
                    <DiscountBox>
                        <DiscountRow>
                            {/* <PremiumBadge /> */}
                            <img alt='premium' src={PremiumPNG} />

                            Premium

                            <div>
                                - {typeOfDiscount.premium} %
                            </div>

                            {(ownedPass !== undefined) && !ownedPass.includes('Premium') && (
                                <NotAvailableImage
                                    alt='not available'
                                    src={RedXPNG}
                                />
                            )}
                        </DiscountRow>

                        <DiscountRow>
                            {/* <GoldBadge /> */}
                            <img alt='premium' src={GoldPNG} />

                            Gold

                            <div>
                                - {typeOfDiscount.gold} %
                            </div>

                            {(ownedPass !== undefined) && !ownedPass.includes('Gold') && (
                                <NotAvailableImage
                                    alt='not available'
                                    src={RedXPNG}
                                />
                            )}
                        </DiscountRow>

                        <DiscountRow>
                            {/* <PlatinumBadge /> */}
                            <img alt='premium' src={PlatinumPNG} />

                            Platinum

                            <div>
                                - {typeOfDiscount.platinum} %
                            </div>

                            {(ownedPass !== undefined) && !ownedPass.includes('Platinum') && (
                                <NotAvailableImage
                                    alt='not available'
                                    src={RedXPNG}
                                />
                            )}
                        </DiscountRow>
                    </DiscountBox>
                )}
            </CardContentBox>

            <DescriptionBox>
                <PartnerName>
                    {name}
                </PartnerName>
                
                {description && <Description dangerouslySetInnerHTML={{__html: description}}>
                    {/* {description} */}
                </Description>}

                {minPurchase && (
                    <Description>
                        Acquisto minimo richiesto: {minPurchase} €
                    </Description>
                )}

                <ActionButton
                    onClick={actionButtonHandler}
                >
                    {isToPromote && "Attiva la Promozione"}

                    {!isToPromote && "Riscatta il tuo codice sconto"}
                </ActionButton>
            </DescriptionBox>
        </Content>
    );
};

export default PartnerModal;
