// assets
// import NotAvailableImg from 'assets/images/not-available-img.jpg'
import Building11Fl from 'assets/state1Buildings/11Fl.jpg';
import Building17Fl from 'assets/state1Buildings/17Fl.jpg';
import Building80In from 'assets/state1Buildings/80In.jpg';
import Building150In from 'assets/state1Buildings/150In.jpg';
import Building190In from 'assets/state1Buildings/190In.jpg';
import Building190Out from 'assets/state1Buildings/190Out.jpg';
import Building380InUp from 'assets/state1Buildings/380InUp.jpg';
import BuildingVillas from 'assets/state1Buildings/villas.jpg';


export const NEXI_URL_TEST = 'https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet';

export const BUILDINGS_IMAGE = [
    Building11Fl,
    Building150In,
    Building17Fl,
    Building190In,
    Building190Out,
    Building380InUp,
    Building80In,
    BuildingVillas
];
