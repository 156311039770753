import { ReactElement } from 'react';

// routing
import { Navigate } from 'react-router';

// pages
import Landing from 'Pages/Authentication/Authentication.component';
import Terms from 'Pages/LegalPages/TermsAndConditions/TermsAndConditions.component';
import Privacy from 'Pages/LegalPages/Privacy/Privacy.component';
import Cookie from 'Pages/LegalPages/Cookie/Cookie.component';
import Authentication from 'Pages/Authentication/Authentication.component';
import LoginForm from 'Pages/Authentication/LoginForm/LoginForm.component';
import PasswordRecoveryForm from 'Pages/Authentication/PasswordRecoveryForm/PasswordRecoveryForm.component';
import ConfirmSignUpForm from 'Pages/Authentication/Authentication.component';
import SignupForm from 'Pages/Authentication/SignupForm/SignupForm.component';
import Payments from 'Pages/PaymentsPage/PaymentsPage.component';
import Dashboard from 'Pages/Dashboard/DashboardPage/DashboardPage.component';
// import AccountPage from 'Pages/Authentication/Authentication.component';
// import ShopPage from 'Pages/Authentication/Authentication.component';
import FallbackPages from 'Pages/FallbackPages/FallbackPages.component';
// import LoginError from 'Pages/Authentication/Authentication.component';
import ReservedArea from 'Pages/ReservedArea/ReservedArea.component';
// import GenericError from 'Pages/Authentication/Authentication.component';
// import ThanksForBuying from 'Pages/Authentication/Authentication.component';
// import ProductError from 'Pages/Authentication/Authentication.component';
import Error404 from 'Pages/FallbackPages/NotFoundPage/NotFoundPage.component';
// import ResetPage from 'Pages/Authentication/Authentication.component';
import StateOnePage from 'Pages/NFTSection/StateOnePage/StateOnePage.component';
import EllenPassPage from 'Pages/NFTSection/EllenPass/EllenPass.component';
import WalletPage from 'Pages/CryptoSection/WalletPage/WalletPage.component';
import ContactsPage from 'Pages/ContactsSection/ContactsPage/ContactsPage.component';
import PartnerPage from 'Pages/NFTSection/PartnerPage/PartnerPage.component';
import State1PaymentPage from 'Pages/PaymentsPage/State1PaymentPage/State1PaymentPage.component';
import PaymentRefused from 'Pages/PaymentsPage/PaymentRefused/PaymentRefused.component';
import PaymentClosed from 'Pages/PaymentsPage/PaymentClosed/PaymentClosed.component';
import PaymentConfirmed from 'Pages/PaymentsPage/PaymentConfirmed/PaymentConfirmed.component';
import PaymentToManage from 'Pages/PaymentsPage/PaymentToManage/PaymentToManage.component';
import EllenPassPaymentPage from 'Pages/PaymentsPage/EllenPassPaymentPage/EllenPassPaymentPage.component';
import VerifyEmail from 'Pages/Authentication/VerifyEmail/VerifyEmail.component';


export interface AppRoute {

    name: string,
    path: string,
    element: ReactElement,
    isIndex?: boolean | undefined,
    isProtected?: boolean | undefined,
    subRoutes?: AppRoute[]
};

/**
 * first item will always be root,
 * last item will always be "not found page"
 */
const appRoutes: AppRoute[] = [

    {
        name: 'root',
        path: "/",
        element: <Navigate to={'/authentication'} />,
    },
    // {
    //     name: 'root',
    //     path: "/",
    //     element: <LandingPage />,
    //     isIndex: true
    // },
    // {
    //     name: 'landing',
    //     path: "/landing",
    //     element: <LandingPage />
    // },
    {
        name: 'legalTerms',
        path: "/terms",
        element: <Terms />
    },
    {
        name: 'legalPrivacy',
        path: "/privacy",
        element: <Privacy />
    },
    {
        name: 'legalCookie',
        path: "/cookie",
        element: <Cookie />
    },
    {
        name: 'verify email',
        path: "verify-email",
        element: <VerifyEmail />
    },
    {
        name: 'authentication',
        path: "/authentication",
        element: <Authentication />,
        subRoutes: [
            {
                name: 'loginForm',
                path: "/",
                element: <LoginForm />,
                isIndex: true
            },
            {
                name: 'loginForm',
                path: "login",
                element: <LoginForm />
            },
            {
                name: 'loginPasswordRecovery',
                path: "password-recovery",
                element: <PasswordRecoveryForm />
            },
            {
                name: 'signUpForm',
                path: "signup",
                element: <SignupForm />
            },
        ]
    },
    // {
    //     name: 'signUp',
    //     path: "/sign-up",
    //     element: <SignUpPage />,
    //     subRoutes: [
    //     ]
    // },
    {
        name: 'Payments',
        path: "payments",
        element: <Payments />,
        subRoutes: [
            {
                name: 'State 1 order',
                path: "state1",
                element: <State1PaymentPage />
            },
            {
                name: 'Refused payment',
                path: "refused",
                element: <PaymentRefused />
            },
            {
                name: 'Ellen Pass order',
                path: "ellenpass",
                element: <EllenPassPaymentPage />
            },
            {
                name: 'Payment closed',
                path: "closed",
                element: <PaymentClosed />
            },
            {
                name: 'Payment confirmed',
                path: "confirmed",
                element: <PaymentConfirmed />
            },
            {
                name: 'Payment to manage',
                path: "tomanage",
                element: <PaymentToManage />
            }
        ]
    },
    {
        name: 'reservedArea',
        path: '/reservedArea',
        isProtected: true,
        element: <ReservedArea />,
        subRoutes: [
            {
                name: 'home',
                path: "home",
                element: <Dashboard />,
                isProtected: true,
                isIndex: true
            },
            // {
            //     name: 'userProfile',
            //     path: "account-page",
            //     element: <AccountPage />,
            //     isProtected: true
            // },
            // {
            //     name: 'shop',
            //     path: "shop",
            //     element: <ShopPage />,
            //     isProtected: true
            // },
            {
                name: 'state1',
                path: "state1",
                element: <StateOnePage />,
                isProtected: true
            },
            {
                name: 'wallet',
                path: "wallet",
                element: <WalletPage />,
                isProtected: true
            },
            {
                name: 'contacts',
                path: "contacts",
                element: <ContactsPage />,
                isProtected: true
            },
            {
                name: 'ellenpass',
                path: "ellenpass",
                element: <EllenPassPage />,
                isProtected: true
            },
            {
                name: 'partner',
                path: "partner",
                element: <PartnerPage />,
                isProtected: true
            },
        ]
    },
    {
       name: 'fallback',
       path: "/fallback-page",
       element: <FallbackPages />,
       subRoutes:[
        // {
        //     name: 'requiredLogin',
        //     path: "require-login",
        //     element: <LoginError />
        // },
        // {
        //     name: 'genericError',
        //     path: "generic-error",
        //     element: <GenericError />
        // },
        // {
        //     name: 'permissionDenied',
        //     path: "permission-denied",
        //     element: <AutorizationError />
        // },
        // {
        //     name: 'accountProblem',
        //     path: "accout-problem",
        //     element: <AccountProblemError />
        // },
        // {
        //     name: 'ThanksForBuying',
        //     path: "Thanks-for-buying",
        //     element: <ThanksForBuying />
        // },
        // {
        //     name: 'assignError',
        //     path: "assign-error",
        //     element: <ProductError />,
        // },
        {
            name: 'pageNotFound',
            path: "page-not-found",
            element: <Error404 />,
            isIndex: true
        }
       ]
    },
    // {
    //    name: 'reset',
    //    path: "/reset",
    //    element: <ResetPage />
    // },
    {
       name: 'default',
       path: "*",
       element: <Navigate to={'/fallback-page'} />
    }
];

export default appRoutes;
