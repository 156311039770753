import { FC, SyntheticEvent, useRef, useState } from 'react';

// components
import BaseInput from 'components/BaseInput/BaseInput.component';

// style
import { 
    Content,
    RecoveryText,
    RecoveryButton,
    SwitchText
} from './style/PasswordRecoveryForm.style';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { recoverPassword } from 'services/firebase.services';
import useAppDispatch from 'hooks/useAppDispatch';
import { addSnackbar } from 'redux/actions/actions-snackbar';
import { setIsLoading } from 'redux/slices/slice-ui';
import { useNavigate } from 'react-router';


const PasswordRecoveryForm: FC<{}> = () => {

    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const mailRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const emailValidityHandler = (isEmailValid: boolean) => setIsEmailValid(isEmailValid);
    const loginHandler = () => navigate('/authentication/login');
    const signupHandler = () => navigate('/authentication/signup');

    const onSubmitHandler = async (e: SyntheticEvent<HTMLFormElement>) => {

        e.preventDefault();
        if (!isEmailValid) return;
        if ((mailRef === null) || (mailRef.current === null)) return;

        dispatch( setIsLoading(true) );
        let snackbarData: SnackbarOptions = {};

        try {
            
            await recoverPassword(mailRef.current.value);

            snackbarData.type = 'success';
            snackbarData.message = 'Abbiamo inviato la mail con il link di reset alla mail indicata.'

            dispatch( setIsLoading(false) );
            dispatch( addSnackbar(snackbarData) );
            navigate('/');
        } catch (error: any) {
            console.warn("ERROR:", error);
            
            snackbarData.type = 'error';
            snackbarData.message = 'Abbiamo riscontrato un problema, riprova più tardi';

            dispatch( addSnackbar(snackbarData) );
            dispatch( setIsLoading(false) );
        }
    };

    return (
        <Content
            onSubmit={onSubmitHandler}
        >
            <RecoveryText>
                Inserisci la tua mail per ricevere il link di reset della password:
            </RecoveryText>

            <BaseInput
                inputRef={mailRef}
                validityChecker={emailValidityHandler}
            />

            <RecoveryButton
                isDisabled={!isEmailValid}
            >
                Invia richiesta
            </RecoveryButton>

            <SwitchText>
                Clicca qui se vuoi tornare al <span onClick={loginHandler}>login</span> oppure 
                qui se vuoi andare alla <span onClick={signupHandler}>registrazione</span>
            </SwitchText>
        </Content>
    );
};

export default PasswordRecoveryForm;
