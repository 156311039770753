import { BaseButton } from 'style/components/BaseButton.style';
import styled, { css } from 'styled-components';

export const Content = styled.form`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    padding: 30px 20px;
`;

export const RecoveryText = styled.h2`

    margin: 0;
    padding: 0;

    color: #011f71;
    text-align: center;
`;

export const RecoveryButton = styled(BaseButton)<{ isDisabled: boolean}>`

${({isDisabled}) => isDisabled && (

    css`

        background: gray;
        color: black;

        cursor: default;
    `
)}
`;

export const SwitchText = styled.div`

    margin: 0;
    padding: 0;

    font-size: 15px;

    span {

        /* font-size: 14px; */
        font-style: italic;
        font-weight: 700;
        color: #df03e8;

        cursor: pointer;
    }
`;
