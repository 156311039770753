import { css, styled } from 'styled-components';

export const Page = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 40px;

    max-width: 1200px;

    margin: 0;
    padding: 20px;

    /* border-radius: 0 0 50px 0;
    border-bottom: 20px solid #df03e8;
    border-right: 20px solid #df03e8;
 */
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

export const LogoBox = styled.div`

    max-width: 200px;

    img {
        max-width: 200px;
    }
`;

export const TextBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 20px;
`;

export const Title = styled.h2`

    font-size: 30px;
`;

export const Description = styled.p`

    font-size: 20px;
`;

export const ResendButton = styled.button`

    padding: 10px 20px;

    border: 5px solid #1E2251FC;
    border-radius: 15px;
    box-shadow: unset;

    color: #1E2251FC;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;

    ${({isMouseOver}) => isMouseOver && (

        css`
        
            color: white;
            background: #1E2251FC;
        `
    )}
`;

export const Signature = styled.p`

    font-size: 20px;
    font-style: italic;
    color: #1E2251FC;
`;
