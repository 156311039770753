import styled from 'styled-components';

export const Card = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
    gap: 20px;

    max-width: 300px;
    padding: 20px;

    background: white;
    border: 5px solid #011f71;
    border-radius: 25px;

    cursor: pointer;
`;

export const PartnerImage = styled.img`

    width: 100%;
    border-radius: 15px;
`;

export const PartnerName = styled.h4`

    margin: 0;
    padding: 0;

    font-size: 20px;
    font-weight: 700;
`;

export const TypeOfDiscount = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    white-space: nowrap;

    > div {
        margin: 0 5px 0 20px;
    }

    > div:first-child {
        margin: 0 5px 0 0;
    }
`;

export const PremiumBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid gold;
    border-radius: 50%;
    background: black;
`;

export const GoldBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid white;
    border-radius: 50%;
    background: gold;
`;

export const PlatinumBadge = styled.div`

    width: 25px;
    height: 25px;

    border: 2px solid black;
    border-radius: 50%;
    background: white;
`;

export const NotAvailableImage = styled.img`

    max-width: 20px;
    position: absolute;

    left: 50px;

    opacity: 0.5;
`;

export const PercentageBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    position: relative;

    img {
        width: 30px;
    }
`;

export const CategoryText = styled.h3`

    margin: 0;
    padding: 0;

    color: #df03e8;
`;

export const StarsRow = styled.div``;
