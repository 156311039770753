import { css, styled } from 'styled-components';


export const Card = styled.form`

    display: flex;
    align-items: center;
    justify-content: stretch;

    flex-direction: column;

    width: 300px;
    padding: 20px;

    border: 5px solid #df03e8;
    border: 5px solid #011f71;
    border-radius: 15px;

    color: #011f71;
`;

export const OfferImg = styled.img`

    width: 100%;
    /* max-width: 200px; */
`;

export const OfferDetails = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    flex-grow: 1;

    margin: 20px 0 0;
`;

export const OfferTitle = styled.h3`

    flex-grow: 1;
`;

export const OfferPrice = styled.div<{ $isCutted?: boolean}>`

    ${({$isCutted}) => $isCutted && (
        
        css`
            text-decoration: line-through red 5px;
        `
    )} 
`;

export const OfferQuantity = styled.div<{ isMinimum?: boolean, isMaximum?: boolean}>`

    width: 100%;

    text-align: center;

    ${({isMinimum}) => isMinimum && (
        css`
            margin: 0 0 0 50px;
        `
    )}

    ${({isMaximum}) => isMaximum && (
        css`
            margin: 0 50px 0 0;
        `
    )}
`;

export const QuantityChangeButton = styled.button`

    margin: 10px;

    color: #011f71;
    border-color: #011f71;
    border-radius: 5px;
`;

export const OfferButton = styled.button`

    font-weight: 600;
    color: #df03e8;
    border-color: #df03e8;
    border-radius: 5px;

    text-transform: uppercase;
`;
