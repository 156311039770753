import React from 'react';

// style
import { css, styled } from 'styled-components';

const TableBase = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 700px;

    border: 5px solid #011f71;
    border-radius: 15px;

    background: white;

    ${({$flavor}) => ($flavor === 'contacts') && (
        
        css`

            color: white;
            border: none;
            background: linear-gradient(90deg, #B829B3, #0535B8);
        `
    )}

    @media screen and (max-width: 768px) {
        
        width: 100%;
    }
`;

const Content = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    gap: 20px;

    width: 100%;
    padding: 20px;
`;

const InfoRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    @media screen and (max-width: 768px) {
        
        flex-direction: column;
    }
`;

const Title = styled.h4`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-basis: 30%;

    margin: 0;
    padding: 0;

    font-size: 20px;
`;

const Dsecription = styled.p`

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-basis: 70%;

    margin: 0;
    padding: 0;

    font-size: 20px;
    cursor: pointer;

    ${({$isWallet}) => $isWallet && (

        css`

            cursor: default;
            
            @media screen and (max-width: 768px) {

                width: 100%;
                align-self: flex-start;
                overflow-x: scroll;
                font-size: 12px;
            }
        `
    )}
`;


const TableCard = (propsData) => {

    return (
        <TableBase $flavor={propsData.flavor}>
            <Content>
                {propsData.data.map((contact, index) => {

                const linkClickHandler = (title, value) => {

                    if (title === 'wallet') return;

                    if (title === 'e-mail') {

                        window.open(
                            `mailto:${value}`,
                            '_blank',
                            'noreferrer noopener'
                        );
                        return;
                    };

                    if (title === 'discord') {

                        window.open(
                            `https://discord.com/invite/Gn5qpDdqwF`,
                            '_blank',
                            'noreferrer noopener'
                        );
                        return;
                    };

                    if (title === 'telegram') {

                            window.open(
                                `https://t.me/ellenikautility`,
                                '_blank',
                                'noreferrer noopener'
                            );
                            return;
                        };

                        window.open(
                            `https://${value}`,
                            '_blank',
                            'noreferrer noopener'
                        )
                    };

                    return (
                        <InfoRow key={index}>
                            <Title>
                                {contact.title}
                            </Title>

                            <Dsecription 
                                $isWallet={contact.title === 'wallet'}
                                onClick={() => linkClickHandler(contact.title, contact.value)}    
                            >
                                {contact.value}
                            </Dsecription>
                        </InfoRow>
                    )
                })}
            </Content>
        </TableBase>
    );
};

export default TableCard;
