import { useEffect } from 'react';

// routing
import { useNavigate, useSearchParams } from 'react-router-dom';

// redux
import { setIsLoading } from 'redux/slices/slice-ui';
import { SnackbarOptions } from 'redux/slices/slice-snackbar.d';
import { addSnackbar } from 'redux/actions/actions-snackbar';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

//utils
import { assignEllenPass } from 'services/Web3/Web3.services';
import { sendEmailPur } from 'services/emailjs.services';
import { updateDB, userDBData } from 'services/firebase.services';
import { 
    addProcessedTransaction,
    addEllenPassTransactionToVerify
} from 'services/payments.services';

//style
import { Page } from './style/EllenPassPaymentPage.style';


const EllenPassPaymentPage = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    const manageNewEllenPass = async () => {
        
        dispatch( setIsLoading(true) );

        const authUser = JSON.parse(window.localStorage.getItem('authUser')!); // to do manage null
        const transaction = searchParams.get('codTrans');
        const typeOfPass = searchParams.get('Note1');
        let snackbarData: SnackbarOptions = {}
        
        if ((transaction === null) || (transaction === undefined)) {
            
            snackbarData.type = 'error';
            snackbarData.message = 'Nessun codice transazione trovato, contatta il supporto';
            
            dispatch( addSnackbar(snackbarData) );
            dispatch( setIsLoading(false) );
            navigate('/authentication/login');
            return;
        };
        
        if ((typeOfPass === null) || (typeOfPass === undefined) || ((typeOfPass !== 'premium') && (typeOfPass !== 'gold') && (typeOfPass !== 'platinum'))) {
            
            snackbarData.type = 'error';
            snackbarData.message = 'Nessuna rarità per Ellen Pass trovata, contatta il supporto';
            
            dispatch( setIsLoading(false) );
            dispatch( addSnackbar(snackbarData) );
            navigate('/authentication/login');
            return;
        };

        if ((authUser === undefined) || (authUser === null) || !authUser) {

            snackbarData.type = 'error';
            snackbarData.message = 'Utente non attivo, contattare il supporto';

            dispatch( setIsLoading(false) );
            dispatch( addSnackbar(snackbarData) );
            navigate('/authentication/login');
            return;
        };

        try {
        
            const userDataOnDB = await userDBData();
            const walletAddress = userDataOnDB.wallet;

            if ((walletAddress === undefined) || (walletAddress === null) || (walletAddress === '')) {

                snackbarData.type = 'error';
                snackbarData.message = 'Wallet Address non trovato, contattare il supporto';
    
                dispatch( setIsLoading(false) );
                dispatch( addSnackbar(snackbarData) );
                navigate('/authentication/login');
                return;    
            }

            await assignEllenPass(walletAddress, typeOfPass);

            const transactionPayload = {
                [transaction]: {
                    userId: authUser.uid,
                    typeOfPass
                }
            };
        
            await addProcessedTransaction(transactionPayload);
        
            const passPrice = ((typeOfPass === 'premium') ? 99 : ((typeOfPass === 'gold') ? 149 : 199));
            const purchasePayload = {
                purchaseName: `Ellen Pass di tipo ${typeOfPass}`,
                purchaseQuantity: 1,
                purchasePrice: passPrice
            };
        
            const updateDbPath = `userData/${authUser.uid}/ellenpass${typeOfPass}`;
            await updateDB(updateDbPath, { transaction });
            await sendEmailPur('template_acquisto', authUser.email, purchasePayload);
        
            dispatch( setIsLoading(false) );

            window.history.replaceState({}, document.title, "/confirmed");
            window.location.href = "payments/confirmed";        
        } catch (error: any) {
            console.warn("ERROR:", error);
            dispatch( setIsLoading(false) );

            const transactionPayload = {
                transaction: {
                    codTrans: transaction,
                    data: {
                        typeOfPass,
                        userID: authUser.uid
                    }
                }
            };
            await addEllenPassTransactionToVerify(transaction, authUser.uid, transactionPayload);
            
            window.history.replaceState({}, document.title, "/tomanage");
            window.location.href = "payments/tomanage";        
        }
    };

    useEffect(() => {
        manageNewEllenPass();
    }, []);

    return (
        <Page>
            {/* <img alt='spinner' src={Logo} /> */}
        </Page>
    );
};

export default EllenPassPaymentPage;

// https://int-ecommerce.nexi.it/ecomm/ecomm/TdsMerchantServlet?action=end&merchantId=00070342&tdsId=45520&description=EUTRANSCOD1695284940536_1695284971508&