import styled from 'styled-components';
import appLogo from 'assets/logo-ellenika.png'
import devices from 'style/breakpoints';

export const MinRangeGuard = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 100vh;

    background: grey;
    background-image: url(${appLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    font-size: 18px;
    font-weight: 500;
    color: black;

    overflow: hidden;

    @media ${devices.appMinWidth} {

        display: none;
    }
`;

export const Disclaimer = styled.p`

    width: 100%;

    margin: 0;
    padding: 20px;

    text-align: center;
`;

export const AppBox = styled.div`

    display: none;

    @media ${devices.appMinWidth} {

        display: flex;
        width: 100%;
        height: 100vh;
        align-items: stretch;
    }
`;

export const AppLogo = styled.img``;