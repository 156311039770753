import { FC, useEffect, useRef, useState } from 'react';

// routing
import { useNavigate } from 'react-router';

// redux
import { setIsLoading } from 'redux/slices/slice-ui';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { userDBData } from 'services/firebase.services';
import { updateDBUserData } from 'services/state1.services';

// components
import TableCard from 'components/TableCard/TableCard';

// assets
import { ReactComponent as PoligonIconSVG } from 'assets/polygon-matic-logo.svg';

// style
import {
    Page,
    WalletDisclaimer,
    InputWalletBox,
    WalletInput,
    SaveWalletButton
} from './style/WalletPage.style'


const WalletPage: FC<{}> = () => {

    const [userWallet, setUserWallet] = useState<string | undefined>(undefined); 
    const walletInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const saveWalletHandler = async () => {
        
        if (walletInputRef.current === null) return;
        dispatch( setIsLoading(true) );

        try {
            
            const payload = {
                wallet: walletInputRef.current.value
            }
            await updateDBUserData(payload, ''); 
            await getUserData();
            dispatch( setIsLoading(false) );
            navigate('/reservedArea/wallet');
        } catch (error: any) {
            console.warn("ERROR:", error);
            dispatch( setIsLoading(false) );
        }
    };

    const getUserData =  async () => {

        try {
            
            const response = await userDBData();
            setUserWallet(response.wallet);
        } catch (error: any) {
            console.warn("ERROR:", error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);
    
    const userHasWallet = ((userWallet !== undefined) && (userWallet !== '')) ?? false;
    const walletData = [
        {
            title: 'wallet',
            value: (userWallet ?? '')
        }
    ]

    return (
        <Page>
            <WalletDisclaimer>
                Ti ricordiamo che il Wallet deve essere su rete POLYGON

                <PoligonIconSVG />
            </WalletDisclaimer>
            {!userHasWallet && (
                <>
                    <InputWalletBox>
                        <WalletInput ref={walletInputRef}/>

                        <SaveWalletButton onClick={saveWalletHandler}>
                            Salva il tuo Wallet
                        </SaveWalletButton>
                    </InputWalletBox>

                    <WalletDisclaimer>
                        <a 
                            href='https://www.youtube.com/@ellenika_utilityofficial/featured'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Per qualsiasi dubbio puoi accedere da qui ai nostri tutorial
                        </a>
                    </WalletDisclaimer>
                </>
            )}

            {userHasWallet && (
                <TableCard data={walletData} />
            )}
        </Page>
    );
};

export default WalletPage;
