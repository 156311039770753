import { FC } from 'react';

// redux
import { setIsLoading } from 'redux/slices/slice-ui';

// hooks
import useAppDispatch from 'hooks/useAppDispatch';

// utils
import { nexiEllenPassPaymentCall, nexiState1PaymentCall } from 'services/nexi.services';
import { deleteDiscountCode } from 'services/payments.services';

// types
import { PurchaseModalProps } from './PurchaseModal.component.d';

// style
import {
    Content,
    Disclaimer,
    AcceptButton
} from './style/PurchaseModal.style';


const PurchaseModal: FC<PurchaseModalProps> = ({ 
    isEllenPassPurchase,
    ellenPassType,
    buildingType,
    buildingZone,
    finalDescription,
    finalPrice,
    quantity,
    discountCode
}) => {

    const dispatch = useAppDispatch();

    const purchaseHandler = async () => {
        
        dispatch( setIsLoading(true) );
           
        if (isEllenPassPurchase) {

            if ((ellenPassType === undefined) || (finalPrice === undefined)) return;
            if ((discountCode !== null) && (discountCode !== undefined)) await deleteDiscountCode(discountCode);

            nexiEllenPassPaymentCall(ellenPassType, finalPrice);
        };

        if (!isEllenPassPurchase) {

            if ((buildingType === undefined) ||
                (buildingZone === undefined) ||
                (finalPrice === undefined) ||
                (finalDescription === undefined) ||
                (quantity === undefined)) return;
            if ((discountCode !== null) && (discountCode !== undefined)) await deleteDiscountCode(discountCode);

            nexiState1PaymentCall(buildingType, buildingZone, finalPrice, finalDescription, quantity);
        }
    };

    return (
        <Content>
            <Disclaimer>
                In conformità a quanto espressamente previsto dall’art. 7 delle Condizioni 
                Generali di Vendita le ricordiamo che il Diritto di Recesso è escluso con 
                riferimento ai servizi ad esecuzione immediata e/o alla fornitura di contenuti 
                digitali eseguita mediante un supporto non materiale. Procede con l'acquisto?
            </Disclaimer>

            <AcceptButton
                onClick={purchaseHandler} 
            >
                Procedere
            </AcceptButton>
        </Content>
    );
};

export default PurchaseModal;
