import styled, { css } from 'styled-components';

export const BaseLabel = styled.label`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    width: 100%;
    max-width: 300px;

    font-size: 12px;

    span {

        font-size: 18px;
        text-transform: capitalize;
    }
`;

export const CustomInput = styled.input<{ $isInputInError: boolean | undefined}>`

    width: 100%;
    height: 30px;

    font-size: 18px;

    ${({$isInputInError}) => $isInputInError && (

        css`
            border-color: red;
        `
    )}
`;

export const InputBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const SeePwButton = styled.div`

    width: 30px;
    height: 30px;

    margin: 0 0 0 10px;

    cursor: pointer;

    img {
        width: 30px;
    }
`;
