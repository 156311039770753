export const ELLENIKA_STATE1_ZONE = [
    'ArenaPark',
    'EllenIsland',
    'LakeCityLine',
    'MezzaLuna',
    'PanoramaHill',
    'RedKongBeach',
    'Sportswear',
    'WhiteWeekZone',
    'WineZone'
  ];
